import { parse, stringify } from "qs";
import { isGuid } from "utils";

function convertQueryToPathParams() {
    const parsedParams = parse(window.location.search, { ignoreQueryPrefix: true });
    const queryParamsToConvert = ["system", "unitId"];
    queryParamsToConvert.forEach((param) => {
        if (!parsedParams[param]) return;

        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        const value = String(parsedParams[param]);
        delete parsedParams[param];

        let url = window.location.pathname;
        if (!url.endsWith("/")) url += "/";
        url += value;
        url += stringify(parsedParams, { addQueryPrefix: true });

        window.history.replaceState({}, "", url);
    });
}

function validatePathParams() {
    const parsedPath = document.location.pathname.split("/").filter((s) => s) || [];
    const isLandingPage = parsedPath[0] === "booking";

    // No validation implemented yet
    let unitId: string;
    if (isLandingPage) {
        // system = parsedPath[1];
        // webBookingId = parsedPath[2];
    } else {
        // system = parsedPath[0];
        unitId = parsedPath[1];

        // for backwards compatibility - feel free to remove this
        if (unitId && isGuid(unitId)) {
            window.history.replaceState({}, "", `/booking${window.location.pathname}`);
        }
    }
}

convertQueryToPathParams();
validatePathParams();
