import { useAvailableTimes } from "api/api-hooks/use-available-times";
import { useBookingStateContext } from "hooks/use-booking-state";
import { useMemo } from "react";
import { AvailableTimesSection, AvailableTimesUnit } from "types/available-times";

export const useAllTimeSets = () => {
    const state = useBookingStateContext();
    const availableTimes = useAvailableTimes(state.date);

    return useMemo(() => {
        return availableTimes?.data?.flatMap((item: AvailableTimesUnit) =>
            item.sections.flatMap((section: AvailableTimesSection) => section.timeSets)
        );
    }, [availableTimes?.data]);
};
