import { useQuery } from "@tanstack/react-query";
import { get } from "api/api-methods";
import { useBookingIdParam } from "hooks/use-booking-id-param";
import i18n from "i18next";
import { WebBooking } from "types/web-booking";

const webBookingQuery = (id: string, onSuccess?: (data: WebBooking) => void) => ({
    queryKey: ["booking", id],
    queryFn: async () => {
        const response = await get(`webBooking/webBookings/${id}`).then(
            (response: Partial<WebBooking>) => {
                return new WebBooking(response);
            }
        );

        if (onSuccess) {
            onSuccess(response);
        }
        return response;
    },
});

export function useWebBooking() {
    const bookingId = useBookingIdParam();
    const localHistory = history as unknown as TypedHistory;
    const isFinal = localHistory.state?.isFinal;

    let onSuccess: (data: WebBooking) => void | undefined = undefined;
    if (!isFinal) {
        // TODO: Check it this still works correctlly
        onSuccess = (data: WebBooking) => {
            void i18n.changeLanguage(data.language);
        };
    }

    return useQuery({
        ...webBookingQuery(bookingId, onSuccess),
        refetchOnMount: false,
        // The query will not execute unless theres a unitId from the booking and not in the params
        enabled: !!bookingId,
    });
}
