import { Alert } from "@caspeco/casper-ui-library.components.alert";
import AlertSkeleton from "components/skeletons/alert-skeleton";
import { useReservation } from "api/api-hooks/use-create-reservation";
import { useSelectedRule } from "hooks/use-selected-rule";
import { useTranslation } from "react-i18next";
import { CurrencyDisplay } from "translations/currency";
import { getPaymentCalculationTotal } from "utils/payment-utils";
import { useAvailableTimes } from "api/api-hooks/use-available-times";
import { useWebBooking } from "api/api-hooks/use-web-booking";
import { useBookingStateContext } from "hooks/use-booking-state";

const NoShowAlert = () => {
    const { t } = useTranslation();
    const selectedRule = useSelectedRule();
    const state = useBookingStateContext();
    const availableTimes = useAvailableTimes(state.date);
    const reservation = useReservation();
    const { data: webBooking } = useWebBooking();

    if (availableTimes.isPending && !webBooking.webBookingRules) return <AlertSkeleton />;

    const guests = state.guests ? state.guests : webBooking.guests;
    const value = getPaymentCalculationTotal(
        selectedRule,
        guests,
        reservation.data?.articles?.filter((x) => x.isTable)
    );

    return (
        <Alert type="info" iconVerticalAlign="flex-start">
            {t("noShowInfoAlert", {
                value,
                display: CurrencyDisplay.Symbol,
            })}
        </Alert>
    );
};

export default NoShowAlert;
