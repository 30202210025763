import { useReservation } from "api/api-hooks/use-create-reservation";
import { useBookingActionsContext } from "hooks/use-booking-state";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

type NavigateOptions = {
    replace?: boolean;
    preventBlocking?: boolean;
};

enum BlockReason {
    DATE_REMOVAL,
    TIMES_CONFIRMED_REMOVAL,
}

/**
 * A hook that provides safe navigation with blocking for destructive actions
 * This keeps URL and state in sync and prevents circular updates
 * The booking state handles the state updates based on the URL updates
 */
export const useSafeNavigation = () => {
    const rawNavigate = useNavigate();
    const location = useLocation();
    const isNavigatingRef = useRef(false);
    const { data: reservation } = useReservation();
    const { handleCancel } = useBookingActionsContext();
    const { t } = useTranslation();

    const checkDestructiveChanges = useCallback(
        (nextSearch: string) => {
            const currentParams = new URLSearchParams(location.search);
            const nextParams = new URLSearchParams(nextSearch);

            if (
                currentParams.has("date") &&
                currentParams.get("activityBooking") &&
                !nextParams.has("date")
            ) {
                return BlockReason.DATE_REMOVAL;
            }

            if (
                currentParams.has("timesConfirmed") &&
                !currentParams.get("activityBooking") &&
                !nextParams.has("timesConfirmed")
            ) {
                return BlockReason.TIMES_CONFIRMED_REMOVAL;
            }

            return null;
        },
        [location.search]
    );

    const showConfirmation = useCallback(
        (reason: BlockReason) => {
            const messages = {
                [BlockReason.DATE_REMOVAL]: t("warnings.dateRemoval"),
                [BlockReason.TIMES_CONFIRMED_REMOVAL]: t("warnings.timesConfirmed"),
            };

            return window.confirm(messages[reason]);
        },
        [t]
    );

    const safeNavigate = useCallback(
        (to: string, options: NavigateOptions = {}) => {
            // Prevent circular updates
            if (isNavigatingRef.current) {
                console.log("Navigation already in progress, skipping");
                return false;
            }

            const { replace = false, preventBlocking = false } = options;

            // Extract search part
            const searchPart = to.includes("?") ? to.substring(to.indexOf("?")) : "";
            const hasReservation = !!reservation;
            let confirmedShouldProceed = false;

            if (hasReservation && !preventBlocking) {
                const blockReason = checkDestructiveChanges(searchPart);

                if (blockReason !== null) {
                    confirmedShouldProceed = showConfirmation(blockReason);

                    if (!confirmedShouldProceed) {
                        return false; // Navigation canceled
                    }
                }
            }

            try {
                isNavigatingRef.current = true;
                rawNavigate(to, { replace });
                if (confirmedShouldProceed) handleCancel();
                return true;
            } finally {
                // Clean up the navigating flag after a short delay
                setTimeout(() => {
                    isNavigatingRef.current = false;
                }, 0);
            }
        },
        [reservation, checkDestructiveChanges, showConfirmation, rawNavigate, handleCancel]
    );

    return safeNavigate;
};
