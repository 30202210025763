import {
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";
import { useWebBooking } from "api/api-hooks/use-web-booking";
import StatusFailedWithDialogue from "components/booking-status/status-failed-icon-with-dialogue";
import { t } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { hasAvailableWaitListOffers, isWaitListOfferLocked } from "utils/booking";

interface IOfferStatusProps {
    title: string;
    message: string;
    locked?: boolean;
    lockTime?: number;
}

const OfferAvailable = ({ title, message }: IOfferStatusProps) => (
    <Flex direction="column" alignItems="left">
        <Heading as="h1" fontWeight="700" textAlign="left">
            {title}
        </Heading>
        <Text maxW="496" textAlign="left" fontSize={ThemeFontSizeVariable.Large}>
            {message}
        </Text>
    </Flex>
);

const OfferNotAvailable = ({ title, message, locked, lockTime }: IOfferStatusProps) => {
    const { t } = useTranslation();
    const phoneNumber = useExternalBookingSettings().data?.phoneNumber;
    const hours = lockTime && Math.floor(lockTime / 60);
    const messageTranslation = locked ? (
        <Trans i18nKey="onlineBookingPossibleUntil">
            {t("waitList.onlineBookingPossibleUntil", {
                hours: hours.toString(),
                phoneNumber,
            })}

            <Button variant="link" href={`tel:${phoneNumber}`} />
        </Trans>
    ) : (
        message
    );

    return <StatusFailedWithDialogue title={title} message={messageTranslation} />;
};

const WaitListOffer = () => {
    const location = useLocation() as TypedHistory;
    const webBooking = useWebBooking().data;
    const hasAvailableOffers = hasAvailableWaitListOffers(webBooking);
    const waitListOfferLocked = isWaitListOfferLocked(webBooking);
    const showWaitListOffer = hasAvailableOffers && !waitListOfferLocked;

    // If one or more people try confirming a waitlist offer that requires payment at the same time, the terminal step will result in a race condition
    // The last ones back from the terminal will get a double booking exception
    const failedPaymentWithDoubleBookingException = location.state?.failedPaymentConfirmWaitList;
    const noShowOrPrepayment = webBooking.webBookingRules.at(0).hasNoShowRule()
        ? "noShow"
        : "payment";
    return (
        <Stack alignItems="center" gap={ThemeSpaceVariable.Medium} pt={ThemeSpaceVariable.Medium}>
            {failedPaymentWithDoubleBookingException ? (
                <OfferNotAvailable
                    title={t("paymentErrors.dialogue.waitList.title")}
                    message={t(`paymentErrors.dialogue.waitList.${noShowOrPrepayment}.message`)}
                    locked={false}
                />
            ) : showWaitListOffer ? (
                <OfferAvailable
                    title={t("waitList.offerAvailableTitle")}
                    message={t("waitList.offerAvailableMessage")}
                />
            ) : (
                <OfferNotAvailable
                    title={t("waitList.offerNotAvailableTitle")}
                    message={t("waitList.offerNotAvailableMessage")}
                    locked={waitListOfferLocked}
                    lockTime={webBooking.webBookingRules.at(0)?.lockTime}
                />
            )}
        </Stack>
    );
};
export default WaitListOffer;
