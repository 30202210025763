import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Grid } from "@caspeco/casper-ui-library.components.grid";

import React from "react";

interface ITimeSlotGrid {
    items: JSX.Element[];
}

const TimeSlotGrid = ({ items }: ITimeSlotGrid) => {
    return (
        <Grid templateColumns="repeat(4, 1fr)" gap={ThemeSpaceVariable.Medium}>
            {items}
        </Grid>
    );
};

export default TimeSlotGrid;
