import { ThemeProvider } from "@caspeco/casper-ui-library.base-ui.theme";
import { setHTMLHeadAppProperties } from "components/app/app-helper";
import Spinner from "components/spinner/spinner";
import config from "config/app.config";
import { fallbackTheme } from "fallback-theme";
import Layout from "layout/layout";
import { useEffect } from "react";
import { ga, initialize } from "react-ga";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import routes from "routes";
import "../../variables.less";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";

const App = () => {
    const { data: settings } = useExternalBookingSettings();

    useEffect(() => {
        void setHTMLHeadAppProperties(config.environment);
    }, []);

    useEffect(() => {
        if (!settings?.gaTrackingId) return;
        initialize(settings?.gaTrackingId);
        ga("create", settings?.gaTrackingId, "auto", {
            name: "customerTracking",
        });
        ga("customerTracking.send", "pageview", {
            page: window.location.pathname + window.location.search,
            anonymizeIp: true,
        });
    }, [settings?.gaTrackingId]);

    const themeColors = settings?.themeSettings?.colors || fallbackTheme;
    return (
        <ThemeProvider colors={themeColors}>
            <Layout>
                <RouterProvider
                    router={createBrowserRouter(routes)}
                    fallbackElement={<Spinner />}
                />
            </Layout>
        </ThemeProvider>
    );
};

export default App;
