import { ThemeFontSizeVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";
import EmailLink from "components/links/email-link";
import { PhoneNumberLink } from "components/links/phone-number-link";
import { SpaceBetween } from "layout/space-between";
import { useTranslation } from "react-i18next";

export default function ResturantInformation() {
    const { t } = useTranslation();
    const { data: settings, isPending } = useExternalBookingSettings();

    if (isPending) return null;

    const { unitsMetaData, email, address } = settings;
    return (
        <Stack>
            <Heading as="h4" fontWeight={"medium"} fontSize={ThemeFontSizeVariable.Large}>
                {t("resturangInformation.contactInformation")}
            </Heading>
            <SpaceBetween>
                <Text>{t("resturangInformation.place")}</Text>
                <Text textAlign="right">{unitsMetaData.name}</Text>
            </SpaceBetween>
            <SpaceBetween>
                <Text>{t("resturangInformation.address")}</Text>
                <Text textAlign="right">{address}</Text>
            </SpaceBetween>
            <SpaceBetween>
                <Text>{t("resturangInformation.phone")}</Text>
                <PhoneNumberLink />
            </SpaceBetween>
            <SpaceBetween>
                <Text>{t("resturangInformation.replyEmail")}</Text>
                <EmailLink email={email} />
            </SpaceBetween>
        </Stack>
    );
}
