import {
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { Grid } from "@caspeco/casper-ui-library.components.grid";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
} from "@caspeco/casper-ui-library.components.modal";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useSelectedRule } from "hooks/use-selected-rule";
import { useTranslation } from "react-i18next";
import { getSortedHourKeys, groupedTimes } from "utils/booking";
import WaitListTimeSlot from "./wait-list-time-slot";
import { TimeSlot } from "types/time-slot";

interface IWaitlistModalProps {
    isOpen: boolean;
    handleWaitlistModalClose: () => void;
    handleWaitlistContinue: () => void;
    waitListTimes: TimeSlot[];
    selectedTimes: TimeSlot[];
    onTimeClick: (time: TimeSlot) => void;
}

const WaitlistModal = ({
    handleWaitlistContinue,
    handleWaitlistModalClose,
    isOpen,
    waitListTimes,
    selectedTimes,
    onTimeClick,
}: IWaitlistModalProps) => {
    const { t } = useTranslation();
    const rule = useSelectedRule(selectedTimes[0]?.ruleId);

    const itemList: React.ReactNode[] = [];
    const groupedTimesByHour = groupedTimes(waitListTimes);
    const hourKeys = getSortedHourKeys(Object.keys(groupedTimesByHour));

    hourKeys.forEach((hour) => {
        const timesThisHour = groupedTimesByHour[hour];
        timesThisHour.forEach((time: TimeSlot) => {
            itemList.push(
                <WaitListTimeSlot
                    key={time.start.format("HH:mm")}
                    selectedTimes={selectedTimes}
                    onTimeClick={onTimeClick}
                    time={time}
                    isWaitListModal
                />
            );
        });
    });

    if (!rule) {
        return null;
    }

    return (
        <Modal isOpen={isOpen} onClose={handleWaitlistModalClose} size="md">
            <ModalHeader
                display="flex"
                justifyContent="space-between"
                p={ThemeSpaceVariable.Medium}
            >
                <Text fontWeight="medium" fontSize={ThemeFontSizeVariable.XLarge}>
                    {t("waitList.waitListChooseTimes", { ruleName: rule?.title.toLowerCase() })}
                </Text>
                <ModalCloseButton />
            </ModalHeader>
            <ModalBody
                display="flex"
                flexDirection="column"
                px={[ThemeSpaceVariable.Medium, ThemeSpaceVariable.Large]}
            >
                <Text>{t("waitList.waitListChooseMultiple")}</Text>
                <Grid
                    templateColumns="repeat(4, 1fr)"
                    gap={ThemeSpaceVariable.Medium}
                    mt={ThemeSpaceVariable.Medium}
                    mb={ThemeSpaceVariable.Medium}
                >
                    {itemList}
                </Grid>
            </ModalBody>
            <ModalFooter
                px={ThemeSpaceVariable.Medium}
                pt={ThemeSpaceVariable.Medium}
                pb={ThemeSpaceVariable.Medium}
            >
                <Button
                    variant="primary"
                    size={["lg", "md"]}
                    alignSelf="flex-end"
                    w={["full", "auto"]}
                    onClick={handleWaitlistContinue}
                >
                    {t("waitList.continueWithSelectedTimes")}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default WaitlistModal;
