import {
    ThemeColorVariable,
    ThemeFontSizeVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Icons } from "@caspeco/casper-ui-library.components.icon";
import { IconButton } from "@caspeco/casper-ui-library.components.icon-button";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useReservation } from "api/api-hooks/use-create-reservation";
import ActivityStepContinueButton from "components/selection-components/select-activity/activity-step-continue-button";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getUniqueResourceArticlesByTimeAndGroup } from "utils";

interface IActivityFooter {
    setSummaryOpen: (value: boolean) => void;
    gotoDetailsForm: () => void;
}

const ActivityFooter = ({ setSummaryOpen, gotoDetailsForm }: IActivityFooter) => {
    const { t } = useTranslation();
    const { data: reservation } = useReservation();
    const footerLabel = t("selectActivity.continueOrEditFooterName");
    const uniqueResourceGroups = useMemo(
        () => getUniqueResourceArticlesByTimeAndGroup(reservation),
        [reservation]
    );
    const selectedActivities = uniqueResourceGroups?.length ?? 0;
    const selectedActivitiesQuantity = selectedActivities < 10 ? String(selectedActivities) : "9+";

    return (
        <Stack
            role="navigation"
            flexDirection="row"
            aria-label={footerLabel}
            position="sticky"
            bottom={0}
            marginTop="auto"
            padding={`${ThemeFontSizeVariable.Medium} 0`}
            gap={ThemeFontSizeVariable.Medium}
            background={ThemeColorVariable.Background}
            w="full"
            display={{ base: selectedActivities > 0 ? "flex" : "none", lg: "none" }}
        >
            <Box alignSelf="flex-end" w="full" borderRadius="full">
                <ActivityStepContinueButton onClick={gotoDetailsForm} />
            </Box>
            <Box position="relative" borderRadius="full" alignSelf="flex-end">
                <IconButton
                    aria-label={t("selectActivity.changeSelection")}
                    icon={Icons.Cart}
                    size="lg"
                    onClick={() => {
                        setSummaryOpen(true);
                    }}
                    variant="primary"
                />
                <Box
                    position="absolute"
                    top="-1"
                    right="-1"
                    borderRadius={ThemeFontSizeVariable.XLarge}
                    bg={ThemeColorVariable.Secondary}
                    width={ThemeFontSizeVariable.XLarge}
                    height={ThemeFontSizeVariable.XLarge}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text color={ThemeColorVariable.OnSecondary} fontWeight="500">
                        {selectedActivitiesQuantity}
                    </Text>
                </Box>
            </Box>
        </Stack>
    );
};

export default ActivityFooter;
