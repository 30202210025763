import {
    ThemeBorderVariable,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeRadiusVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { useReservation } from "api/api-hooks/use-create-reservation";
import Summary from "components/selection-components/summary/summary";
import { t } from "i18next";

export function Articles() {
    const reservation = useReservation();
    if (!reservation?.data?.articles || !reservation?.data?.nonTableArticles) return null;
    const border = `${ThemeBorderVariable.XSmall} ${ThemeColorVariable.OnSurfaceBorder}`;

    return (
        <Stack>
            <Heading as="h4" fontWeight={"medium"} fontSize={ThemeFontSizeVariable.Large}>
                {t("myBooking")}
            </Heading>
            <Stack
                border={border}
                borderRadius={ThemeRadiusVariable.Small}
                p={ThemeSpaceVariable.Medium}
                spacing={ThemeSpaceVariable.None}
            >
                <Summary />
            </Stack>
        </Stack>
    );
}
