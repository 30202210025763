import { ThemeColorVariable, ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import {
    Accordion,
    AccordionItem,
    AccordionPanel,
} from "@caspeco/casper-ui-library.components.accordion";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import ActivityTimeSlot from "components/selection-components/select-activity/activity-time-slot";
import TimeSlotGrid from "components/time-slot-grid";
import { useEffect, useRef, useState } from "react";
import { Time } from "store/store-types";
import testId from "types/test-id";
import { TimeSlot } from "types/time-slot";
import { WebRule } from "types/web-rule";

interface ITimeSetAccordion {
    selectedTime: TimeSlot;
    defaultIndex?: number;
    onClick?: (time: TimeSlot) => void;
    timeSets: WebRule[];
    timesToNudgeMap: Map<number, Time[]>;
}

const TimeSetAccordion = ({
    selectedTime,
    defaultIndex = 0,
    timeSets,
    onClick,
    timesToNudgeMap,
}: ITimeSetAccordion) => {
    const [expanded, setExpanded] = useState<number[]>([defaultIndex]);
    const [clickedIdx, setClickedIdx] = useState<number | null>(null);
    const divRefs = useRef<(HTMLDivElement | null)[]>([]);
    // Delay before scrolling to ensure the accordion has time to expand
    const scrollDelay = 100;

    const handleAccordionChange = (index: number) => {
        setExpanded((prevExpanded) =>
            prevExpanded.includes(index)
                ? prevExpanded.filter((i) => i !== index)
                : [...prevExpanded, index]
        );
    };

    const handleTimeSlotClick = (time: TimeSlot, index: number) => {
        setExpanded((prevExpanded) =>
            prevExpanded.includes(index) ? prevExpanded : [...prevExpanded, index]
        );
        if (onClick) {
            onClick(time);
        }
        setClickedIdx(index);
    };

    useEffect(() => {
        if (selectedTime) {
            const timeSetIndex = timeSets.findIndex((timeSet) => {
                return timeSet.times.some((time) => time.start.isSame(selectedTime.start));
            });
            if (timeSetIndex !== -1) {
                setExpanded((prevExpanded) =>
                    prevExpanded.includes(timeSetIndex)
                        ? prevExpanded
                        : [...prevExpanded, timeSetIndex]
                );
                setClickedIdx(timeSetIndex);
            }
        }
    }, [selectedTime, timeSets]);

    useEffect(() => {
        if (clickedIdx !== null && expanded.includes(clickedIdx)) {
            setTimeout(() => {
                const divToScroll = divRefs.current[clickedIdx];
                if (divToScroll) {
                    divToScroll.scrollIntoView({
                        block: "nearest",
                    });
                }
            }, scrollDelay);
        }
    }, [expanded, clickedIdx]);

    return (
        <Stack spacing={ThemeSpaceVariable.Medium}>
            {timeSets.map((timeSet, idx) => (
                <div
                    key={`scrollintoviewhack-${idx}`}
                    ref={(element) => (divRefs.current[idx] = element)}
                    onClick={() => setClickedIdx(idx)}
                >
                    {timeSet.times && timeSet.times.length > 0 && (
                        <Accordion
                            key={`accordion-${idx}`}
                            w="full"
                            variant="noBorder"
                            index={expanded.includes(idx) ? 0 : -1}
                            onChange={() => {
                                handleAccordionChange(idx);
                            }}
                        >
                            <AccordionItem title={timeSet.title}>
                                <AccordionPanel>
                                    {timeSet.comment && timeSet.comment.length && (
                                        <Text
                                            p={`${ThemeSpaceVariable.Small} 0`}
                                            color={ThemeColorVariable.OnSurfaceSubdued}
                                            data-testid={testId.timeSet.comment}
                                        >
                                            {timeSet.comment}
                                        </Text>
                                    )}
                                    <TimeSlotGrid
                                        items={timeSet.times.map((time) => (
                                            <ActivityTimeSlot
                                                key={time.start.format("HH:mm")}
                                                time={time}
                                                selectedTime={selectedTime}
                                                onClick={() => handleTimeSlotClick(time, idx)}
                                                timesToNudge={timesToNudgeMap.get(timeSet.id)}
                                            />
                                        ))}
                                    />
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    )}
                </div>
            ))}
        </Stack>
    );
};

export default TimeSetAccordion;
