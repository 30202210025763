import { useDayStates } from "api/api-hooks/use-day-states";
import moment from "moment";

export function useFirstAvailableDay(selectedMonth: Date): Date | null {
    const dayStates = useDayStates(selectedMonth).data;

    for (const date in dayStates) {
        const day = dayStates[date];
        if (day.open && day.available.length > 0) {
            return new Date(date);
        }
    }
    return null;
}

export function useHasAvailableDaysInMonth(month: Date): boolean {
    const dayStates = useDayStates(month);

    if (dayStates.isLoading) return false;

    const startOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
    const endOfMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0);

    const startDateString = moment(startOfMonth).format("YYYY-MM-DD");
    const endDateString = moment(endOfMonth).format("YYYY-MM-DD");

    const dates = Object?.keys(dayStates.data);

    // Filter the dates to only include those within the specified month
    const availableDaysInMonth = dates.filter((date) => {
        const day = dayStates.data[date];

        return (
            date >= startDateString && date <= endDateString && day.open && day.available.length > 0
        );
    });

    // Check if there are any available days in the given month
    return availableDaysInMonth.length > 0;
}
