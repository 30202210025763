/**
 * Organizes the activity menu and activity data into pairs.
 * This is used for presenting the activities in the summary view.
 * Articles that share the same article group are the same activity
 * what differs is the number of units booked, e.g the number of tables.
 * There is a property on the articles called TableNumber that can be used or presenting it more fine grained.
 */

import { Article } from "types/article";
import { BookingMenu, MenuStatus } from "types/menu";
import { WebBooking } from "types/web-booking";

const getActivities = (reservation: WebBooking) => {
    if (!reservation || !reservation.articles) return [];

    return reservation.articles.reduce((acc: ActivityMenuPair[], activity) => {
        if (acc.find((addedActivity) => addedActivity.articleGroupId === activity.articleGroupId)) {
            /**
             * If the article group is in the accumilator we should not add it again.
             * If we in the future want to show number of tables or booking menus we can accumliate it here
             */
            return acc;
        }
        const menu = reservation.menus.find((menu) => menu.publicId === activity.menuGuid);
        const menuTotalPrice = menu
            ? menu.groups.reduce((menuPrice, group) => {
                  const groupPrice = group.items
                      .map((item) => item.price * item.quantity)
                      .reduce((a, b) => a + b, 0);
                  return menuPrice + groupPrice;
              }, 0)
            : 0;

        const menuToRender = menu?.status === MenuStatus.Confirmed ? menu : ({} as BookingMenu);
        const activityMenuPairs = {
            ...activity,
            ...menuToRender,
            totalPrice: menuTotalPrice,
        };

        // Sort it in order of start time
        const index = acc.findIndex((pair) => pair.start.unix() > activity.start.unix());
        if (index === -1) acc.push(activityMenuPairs);
        else acc.splice(index, 0, activityMenuPairs);

        return acc;
    }, []);
};

export type ActivityMenuPair = Article & BookingMenu & { totalPrice: number };
export default getActivities;
