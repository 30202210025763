/* eslint-disable */

import { State } from "store/store-types";

const isIframe = window.self !== window.top;

export enum TrackingEventType {
    sessionStart = "sessionStart",
    userReload = "userReload",
    userInput = "userInput",
    cancel = "cancel",
    paymentTerminal = "paymentTerminal",
    paymentTerminalFailed = "paymentTerminalFailed",
    backFromPaymentTerminal = "backFromPaymentTerminal",
    backFromPaymentTerminalFailed = "backFromPaymentTerminalFailed",
    bookingFinalized = "bookingFinalized",
    bookingFinalizedFailed = "bookingFinalizedFailed",
}

type TrackingEvent = {
    name: string; // "caspeco-booking-event"
    eventTime?: string; // YYYY-MM-DDTHH:mm:ss.SSSZ
    timeSinceLastEvent?: number; // milliseconds since last event
    timeSinceStart?: number; // milliseconds since start
    type?: TrackingEventType; // "partial" | "complete" | "cancel"
    guests?: number; // 2
    children?: number; // 1
    date?: string; // 2024-12-24
    times?: string[]; // ["12:00", "13:00"]
    isWaitingList?: boolean; // true
    sectionName?: string; // "Lunch"
    webRuleTitle?: string; // "Lunch"
    customerName?: string; // "Företagsnamn"
    bookingType?: string; // "Bokningstyp"
    contact?: {
        firstname?: string;
        lastname?: string;
        email?: string;
        phone?: string;
        acceptNewsletter?: boolean;
    };
};

const startTimeFromStorage = localStorage.getItem("startEventTime");

let reload = true;

let startEventTime: number = startTimeFromStorage ? parseInt(startTimeFromStorage) : Date.now();
localStorage.setItem("startEventTime", startEventTime.toString());

const lastEventTimeFromStorage = localStorage.getItem("lastEventTime");
let lastEventTime: number = lastEventTimeFromStorage
    ? parseInt(lastEventTimeFromStorage)
    : startEventTime;

if (Date.now() - lastEventTime > 60 * 60 * 1000) {
    startEventTime = Date.now();
    localStorage.setItem("startEventTime", startEventTime.toString());
    lastEventTime = Date.now();
}

let lastEvent: TrackingEvent;
function removeUndefinedAndNull(event: any) {
    return Object.keys(event).reduce((acc: any, key) => {
        const value = event[key];
        if (value !== undefined && value !== null) {
            acc[key] = value instanceof Object ? removeUndefinedAndNull(value) : value;
        }
        return acc;
    }, {});
}

export function saveSectionNameAndWebRuleTitle(sectionName: string, webRuleTitle: string) {
    localStorage.setItem("sectionName", sectionName);
    localStorage.setItem("webRuleTitle", webRuleTitle);
}

export function sendTrackingEventToParent(
    state: State,
    type: TrackingEventType = TrackingEventType.userInput
) {
    if (!isIframe) return;

    const now = Date.now();

    let event: TrackingEvent = {
        name: "caspeco-booking-event",
        type,
        guests: state.guests,
        children: state.guestsChildren,
        date: state.date?.format("YYYY-MM-DD"),
        times: state.times?.map((time) => time.start.format("HH:mm")),
        isWaitingList: state.isWaitList,
        customerName: state.customer?.name,
    };
    if (state.contact) {
        event.contact = {
            firstname: state.contact.fname,
            lastname: state.contact.lname,
            email: state.contact.email,
            phone: state.contact.mobile,
            acceptNewsletter: state.contact.newsletter,
        };
    }
    if (state.times?.length) {
        const sectionName = localStorage.getItem("sectionName");
        if (sectionName) {
            event.sectionName = sectionName;
        }
        const webRuleTitle = localStorage.getItem("webRuleTitle");
        if (webRuleTitle) {
            event.webRuleTitle = webRuleTitle;
        }
    }

    event = removeUndefinedAndNull(event);

    // compare with last event
    if (lastEvent && JSON.stringify(lastEvent) === JSON.stringify(event)) return;
    lastEvent = event;

    // add times to event
    event = {
        ...event,
        eventTime: new Date(now).toISOString(),
        timeSinceLastEvent: now - lastEventTime,
        timeSinceStart: now - startEventTime,
    };

    // detect session start
    if (lastEventTime === startEventTime) {
        event.type = TrackingEventType.sessionStart;
        event.timeSinceLastEvent = 0;
    } else if (reload) {
        event.type = TrackingEventType.userReload;
        reload = false;
    }

    lastEventTime = now;
    localStorage.setItem("lastEventTime", lastEventTime.toString());
    window.parent.postMessage({ name: "caspeco-booking-event", ...event }, "*");
}
