/**
 * Contains the test identifiers used by react testing library and in the future playwright.
 * The IDs are organized on component level and each id should preferable be unique so the selectors only get one hit.
 */
const testId = {
    preorder: {
        wrapper: "preorder-wrapper",
        modalwrapper: "preorder-modal-wrapper",
    },
    customizeWizard: {
        wrapper: "customize-wizard-wrapper",
        modalwrapper: "customize-wizard-modal-wrapper",
    },
    summary: {
        wrapper: "summary-wrapper",
    },
    timeSet: {
        comment: "time-set-comment",
    },
};

export default testId;
