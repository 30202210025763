import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Divider } from "@caspeco/casper-ui-library.components.divider";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Skeleton } from "@caspeco/casper-ui-library.components.skeleton";
import { Stack } from "@caspeco/casper-ui-library.components.stack";

const ActivityItemSkeleton = () => {
    return (
        <Box>
            <Flex
                justify="space-between"
                py={ThemeSpaceVariable.Medium}
                align="center"
                gap={ThemeSpaceVariable.Small}
            >
                <Stack spacing={ThemeSpaceVariable.Small} w="full">
                    <Skeleton height="20px" width="60%" />
                    <Skeleton height="16px" width="80%" />
                    <Skeleton height="24px" width="40%" />
                </Stack>
                <Skeleton
                    height="72px"
                    width="72px"
                    minW="72px"
                    borderRadius={ThemeSpaceVariable.Small}
                />
            </Flex>
            <Divider />
        </Box>
    );
};

const ActivityListSkeleton = ({ count = 4 }) => {
    return (
        <Stack spacing={ThemeSpaceVariable.Small}>
            {Array.from({ length: count }).map((_, index) => (
                <ActivityItemSkeleton key={index} />
            ))}
        </Stack>
    );
};

export default ActivityListSkeleton;
