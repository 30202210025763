import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "@caspeco/casper-ui-library.components.modal";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";
import { trackEvent } from "logging/insights";
import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IPrivacyPolicyModal {
    isOpen: boolean;
    onClose: () => void;
}

export const PrivacyPolicyModal = ({ isOpen, onClose }: IPrivacyPolicyModal) => {
    //only populate this once
    const sections: ReactNode[] = [];

    const settings = useExternalBookingSettings().data;
    const { t } = useTranslation();

    useEffect(() => {
        if (isOpen) {
            trackEvent("PrivacyPolicyModalOpened");
        }
    }, [isOpen]);

    if (!sections.length) {
        const addSection = (heading: string, text: string) => {
            sections.push(
                <Heading as="h5" fontWeight="500" key={heading}>
                    {heading}
                </Heading>
            );
            sections.push(text);
        };

        for (let i = 1; i <= 12; i++) {
            addSection(t(`policy.p${i}heading`), t(`policy.p${i}`));
        }
        if (settings?.gdprInfo && settings.gdprInfo.length > 0) {
            addSection(t("policy.ownPolicy"), settings.gdprInfo);
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md">
            <ModalHeader textAlign="center" mt={ThemeSpaceVariable.Large}>
                {t("policy.dialogHeader")}
            </ModalHeader>
            <ModalBody
                display="flex"
                flexDirection="column"
                pb={ThemeSpaceVariable.Large}
                px={ThemeSpaceVariable.Large}
                gap={ThemeSpaceVariable.Medium}
            >
                {sections}
            </ModalBody>
            <ModalFooter padding={ThemeSpaceVariable.Medium}>
                <Button onClick={onClose}>{t("close")}</Button>
            </ModalFooter>
        </Modal>
    );
};
