import { useReservation } from "api/api-hooks/use-create-reservation";
import { useActivityContext } from "context/use-activity-context";
import { useMemo } from "react";
import { Time } from "store/store-types";
import { AvailableTimesSection } from "types/available-times";
import { WebBooking } from "types/web-booking";

const useOriginalSelectedTime = () => {
    const { openActivity } = useActivityContext();
    const { data: reservation } = useReservation();

    const getBookTimeFromSection = (section: AvailableTimesSection, reservation: WebBooking) => {
        if (!reservation?.articles || !section) return null;
        const bookTimes: Time[] = reservation.getBookTimes();

        const matchingTime = bookTimes?.find((time) =>
            section.timeSets.some((timeSet) => time.ruleId === timeSet.id)
        );
        const matchingTimeSet = section.timeSets.find(
            (timeSet) => timeSet.id === matchingTime?.ruleId
        );
        const timeSlot = matchingTimeSet?.times.find((time) =>
            time.start.isSame(matchingTime?.start)
        );

        return timeSlot;
    };

    return useMemo(
        () => getBookTimeFromSection(openActivity, reservation),
        [openActivity, reservation]
    );
};

export { useOriginalSelectedTime };
