import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Grid } from "@caspeco/casper-ui-library.components.grid";
import React from "react";

interface TimeSlotGridProps {
    timeSlots: React.ReactNode[];
}
const TimeSlotGrid = ({ timeSlots }: TimeSlotGridProps) => {
    return (
        <Grid
            templateColumns="repeat(4, 1fr)"
            gap={ThemeSpaceVariable.Medium}
            mt={ThemeSpaceVariable.Small}
        >
            {timeSlots}
        </Grid>
    );
};

export default TimeSlotGrid;
