import {
    ThemeBorderVariable,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeRadiusVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Divider } from "@caspeco/casper-ui-library.components.divider";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { List } from "@caspeco/casper-ui-library.components.list";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useWebBooking } from "api/api-hooks/use-web-booking";
import Price from "components/price";
import { useCurrencyCode } from "hooks/use-currency-code";
import { SpaceBetween } from "layout/space-between";
import { useTranslation } from "react-i18next";

export function MyMenu() {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();
    const { menus, nonTableArticles } = webBooking;

    const border = `${ThemeBorderVariable.XSmall} ${ThemeColorVariable.OnSurfaceBorder}`;
    const currency = useCurrencyCode();

    const firstIndex = 0;
    return (
        <Stack>
            <Heading as="h4" fontWeight={"medium"} fontSize={ThemeFontSizeVariable.Large}>
                {t("menuSummary.choosenMenu")}
            </Heading>
            <List
                border={border}
                borderRadius={ThemeRadiusVariable.Small}
                p={ThemeSpaceVariable.Medium}
            >
                <Stack spacing={ThemeSpaceVariable.XSmall}>
                    {menus?.at(firstIndex).groups.map((group) => (
                        <Box key={Math.random()}>
                            <Text fontWeight="medium" fontSize={ThemeFontSizeVariable.Medium}>
                                {group.name}
                            </Text>
                            <Stack
                                display="flex"
                                flexDirection="column"
                                spacing={ThemeSpaceVariable.None}
                                mt={ThemeSpaceVariable.XSmall}
                                pl={ThemeSpaceVariable.Small}
                            >
                                {group.items.map((article) => (
                                    <Text
                                        color={ThemeColorVariable.OnSurfaceSubdued}
                                        key={`${group.name}-${article.articleID}`}
                                    >
                                        {t("activitySummary.menuItemRow", {
                                            name: article.name,
                                            quantity: article.quantity,
                                            price: t("currency.onlyAmount", {
                                                value: article.price,
                                                display: "symbol",
                                            }),
                                        })}
                                    </Text>
                                ))}
                            </Stack>
                        </Box>
                    ))}
                </Stack>
                <Divider my={ThemeSpaceVariable.Small} />
                <SpaceBetween>
                    <Box>
                        <Text as="span" fontWeight="bold">
                            {`${t("menuSummary.totalCost")} ${currency} `}
                        </Text>
                        <Text as="span" color={ThemeColorVariable.OnSurfaceSubdued}>
                            {t("menuSummary.inkVat")}
                        </Text>
                    </Box>
                    <Price
                        amount={nonTableArticles.reduce((sum, a) => sum + a.outPriceIncVat, 0)}
                    />
                </SpaceBetween>
            </List>
        </Stack>
    );
}
