import { Alert } from "@caspeco/casper-ui-library.components.alert";
import { useTranslation } from "react-i18next";

const WaitListNoShowAlert = () => {
    const { t } = useTranslation();
    return (
        <Alert type="info" iconVerticalAlign="flex-start">
            {t("payment.waitListWithNoShowInfo")}
        </Alert>
    );
};

export default WaitListNoShowAlert;
