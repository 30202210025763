import { WebRule } from "types/web-rule";

export class AvailableTimesSection {
    hidden: number;
    id: number;
    name: string;
    timeSets: WebRule[];
    description: string;
    image: string;
    sortOrder: number;
    disabled: boolean;

    constructor(values?: Partial<AvailableTimesSection>) {
        this.hidden = values?.hidden ?? 0;
        this.id = values?.id ?? 0;
        this.name = values?.name ?? "";
        this.timeSets = values?.timeSets?.map((rule) => new WebRule(rule)) ?? [];
        this.sortOrder = values?.sortOrder ?? 0;
        this.description = values?.description ?? "";
        this.image = values?.image ?? "";
    }
}

export class AvailableTimesUnit {
    id: number;
    name: string;
    timeZone: string;
    sections: AvailableTimesSection[];

    constructor(values?: AvailableTimesUnit) {
        this.id = values?.id ?? 0;
        this.name = values?.name ?? "";
        this.timeZone = values?.timeZone ?? "";
        this.sections =
            values?.sections?.map((section) => new AvailableTimesSection(section)) ?? [];
    }
}
