import { Icons } from "@caspeco/casper-ui-library.components.icon";
import { IconButton } from "@caspeco/casper-ui-library.components.icon-button";
import { useTranslation } from "react-i18next";

interface IBackButton {
    onClick: () => void;
}

const BackButton: React.FC<IBackButton> = ({ onClick }) => {
    const { t } = useTranslation();
    return (
        <IconButton aria-label={t("back")} icon={Icons.Back} variant="ghost" onClick={onClick} />
    );
};

export default BackButton;
