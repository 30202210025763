import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Image } from "@caspeco/casper-ui-library.components.image";
import { Link } from "@caspeco/casper-ui-library.components.link";
import CaspecoIcon from "assets/caspecoIcon.svg";

export function PoweredByCaspeco() {
    return (
        <Link
            display="flex"
            gap={ThemeSpaceVariable.Small}
            px={ThemeSpaceVariable.Medium}
            href="https://www.caspeco.se/bokningssystem"
            target="_blank"
        >
            <Image src={CaspecoIcon} />
        </Link>
    );
}
