import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useTranslation } from "react-i18next";

interface GreetingProps {
    name: string;
    isWaitList: boolean;
}

export default function Greeting({ name, isWaitList }: GreetingProps) {
    const { t } = useTranslation();

    return (
        <Stack
            alignItems="flex-start"
            justifyContent="space-between"
            flexDirection="column"
            style={{ height: "100%" }}
            pt={ThemeSpaceVariable.Medium}
        >
            <div>
                <Heading as={"h1"} fontWeight="700">
                    {t("hi")} {name},
                </Heading>
                <Text>
                    {isWaitList ? t("waitList.waitListDetailsMessage") : t("viewBookingDetails")}
                </Text>
            </div>
        </Stack>
    );
}
