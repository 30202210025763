import { ThemeStyleProps } from "@caspeco/casper-ui-library.base-ui.theme";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useTranslation } from "react-i18next";

interface PriceProps extends ThemeStyleProps {
    amount: number;
    /**
     * @param symbol the currency will be displayed with a symbol such as kr or €.
     * @param name the currency will be displayed with the full name of the currency, such as "Swedish kronor" or "Euro"
     * @param code the ISO (currency code) of the selected currency.
     * @param none returns the number value formatted but without a display
     * @param undefined default to none
     */
    display?: "symbol" | "name" | "code" | "none";
    themeStyleProps?: ThemeStyleProps;
}

export default function Price({ amount, display = "none", ...props }: PriceProps) {
    const { t } = useTranslation();
    return (
        <Text as="span" {...props}>
            {t("currency.onlyAmount", {
                value: amount,
                display: display,
            })}
        </Text>
    );
}
