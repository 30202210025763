import {
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import StatusSuccess from "components/booking-status/status-icon/status-success";
import { useTranslation } from "react-i18next";

export function BookingSuccess() {
    const { t } = useTranslation();
    return (
        <Stack
            alignItems="center"
            spacing={ThemeSpaceVariable.Medium}
            pt={ThemeSpaceVariable.Medium}
        >
            <StatusSuccess />
            <Stack alignItems="center" spacing={ThemeSpaceVariable.Small}>
                <Heading as="h1" maxW="340" fontWeight="500" textAlign="center">
                    {t("thanks")}
                </Heading>
                <Text maxW="496" textAlign="center" fontSize={ThemeFontSizeVariable.Large}>
                    {t("bookingSuccessMessage")}
                </Text>
            </Stack>
        </Stack>
    );
}
