import {
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { Divider } from "@caspeco/casper-ui-library.components.divider";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Icon, Icons } from "@caspeco/casper-ui-library.components.icon";
import { Text } from "@caspeco/casper-ui-library.components.text";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface IFirstAvailableDayProps {
    date: Date;
    goToNextAvailableDay: () => void;
}

const FirstAvailableDay = ({ date, goToNextAvailableDay }: IFirstAvailableDayProps) => {
    const { t } = useTranslation();
    const formattedDate = moment(date).format("DD MMMM");
    return (
        <>
            <Divider my={ThemeSpaceVariable.Large} />
            <Flex align="flex-end" direction="column" gap={ThemeSpaceVariable.Small}>
                <Text fontSize={ThemeFontSizeVariable.Small} fontWeight="medium">
                    {t("firstAvailableDay", { date: formattedDate })}
                </Text>
                <Button
                    variant="link"
                    gap={ThemeSpaceVariable.XSmall}
                    onClick={goToNextAvailableDay}
                >
                    {t("goToFirstAvailableDay")}
                    <Icon icon={Icons.Forward} fontSize="20px" />
                </Button>
            </Flex>
        </>
    );
};

export default FirstAvailableDay;
