import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { usePaymentRule } from "hooks/use-payment-rule";
import { useTranslation } from "react-i18next";
import { PaymentRule } from "types/payment";
import { useReservation } from "api/api-hooks/use-create-reservation";

const ActivityStepContinueButton = ({ onClick }: { onClick: () => void }) => {
    const paymentRule = usePaymentRule();
    const { data: reservation } = useReservation();
    const totalPrice = reservation?.chargeShouldPayAmount;
    const { t } = useTranslation();

    const totalPriceText = t("currency.onlyAmount", {
        value: totalPrice,
        display: "symbol",
    });

    const getButtonText = () => {
        if (paymentRule === PaymentRule.Prepayment) {
            return t("activitySummary.continueToPayment", { totalPriceText });
        }
        return t("activitySummary.continue");
    };
    return (
        <Button size="lg" w="full" onClick={onClick} variant="primary" p={ThemeSpaceVariable.Small}>
            {getButtonText()}
        </Button>
    );
};

export default ActivityStepContinueButton;
