import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

interface PortalProps {
    children: ReactNode;
    containerId: string;
}

const Portal = ({ children, containerId }: PortalProps) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
        return () => setMounted(false);
    }, []);

    if (!mounted) return null;

    const container = document.getElementById(containerId);
    if (!container) return null;

    return createPortal(children, container);
};

export default Portal;
