import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Alert } from "@caspeco/casper-ui-library.components.alert";
import { Icons } from "@caspeco/casper-ui-library.components.icon";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
} from "@caspeco/casper-ui-library.components.modal";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { useReservation } from "api/api-hooks/use-create-reservation";
import ActivityStepContinueButton from "components/selection-components/select-activity/activity-step-continue-button";
import Summary from "components/selection-components/summary/summary";
import { usePaymentRule } from "hooks/use-payment-rule";
import { useTranslation } from "react-i18next";
import { PaymentRule } from "types/payment";

interface ISummary {
    isOpen: boolean;
    onClose: () => void;
    gotoDetailsForm: () => void;
    openActivity: (ruleId: number) => void;
}

const SummaryModal = ({ openActivity, onClose, isOpen, gotoDetailsForm }: ISummary) => {
    const { data: reservation } = useReservation();
    const { t } = useTranslation();
    const paymentRule = usePaymentRule();

    if (!reservation) return null;

    const headerDate = t("time.date", {
        value: reservation?.start.unix(),
    });

    const getAlertText = () => {
        switch (paymentRule) {
            case PaymentRule.Prepayment:
                return t("activitySummary.alert.prepayment");
            case PaymentRule.NoShowFee:
                return t("activitySummary.alert.paymentCardRegistration");
            default:
                return t("activitySummary.alert.paymentAtLocation");
        }
    };

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            size="full"
            scrollBehavior="inside"
            // @ts-expect-error // Todo, this is not exposed from casper
            blockScrollOnMount={false}
        >
            <ModalHeader
                display="flex"
                justifyContent="center"
                p={ThemeSpaceVariable.Medium}
                fontWeight={"400"}
            >
                {t("activitySummary.title", { date: headerDate })}
                <ModalCloseButton isAbsolutePositioned />
            </ModalHeader>
            <ModalBody p={ThemeSpaceVariable.Medium}>
                <Summary openActivity={openActivity} onCloseModal={onClose} />
                <Stack paddingTop={ThemeSpaceVariable.Large} gap={ThemeSpaceVariable.Medium}>
                    <Alert type="info" icon={Icons.PaymentCard}>
                        {getAlertText()}
                    </Alert>
                </Stack>
            </ModalBody>
            <ModalFooter p={ThemeSpaceVariable.Medium}>
                <ActivityStepContinueButton onClick={gotoDetailsForm} />
            </ModalFooter>
        </Modal>
    );
};

export default SummaryModal;
