import { AvailableTimesSection } from "types/available-times";
import { getPaymentUnitTranslation } from "utils/payment-utils";

/**
 * Formats an array of activity durations into a human-readable badge format.
 *
 * @param {number[]} minutesArray - Array of durations in minutes.
 * @returns {string} Formatted time range (e.g., "1 tim 30 min" or "1 tim - 2 tim").
 */
export function getFormattedActivityLength(
    minutesArray: number[],
    t: (key: string) => string
): string {
    if (minutesArray.length === 0) return "";

    const min = Math.min(...minutesArray);
    const max = Math.max(...minutesArray);

    const formatValue = (minutes: number): string => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        if (hours === 0) return `${remainingMinutes} ${t("minutesAbbreviated")}`;
        if (remainingMinutes === 0) return `${hours} ${t("hoursAbbreviated")}`;
        return `${hours} ${t("hoursAbbreviated")} ${remainingMinutes} ${t("minutesAbbreviated")}`;
    };

    const formattedMin = formatValue(min);
    const formattedMax = formatValue(max);

    return formattedMin === formattedMax ? formattedMin : `${formattedMin} - ${formattedMax}`;
}

/**
 * Extracts unformatted activity durations from a section.
 *
 * @param {AvailableTimesSection} section - The section containing time sets.
 * @returns {number[]} Array of activity durations in minutes.
 */
export function getSectionBookingDurations(section: AvailableTimesSection): number[] {
    const activityLength: number[] = [];
    section.timeSets.forEach((timeSet) => {
        const firstTimeSlot = timeSet.times.at(0);
        if (!firstTimeSlot) return;
        const duration =
            firstTimeSlot.end.diff(firstTimeSlot.start, "minutes") - timeSet.recoupTime;
        activityLength.push(duration);
    });

    return activityLength;
}

/**
 * Extracts formatted activity price from a section.
 *
 * @param {AvailableTimesSection} section - The section containing time sets.
 * @returns {number[]} Array of activity durations in minutes.
 */
export function getFormattedActivityPrice(section: AvailableTimesSection): string {
    const paymentRules = section.timeSets.map((timeSet) => {
        if (timeSet.paymentRule) {
            return `${timeSet.paymentAmount}/${getPaymentUnitTranslation(timeSet)}`;
        }
        return "";
    });

    return paymentRules.join("");
}

/**
 * Formats the minimum and maximum group sizes from a section's time sets into a readable range.
 *
 * @param {AvailableTimesSection} section - The section containing time sets.
 * @param {(key: string) => string} t - Translation function for localizing the unit (e.g., "persons").
 * @returns {string} A formatted string representing the group size range (e.g., "2 - 10 persons"), or an empty string if values are invalid.
 */
export function getFormattedGroupSizeRange(
    section: AvailableTimesSection,
    t: (key: string) => string
): string {
    const maxSizes = section.timeSets.map((timeSet) => timeSet.maxGroupSize);
    const minSizes = section.timeSets.map((timeSet) => timeSet.minGroupSize);

    const maxNum = Math.max(...maxSizes);
    const minNum = Math.min(...minSizes);

    if (!(maxNum > 0) || !(minNum > 0)) return "";

    return `${minNum} - ${maxNum} ${t("persons")}`;
}
