import { Alert } from "@caspeco/casper-ui-library.components.alert";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";
import { useWebBooking } from "api/api-hooks/use-web-booking";
import { useTranslation } from "react-i18next";
import { PaymentEventType } from "types/payment";

const PrepaymentEventExpiredAlert = () => {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();
    const { data: settings } = useExternalBookingSettings();
    const unitName = settings.unitsMetaData.name;

    const prepaymentEvent = webBooking.paymentEvents.find(
        (x) => x.type === PaymentEventType.PaymentLinkCharge
    );

    const eventName = prepaymentEvent ? "prepaymentEventExpired" : "cardregistrationEventExpired";
    return (
        <Alert type="info" iconVerticalAlign="flex-start">
            {t(`payment.${eventName}`, {
                unitName,
            })}
        </Alert>
    );
};

export default PrepaymentEventExpiredAlert;
