import { ActivityContext } from "context/activity-context";
import { useContext } from "react";

export const useActivityContext = () => {
    const context = useContext(ActivityContext);
    if (!context) {
        throw new Error("useActivityContext must be used within an ActivityProvider");
    }
    return context;
};
