import {
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeFontVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import EmptyState from "components/empty-state";
import ActivityBadges from "components/selection-components/select-activity/activity-badges/activity-badges";
import ActivityTimeSlot from "components/selection-components/select-activity/activity-time-slot";
import TimeSetAccordion from "components/selection-components/select-activity/time-set-accordion";
import { useTimesToNudge } from "components/selection-components/select-activity/use-times-to-nudge";
import ShowMoreText from "components/show-more-text";
import TimeSlotGrid from "components/time-slot-grid";
import { useTranslation } from "react-i18next";
import { AvailableTimesSection } from "types/available-times";
import testId from "types/test-id";
import { TimeSlot } from "types/time-slot";
import { WebRule } from "types/web-rule";
import { mergeTimeSetsByTitle } from "utils/time-rule-utils";

interface IActivityModal {
    section: AvailableTimesSection;
    selectedTime: TimeSlot;
    setSelectedTime: (timeSlot: TimeSlot) => void;
}

const ActivityModalBody = ({ section, selectedTime, setSelectedTime }: IActivityModal) => {
    const { t } = useTranslation();

    const timeSets = section.timeSets
        .filter((x) => !x.isTextRow && !x.isClosed)
        .reduce((cleanedTimeSets: WebRule[], timeSet) => {
            cleanedTimeSets.push({
                ...timeSet,
                times: timeSet.times.filter(
                    (time) => !time.waitListSeats || time.waitListSeats.length === 0
                ),
            } as WebRule);
            return cleanedTimeSets;
        }, []);
    const mergedTimeSets = mergeTimeSetsByTitle(timeSets);
    const useAccordion = mergedTimeSets.length > 1;
    const timesToNudgeMap = useTimesToNudge(section);
    const hasTimeSlots = mergedTimeSets.some((timeSet) => timeSet.times.length > 0);

    // Todo: If we already have booked activities with the same ID we want to read in the activities timeslot and present them in the UI here to help the user remember that they already have booked an activity that time.
    const singelGrid = () =>
        mergedTimeSets.map((timeSet) => (
            <TimeSlotGrid
                key={`timeSet${timeSet.id}`}
                items={timeSet.times
                    .filter(
                        (timeSlot) => !timeSlot.waitListSeats || timeSlot.waitListSeats.length === 0
                    )
                    .map((time) => (
                        <ActivityTimeSlot
                            key={time.start.format("HH:mm")}
                            time={time}
                            selectedTime={selectedTime}
                            onClick={() => setSelectedTime(time)}
                            timesToNudge={timesToNudgeMap.get(timeSet.id)}
                        />
                    ))}
            />
        ));

    return (
        <Stack>
            <Stack spacing={ThemeSpaceVariable.Small} p={ThemeSpaceVariable.Medium}>
                <Heading
                    as="h2"
                    fontWeight="medium"
                    fontSize={ThemeFontSizeVariable.XLarge}
                    fontFamily={ThemeFontVariable.SubHeader}
                >
                    {section.name}
                </Heading>
                <ShowMoreText
                    text={section.description}
                    initialLines={3}
                    textProps={{ fontWeight: "400" }}
                />
            </Stack>

            <Stack spacing={ThemeSpaceVariable.Small} p={ThemeSpaceVariable.Medium}>
                <Text fontWeight="400">{t("moreInfo")}</Text>
                <ActivityBadges section={section} />
            </Stack>

            <Stack
                p={ThemeSpaceVariable.Medium}
                spacing={useAccordion ? ThemeSpaceVariable.Medium : "none"}
            >
                {hasTimeSlots ? (
                    <>
                        <Heading
                            as="h1"
                            fontSize={ThemeFontSizeVariable.XLarge}
                            pb={
                                mergedTimeSets[0]?.comment
                                    ? ThemeSpaceVariable.None
                                    : ThemeSpaceVariable.Medium
                            }
                        >
                            {t("selectTime")}
                        </Heading>
                        {useAccordion ? (
                            <TimeSetAccordion
                                timeSets={mergedTimeSets}
                                selectedTime={selectedTime}
                                onClick={setSelectedTime}
                                timesToNudgeMap={timesToNudgeMap}
                            />
                        ) : (
                            <>
                                {mergedTimeSets[0]?.comment && (
                                    <Text
                                        p={`${ThemeSpaceVariable.Small} 0`}
                                        color={ThemeColorVariable.OnSurfaceSubdued}
                                        data-testid={testId.timeSet.comment}
                                    >
                                        {mergedTimeSets[0]?.comment}
                                    </Text>
                                )}
                                {singelGrid()}
                            </>
                        )}
                    </>
                ) : (
                    <EmptyState
                        header={t("selectActivity.noTimeHeader")}
                        description={t("selectActivity.noTimeDescription")}
                    ></EmptyState>
                )}
            </Stack>
        </Stack>
    );
};

export default ActivityModalBody;
