import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { FormControl } from "@caspeco/casper-ui-library.components.formcontrol";
import { Grid } from "@caspeco/casper-ui-library.components.grid";
import { ToggleButton } from "@caspeco/casper-ui-library.components.toggle-button";
// eslint-disable-next-line caspeco/discourage-chakra-import
import { InputGroup } from "@chakra-ui/react";
import { useBookingActionsContext, useBookingStateContext } from "hooks/use-booking-state";
import { useTranslation } from "react-i18next";

export const SelectChildrenAmount = () => {
    const { t } = useTranslation();
    const state = useBookingStateContext();
    const { setChildren } = useBookingActionsContext();

    const { guests } = state;

    const handleGuestsChildrenChange = (guestsChildren: number) => setChildren(guestsChildren);

    return (
        <FormControl key="guest-children" id="guestsChildren">
            <InputGroup>
                <Grid templateColumns="repeat(4, 1fr)" gap={ThemeSpaceVariable.Medium} width="100%">
                    {[...Array(guests).keys()].map((children) => {
                        return (
                            <ToggleButton
                                key={children}
                                w="100%"
                                size="lg"
                                gridColumn={!children ? "span 2" : ""}
                                onClick={() => handleGuestsChildrenChange(children)}
                                h={ThemeSpaceVariable.XLarge}
                            >
                                {!children ? t("start.ofWhichNone") : children}
                            </ToggleButton>
                        );
                    })}
                </Grid>
            </InputGroup>
        </FormControl>
    );
};
