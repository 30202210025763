import { ThemeIconSizeVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { useDisclosure } from "@caspeco/casper-ui-library.components.drawer";
import { Icon, Icons } from "@caspeco/casper-ui-library.components.icon";
// eslint-disable-next-line caspeco/discourage-chakra-import
import { useBreakpointValue } from "@chakra-ui/react";

import SideNavigation from "components/side-navigation/side-navigation";
import { useTranslation } from "react-i18next";

export function HamburgerButton() {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const iconSize = useBreakpointValue({
        base: ThemeIconSizeVariable.Medium,
        md: ThemeIconSizeVariable.Large,
    });

    return (
        <>
            <SideNavigation onCloseSideNav={onClose} isSideNavOpen={isOpen} />
            <Button
                key={iconSize}
                aria-label={t("actionShowMore")}
                onClick={onOpen}
                w={{ base: "40px", md: "56px" }}
                h={{ base: "40px", md: "56px" }}
                variant="secondary"
            >
                <Icon icon={Icons.Menu} size={iconSize} />
            </Button>
        </>
    );
}
