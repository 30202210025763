// eslint-disable-next-line caspeco/discourage-chakra-import
import { useDisclosure } from "@chakra-ui/react";
import { ErrorContext, ErrorProviderProps, WebBookingErrorCode } from "context/error-context";
import { useState } from "react";
import { ValidationResult } from "types/validation-result";

export const ErrorProvider = ({ children }: ErrorProviderProps) => {
    const [errorCode, setErrorCode] = useState(WebBookingErrorCode.Undefined);
    const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
    const {
        onOpen: openReservationFailedModal,
        onClose: closeReservationFailedModal,
        isOpen: isOpenReservationFailedModal,
    } = useDisclosure();

    return (
        <>
            <ErrorContext.Provider
                value={{
                    errorCode,
                    setErrorCode,
                    hasErrorCode: errorCode !== WebBookingErrorCode.Undefined,
                    hasPaymentError: errorCode === WebBookingErrorCode.PaymentError,
                    openReservationFailedModal,
                    closeReservationFailedModal,
                    isOpenReservationFailedModal,
                    validationResult,
                    setValidationResult,
                }}
            >
                {children}
            </ErrorContext.Provider>
        </>
    );
};
