import { Checkbox } from "@caspeco/casper-ui-library.components.checkbox";
import { FormControl } from "@caspeco/casper-ui-library.components.formcontrol";
import React from "react";
import { useTranslation } from "react-i18next";
import { useBookingStateContext, useBookingActionsContext } from "hooks/use-booking-state";

const NewsletterField = () => {
    const { t } = useTranslation();
    const { setContact } = useBookingActionsContext();
    const { contact } = useBookingStateContext();

    const handleNewsletterToggle = (event: React.ChangeEvent<HTMLInputElement>) =>
        setContact({ newsletter: event.target.checked });
    return (
        <FormControl>
            <Checkbox
                isChecked={contact?.newsletter}
                onChange={handleNewsletterToggle}
                color="primary"
            >
                {t("contact.newsletterSignup")}
            </Checkbox>
        </FormControl>
    );
};

export default NewsletterField;
