import { Moment } from "moment-timezone";
import { MenuGroup } from "types/menu-group";
import { ChargeType } from "types/charge-type";

export interface BookingMenu {
    publicId: string;
    name: string;
    groups?: MenuGroup[];
    status: MenuStatus;
    guests: number; //comes from parent booking, if any. Used for validation
    message: Message;
    sent: Moment;
    chargeType: ChargeType;
}

export enum MenuStatus {
    TemplateOrUnknown = 0,
    /**
     * Sent to the customer
     */
    Sent = 1,
    /**
     * Answered by the customer
     */
    Confirmed = 2,
}

/**
 * A message that is added to the bookings message column
 */
type Message = string;
