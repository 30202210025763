import axios from "axios";
import config from "config/app.config";
import { getSearchParams } from "search-params";
const { system } = getSearchParams();

const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/v1`,
    headers: { system: system },
});

enum HttpStatusCode {
    NotFound = 404,
    InternalServerError = 500,
    GatewayTimeout = 504,
}

const get = async <T extends object>(input: string): Promise<T> => {
    const response = await axiosInstance.get(input);
    return response.data as T;
};

const post = async <TResponse extends object, TBody>(
    input: string,
    body: TBody
): Promise<TResponse> => {
    const response = await axiosInstance.post(input, body);
    return response.data as TResponse;
};

const put = async <TResponse extends object, TBody>(
    input: string,
    body: TBody
): Promise<TResponse> => {
    const response = await axiosInstance.put(input, body);
    return response.data as TResponse;
};

export { get, post, put };
export { HttpStatusCode };
