import {
    ThemeSpaceVariable,
    ThemeFontSizeVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import StatusFailedIcon from "components/booking-status/status-failed-icon";
import { Text } from "@caspeco/casper-ui-library.components.text";

const StatusFailedWithDialogue = ({
    title,
    message,
}: {
    title: string;
    message: string | JSX.Element;
}) => {
    return (
        <Stack alignItems="center" spacing={ThemeSpaceVariable.Medium}>
            <StatusFailedIcon />
            <Stack spacing="none">
                <Heading as="h1" fontWeight="500" textAlign="center" mb={ThemeSpaceVariable.Small}>
                    {title}
                </Heading>
                <Text maxW="496" textAlign="center" fontSize={ThemeFontSizeVariable.Large}>
                    {message}
                </Text>
            </Stack>
        </Stack>
    );
};

export default StatusFailedWithDialogue;
