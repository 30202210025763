import { ThemeColorVariable, ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { Icon, Icons } from "@caspeco/casper-ui-library.components.icon";
import { useWebBooking } from "api/api-hooks/use-web-booking";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getSearchParams } from "search-params";
import { hasBookingPassed, isWaitListOfferLocked } from "utils/booking";

const BookMoreButton = () => {
    const { t } = useTranslation();
    const location = useLocation() as TypedHistory;
    const isFinal = location.state?.isFinal;

    const { data: webBooking } = useWebBooking();

    const hasPassed = hasBookingPassed(webBooking);
    const isOfferLocked = isWaitListOfferLocked(webBooking);

    const { system } = getSearchParams();
    const offerLocked = isOfferLocked && !hasPassed && !isFinal;
    const alignment = offerLocked ? "center" : "flex-end";
    return (
        <Button
            variant="ghost"
            alignSelf={alignment}
            href={`${window.location.origin}/${system}/${webBooking.unitId}${window.location.search}`}
        >
            {offerLocked ? t("toBookingStart") : t("bookMore")}
            <Icon
                icon={Icons.Forward}
                fontSize="24px"
                color={ThemeColorVariable.OnAccentContainer}
                ml={ThemeSpaceVariable.XSmall}
            />
        </Button>
    );
};

export default BookMoreButton;
