import {
    ThemeFontSizeVariable,
    ThemeIconSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Icon, Icons } from "@caspeco/casper-ui-library.components.icon";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { PhoneNumberLink } from "components/links/phone-number-link";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useTranslation } from "react-i18next";

export function CancelBookingFailed() {
    const { t } = useTranslation();
    return (
        <Stack alignItems="center" pt={ThemeSpaceVariable.Medium}>
            <Icon icon={Icons.SlightlySad} color="#242236" size={ThemeIconSizeVariable.X3Large} />
            <Text maxW="496" textAlign="center" fontSize={ThemeFontSizeVariable.Large}>
                {t("errorInformation.somethingWrong")}
            </Text>
            <PhoneNumberLink />
        </Stack>
    );
}
