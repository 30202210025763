import { HttpStatusCode } from "api/api-methods";
import BrokenLink from "components/error-components/broken-link";
import ServerDidNotRespond from "components/error-components/server-did-not-respond";
import SomethingWrong from "components/error-components/something-wrong";

export type CustomError = Error & {
    status?: HttpStatusCode;
    response?: {
        status?: HttpStatusCode;
        data?: string;
    };
};

export function ErrorView({ error }: { error?: CustomError }) {
    const status: HttpStatusCode | null = error?.status
        ? error.status
        : error?.response?.status
          ? error?.response?.status
          : null;

    switch (status) {
        case null:
            return <SomethingWrong />;
        case HttpStatusCode.NotFound:
            if (error.response.data === "Unit does not exist") return <BrokenLink />;
            return <SomethingWrong />;
        case HttpStatusCode.InternalServerError:
            return <BrokenLink />;
        case HttpStatusCode.GatewayTimeout:
            return <ServerDidNotRespond />;
        default:
            return <SomethingWrong />;
    }
}
