import { useFilteredAvailableTimes } from "api/api-hooks/use-available-times";
import SectionOfAvailableTimes from "components/selection-components/select-time/section-of-available-times";
import { useBookingStateContext } from "hooks/use-booking-state";

export function AvailableTimes() {
    const { date } = useBookingStateContext();
    const availableTimes = useFilteredAvailableTimes(date);

    const sections = availableTimes.map((section) => (
        <SectionOfAvailableTimes key={`section${section.id}`} availableTimesSection={section} />
    ));

    return <div className="available-times">{sections}</div>;
}
