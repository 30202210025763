/**
 * Appends query parameters to a base URL.
 *
 * @param {string} base - The base URL.
 * @param {Record<string, string | undefined>} params - Query parameters to append.
 * Parameters with `undefined` values are ignored.
 * @returns {string} - The complete URL with query parameters.
 *
 * @example
 * buildUrl("https://example.com/api", { search: "books", category: "fiction" });
 * // Returns: "https://example.com/api?search=books&category=fiction"
 */
export const urlBuilder = (base: string, params: Record<string, string | undefined>): string => {
    const url = new URL(base);
    Object.entries(params).forEach(([key, value]) => {
        if (value) url.searchParams.append(key, value);
    });
    return url.toString();
};
