import { Moment } from "moment-timezone";

export interface MenuItem {
    name: string;
    description: string;
    articleID: number;
    quantity: number;
    price: number;
    time: Moment;
    /**
     * The ways an item displays
     */
    webFlags: MenuItemWebFlags;
    /**
     * if this article is temporarily inactivated (out of stock)
     */
    inactivatedUntil: Moment;
    wasOutOfStock: boolean;
    vatPercent: number;
}

/**
 * Ways an item can be displayed
 */
export enum MenuItemWebFlags {
    TextPriceAndQuantity = 0,
    TextAndQuantity = 1,
    TextOnly = 2,
    Hide = 3,
}
