import { BookingStatus } from "types/booking-status";
import { parseDate } from "utils/moment-utils";

export class BookingChange {
    changeDate: IMoment;
    status: BookingStatus;

    constructor(values?: BookingChange) {
        this.changeDate = parseDate(values?.changeDate);
        this.status = values?.status ?? BookingStatus.History;
    }
}
