import { ThemeColorVariable, ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { mergeTimeSetsByTitle } from "utils/time-rule-utils";
import TimeSet from "./time-set/time-set";
import { AvailableTimesSection } from "types/available-times";

interface ISectionOfAvailableTimes {
    expanded?: boolean;
    availableTimesSection: AvailableTimesSection;
}

const SectionOfAvailableTimes = (props: ISectionOfAvailableTimes) => {
    const noTimeSetsWithAvailableTimes = !props.availableTimesSection.timeSets.find(
        (x) => x.times.length > 0 || x.isTextRow
    );
    if (noTimeSetsWithAvailableTimes) return null;

    const listOfRules = mergeTimeSetsByTitle(props.availableTimesSection.timeSets);

    return (
        <>
            <Heading
                as="h5"
                fontWeight="500"
                flex="1"
                textAlign="left"
                mb={ThemeSpaceVariable.Medium}
                color={ThemeColorVariable.OnBackgroundHeader}
            >
                {props.availableTimesSection.name}
            </Heading>
            {listOfRules.map((timeSet) => (
                <TimeSet key={`timeSet${timeSet.id}`} timeSet={timeSet} />
            ))}
        </>
    );
};

export default SectionOfAvailableTimes;
