import Header from "layout/header";
import { useEffect, useRef, useState } from "react";
import { CSSVarHeaderHeight } from "style-variables";
import { ThemeColorVariable, ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Image as CaspecoImage } from "@caspeco/casper-ui-library.components.image";
import { Skeleton } from "@caspeco/casper-ui-library.components.skeleton";
import "./layout.css";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";
// eslint-disable-next-line caspeco/discourage-chakra-import
import { AspectRatio, useBreakpointValue } from "@chakra-ui/react";
import { LayoutContext } from "layout/layout-context";
import { Grid, GridItem } from "@caspeco/casper-ui-library.components.grid";

const isIframe = window.self !== window.top;
let observer: ResizeObserver;

const Layout = ({ children }: { children: JSX.Element }) => {
    const [showCoverImage, setShowCoverImage] = useState(!isIframe);
    const { data: settings } = useExternalBookingSettings();
    const [isCoverImageLoaded, setIsCoverImageLoaded] = useState(false);
    const [hasAsideContent, setHasAsideContent] = useState(false);
    const asideRef = useRef<HTMLDivElement>(null);

    const aspectRatio = useBreakpointValue({
        base: 1 / 1,
        md: 32 / 9,
    });

    const themeSettings = settings?.themeSettings;
    const coverImageUrl =
        aspectRatio === 1
            ? themeSettings?.coverImageOneByOne.imageUrl
            : themeSettings?.coverImageThirtyTwoByNine.imageUrl;
    const hasCoverImage = Boolean(
        themeSettings?.coverImageOneByOne.imageUrl &&
            themeSettings?.coverImageThirtyTwoByNine.imageUrl
    );

    const container = useRef(null);
    if (isIframe && container.current && !observer) {
        observer = new ResizeObserver((entries) => {
            const { height } = entries[0].contentRect;
            window.parent.postMessage(`caspeco-booking-height ${height}`, "*");
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        observer.observe(container.current);
    }

    useEffect(() => {
        if (isIframe) {
            const parentElement = document.body.parentNode as Element | null;
            if (parentElement && parentElement.classList) {
                parentElement.classList.add("iframe");
            }
        }
    }, []);

    useEffect(() => {
        if (!coverImageUrl) {
            return;
        }
        const handleCoverImageLoad = () => {
            setIsCoverImageLoaded(true);
        };
        const coverImage = new Image();
        coverImage.src = coverImageUrl;
        coverImage.onload = handleCoverImageLoad;

        return () => {
            coverImage.onload = null;
        };
    }, [coverImageUrl]);

    useEffect(() => {
        const checkAsideContent = () => {
            if (asideRef.current) {
                const hasElements = asideRef.current.children.length > 0;

                setHasAsideContent(hasElements);
            }
        };
        checkAsideContent();

        const mutationObserver = new MutationObserver(checkAsideContent);
        if (asideRef.current) {
            mutationObserver.observe(asideRef.current, {
                childList: true, // Watch for changes in child elements
            });
        }

        // Cleanup observer on component unmount
        return () => {
            mutationObserver.disconnect();
        };
    }, []);

    // Define the specific column width for the aside, taken from Figma
    const asideWidth = "22.5rem";

    return (
        <Box ref={container} width="100%" minH="100%" color={ThemeColorVariable.OnSurface}>
            <Grid
                templateColumns={["1fr", "1fr", "1fr", `1fr 1fr ${asideWidth}`]}
                templateRows="auto 1fr"
                minH="100vh"
            >
                {!isIframe && (
                    <GridItem colSpan={3} rowSpan={1}>
                        <Box>
                            <Header
                                shouldHideCoverImage={!showCoverImage}
                                hasCoverImage={hasCoverImage}
                            />
                            <Box minH={CSSVarHeaderHeight} w="full">
                                {showCoverImage && hasCoverImage && (
                                    <AspectRatio
                                        position="relative"
                                        ratio={aspectRatio}
                                        width="full"
                                        height="full"
                                        overflow="hidden"
                                    >
                                        {isCoverImageLoaded ? (
                                            <CaspecoImage
                                                src={coverImageUrl}
                                                alt="Cover image"
                                                objectFit="cover"
                                                width="full"
                                                height="full"
                                            />
                                        ) : (
                                            <Skeleton
                                                startColor={ThemeColorVariable.NeutralContainer}
                                                endColor={ThemeColorVariable.NeutralContainer}
                                                width="full"
                                                height="full"
                                            />
                                        )}
                                    </AspectRatio>
                                )}
                            </Box>
                        </Box>
                    </GridItem>
                )}

                <GridItem rowSpan={1} colSpan={[3, 3, 3, hasAsideContent ? 2 : 3]}>
                    <Flex justifyContent="center" h="100%">
                        <Flex
                            id="content"
                            px={ThemeSpaceVariable.Medium}
                            py={ThemeSpaceVariable.Medium}
                            w="100%"
                            maxWidth="560px"
                            justifyContent="center"
                        >
                            <LayoutContext.Provider value={{ setShowCoverImage }}>
                                {children}
                            </LayoutContext.Provider>
                        </Flex>
                    </Flex>
                </GridItem>

                <GridItem
                    rowSpan={1}
                    colSpan={1}
                    borderLeft={`1px solid ${ThemeColorVariable.OnBackgroundBorder}`}
                    display={hasAsideContent ? "block" : "none"}
                >
                    <Box id="aside-portal-container" ref={asideRef} h="100%" />
                </GridItem>
            </Grid>
        </Box>
    );
};

export default Layout;
