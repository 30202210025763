import { Alert } from "@caspeco/casper-ui-library.components.alert";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";

import { Trans } from "react-i18next";

const CancelNotPossibleAlert = () => {
    const { data: settings } = useExternalBookingSettings();
    const name = settings.unitsMetaData.name;
    return (
        <Alert type="info">
            <Box>
                <Trans
                    i18nKey="notPossibleToCancelBooking"
                    values={{ name }}
                    components={{ bold: <Text as="span" fontWeight="bold" /> }}
                >
                    <Text as="span" fontWeight="bold" />
                </Trans>
            </Box>
        </Alert>
    );
};

export default CancelNotPossibleAlert;
