import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post, put } from "api/api-methods";
import { useError } from "hooks/use-error";
import queryClient from "query-client";
import { GUID_STORAGE_KEY } from "store";
import { State, Time } from "store/store-types";
import { BookingMenu } from "types/menu";
import {
    CreateReservationParameters,
    removeTime,
    UpdateReservationParameters,
} from "types/request-parameters";
import { WebBooking } from "types/web-booking";

export function useReservation() {
    const id = localStorage.getItem(GUID_STORAGE_KEY);
    return useQuery({
        queryKey: ["booking", id],
        queryFn: async () => {
            return get(`webBooking/webBookings/${id}`).then((response: Partial<WebBooking>) => {
                return new WebBooking(response);
            });
        },
        enabled: !!id,
    });
}

export const useCreateReservation = () => {
    const { setErrorCode, openReservationFailedModal } = useError();

    const createReservation = async (state: State) => {
        const data: CreateReservationParameters = new CreateReservationParameters(state);
        let booking: WebBooking;

        try {
            booking = await post<WebBooking, CreateReservationParameters>(
                `WebBooking/WebBookings`,
                data
            );
            localStorage.setItem(GUID_STORAGE_KEY, booking.guid);
            queryClient.setQueryData<WebBooking>(
                ["booking", booking.guid],
                new WebBooking(booking)
            );
        } catch (error) {
            const response = (error as { response?: { data?: { code: number } } })?.response?.data;
            console.error(error);
            const errorCode = response?.code;
            if (typeof errorCode === "number") {
                setErrorCode(errorCode);
                openReservationFailedModal();
            }
        }

        return booking;
    };

    return useMutation({
        mutationFn: createReservation,
        scope: {
            id: "webbooking-create",
        },
    });
};

export const useUpdateReservation = () => {
    const { setErrorCode, openReservationFailedModal } = useError();

    const updateReservation = async ({
        state,
        timesToUpdate,
        removeTimes,
    }: {
        state: State;
        timesToUpdate: Time[];
        removeTimes?: removeTime[];
    }) => {
        const data: UpdateReservationParameters = new UpdateReservationParameters(
            state,
            timesToUpdate,
            localStorage.getItem(GUID_STORAGE_KEY),
            removeTimes
        );
        let booking: WebBooking;

        try {
            booking = await put<WebBooking, UpdateReservationParameters>(
                `WebBooking/WebBookings`,
                data
            );
            queryClient.setQueryData<WebBooking>(
                ["booking", booking.guid],
                new WebBooking(booking)
            );
        } catch (error) {
            const response = (error as { response?: { data?: { code: number } } })?.response?.data;
            console.error(error);
            const errorCode = response?.code;
            if (typeof errorCode === "number") {
                setErrorCode(errorCode);
                openReservationFailedModal();
            }
        }

        return booking;
    };

    return useMutation({
        mutationFn: updateReservation,
        scope: {
            id: "webbooking-create",
        },
    });
};

export const useBookingMenu = () => {
    const { setErrorCode, openReservationFailedModal } = useError();

    const updateBookingMenu = async (bookingMenu: BookingMenu) => {
        let menu: BookingMenu[] = [];

        try {
            menu = await post<BookingMenu[], BookingMenu[]>(
                "BookingMenu/BookingMenusExternal/Respond",
                [bookingMenu]
            );
            await queryClient.invalidateQueries({
                queryKey: ["booking", localStorage.getItem(GUID_STORAGE_KEY)],
            });
        } catch (error) {
            const response = (error as { response?: { data?: { code: number } } })?.response?.data;
            console.error(error);
            const errorCode = response?.code;
            if (typeof errorCode === "number") {
                setErrorCode(errorCode);
                openReservationFailedModal();
            }
        }

        return menu;
    };

    return useMutation({
        mutationFn: updateBookingMenu,
        scope: {
            id: "webbooking-create",
        },
    });
};
