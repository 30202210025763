/**
 * Proxy file that can be used for connecting to Raygun, Cloud or other log system when needed.
 * Correnty log to console if in dev environment.
 */

import { getInitialCrashReportingSetting } from "components/app/app-helper";

// /**
//  * Used for logging an error that is not recoverable.
//  */
// const logError = (message: string) => {
//     if (!getInitialCrashReportingSetting()) console.error(message);
// };

/**
 * Used for logging a warning. Something is wrong but we can recover.
 */
const logWarn = (message: string) => {
    if (!getInitialCrashReportingSetting()) console.warn(message);
};

export { logWarn };
