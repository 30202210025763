import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Modal, ModalFooter } from "@caspeco/casper-ui-library.components.modal";
import {
    useBookingMenu,
    useCreateReservation,
    useReservation,
    useUpdateReservation,
} from "api/api-hooks/use-create-reservation";
import ContinueButton from "components/selection-components/customize-wizard/continue-button";
import CustomizeWizardModalBody from "components/selection-components/customize-wizard/customize-wizard-modal-body-wrapper";
import PreorderBody from "components/selection-components/preorder/preorder-body";
import ActivityModalBody from "components/selection-components/select-activity/activity-modal-body";
import { useOriginalSelectedTime } from "components/selection-components/select-activity/use-original-selected-time";
import { useRemoveActivity } from "components/selection-components/summary/use-remove-activity";
import { ActivityContext } from "context/activity-context";
import { useBookingActionsContext, useBookingStateContext } from "hooks/use-booking-state";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Time } from "store/store-types";
import { BookingMenu, MenuStatus } from "types/menu";
import testId from "types/test-id";
import { TimeSlot } from "types/time-slot";
import { saveSectionNameAndWebRuleTitle } from "utils/event-utils";

interface ICustomizeAcitivity {
    selectedTime: TimeSlot;
    setSelectedTime: (timeSlot: TimeSlot) => void;
}
const CustomizeActivity = ({ selectedTime, setSelectedTime }: ICustomizeAcitivity) => {
    const { t } = useTranslation();
    const { openActivity, setOpenActivity, currentQuantities, currentTotalQuantity } =
        useContext(ActivityContext);
    const state = useBookingStateContext();
    const { setState } = useBookingActionsContext();
    const [step, setStep] = useState(0);
    const originalTime = useOriginalSelectedTime();

    const onCloseModal = () => {
        setOpenActivity(null);
        setSelectedTime(undefined);
    };

    const { removeActivity } = useRemoveActivity(onCloseModal, onCloseModal);

    const preorderButtonText =
        currentTotalQuantity === 0
            ? t("preorder.continueWithoutItems")
            : t("preorder.continueWithItemsPrefix", {
                  totalQuantity: currentTotalQuantity,
                  suffix:
                      currentTotalQuantity === 1
                          ? t("preorder.continueWithItemsSingularSuffix")
                          : t("preorder.continueWithItemsPluralisSuffix"),
              });

    const createBooking = useCreateReservation();
    const { data: reservation } = useReservation();
    const updateBooking = useUpdateReservation();

    const shouldRemoveActivity = originalTime && !selectedTime;
    const activityButtonText = shouldRemoveActivity
        ? t("removeActivityX", { activityName: openActivity.name })
        : !selectedTime
          ? t("addWithEllipsis")
          : t("continueWithTimeX", { time: selectedTime.start.format("HH:mm") });

    const onActivityModalContinue = async () => {
        if (shouldRemoveActivity) {
            await removeActivity(originalTime.ruleId, openActivity.id);
            return;
        }

        const newTime: Time = {
            ruleId: selectedTime.ruleId,
            start: selectedTime.start,
        };
        saveSectionNameAndWebRuleTitle(selectedTime.name, selectedTime.title);
        const times = state.times ? [...state.times, newTime] : [newTime];
        const stateWithTimes = {
            ...state,
            times,
        };
        setState(stateWithTimes);

        let booking;
        if (reservation) {
            booking = await updateBooking.mutateAsync({
                state: stateWithTimes,
                timesToUpdate: [newTime],
            });
        } else {
            booking = await createBooking.mutateAsync(stateWithTimes);
        }
        const updatedArticle = booking.articles.find((article) => {
            return selectedTime.start.isSame(article.start);
        });

        /**
         * If the article has a menu we show the preorder step, otherwise we reset the step and close the modal.
         */
        const articeHasMenu = updatedArticle?.menuGuid && updatedArticle.menuGuid !== "";
        const menu = booking?.menus?.find(
            (menu) => menu.publicId === updatedArticle.menuGuid
        )?.groups;
        const menuHasItems = menu?.some((group) => group.items.length > 0);
        if (articeHasMenu && menuHasItems) {
            setStep(1);
        } else {
            onCloseModal();
            setStep(0);
        }
    };

    // Preorder selection
    const onContinueWithoutMenu = () => {
        setOpenActivity(null);
        setSelectedTime(undefined);
        setStep(0);
    };

    // Ensure the latest version of preorderState is used
    const latestCurrentQuantities = useRef(currentQuantities);
    useEffect(() => {
        latestCurrentQuantities.current = currentQuantities;
    }, [currentQuantities]);

    const updateBookingMenu = useBookingMenu();
    const onPreorderContinue = async () => {
        if (currentTotalQuantity > 0) {
            // We have to use the current timeslot start time and match it to the activity to open the correct menu
            const currentActivity = reservation?.articles.find((activity) =>
                activity.start.isSame(selectedTime?.start)
            );
            const currentMenu = reservation?.menus?.find(
                (menu) =>
                    menu.status === MenuStatus.Sent && menu.publicId === currentActivity?.menuGuid
            );
            const bookingMenuWithChoices: BookingMenu = {
                ...currentMenu,
                groups: currentMenu.groups.map((group, groupIndex) => ({
                    ...group,
                    items: group.items.map((item, itemIndex) => ({
                        ...item,
                        quantity: latestCurrentQuantities.current[groupIndex][itemIndex],
                    })),
                })),
            };
            await updateBookingMenu.mutateAsync(bookingMenuWithChoices);
        }
        return onContinueWithoutMenu();
    };

    const headerBackground = openActivity?.image
        ? `https://imagecdn.caspeco.net/images/${openActivity.image}?AspectRatio=SIXTEEN_BY_NINE&Type=webp`
        : undefined;
    const bodyComponent =
        step === 0 ? (
            <ActivityModalBody
                key={"wizardbody-0"}
                section={openActivity}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
            />
        ) : (
            <PreorderBody
                key={"wizardbody-1"}
                selectedTime={selectedTime}
                // onNoMenu={onContinueWithoutMenu} // Todo
            />
        );

    const continueButton =
        step === 0 ? (
            <ContinueButton
                key={"wizardbutton-0"}
                isDisabled={!selectedTime && !originalTime}
                buttonText={activityButtonText}
                onContinue={onActivityModalContinue}
            />
        ) : (
            <ContinueButton
                key={"wizardbutton-1"}
                buttonText={preorderButtonText}
                onContinue={onPreorderContinue}
            />
        );

    return (
        <Modal
            isOpen={Boolean(openActivity)}
            onClose={() => {
                setStep(0);
                onCloseModal();
            }}
            closeOnOverlayClick={false}
            size="md"
            data-testid={testId.customizeWizard.modalwrapper}
        >
            <CustomizeWizardModalBody
                onBack={step === 0 ? undefined : () => setStep(0)}
                headerImage={step === 0 ? headerBackground : undefined}
            >
                {bodyComponent}
                <Box h="100vh" />
            </CustomizeWizardModalBody>
            <ModalFooter p={ThemeSpaceVariable.Medium}>{continueButton}</ModalFooter>
        </Modal>
    );
};

export default CustomizeActivity;
