import { Alert } from "@caspeco/casper-ui-library.components.alert";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { useAvailableTimes } from "api/api-hooks/use-available-times";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";
import SomethingWrong from "components/error-components/something-wrong";
import { PhoneNumberLink } from "components/links/phone-number-link";
import { AvailableTimes } from "components/selection-components/select-time/available-times";
import { useBookingStateContext } from "hooks/use-booking-state";
import { useTranslation } from "react-i18next";
import { BookingClosed } from "./booking-closed";
import TimeSetSkeleton from "./time-set/time-set-skeleton";

export const SelectTime = () => {
    const { t } = useTranslation();
    const externalBookingSettings = useExternalBookingSettings();
    const state = useBookingStateContext();
    const { guests, date } = state;

    const availableTimes = useAvailableTimes(date);

    if (availableTimes.isLoading) {
        return <TimeSetSkeleton />;
    }
    if (availableTimes.isError) {
        return <SomethingWrong />;
    }

    const units = availableTimes.data;

    const allTimesUnavailable = units?.every((x) =>
        x.sections.every((y) =>
            y.timeSets.every((z) =>
                z.times.every(
                    (a: {
                        availableSeats: { includes: (arg0: number) => unknown };
                        disabled: unknown;
                    }) => !a.availableSeats?.includes(guests) || a.disabled
                )
            )
        )
    );

    const hasAnyWaitList = units?.some((x) =>
        x.sections.some((y) =>
            y.timeSets.some((z) =>
                z.times.some(
                    (a: { waitListSeats: unknown; disabled: unknown }) =>
                        a.waitListSeats && !a.disabled
                )
            )
        )
    );

    const { messageBookingClosed } = externalBookingSettings.data;

    const noAvailableTimes = allTimesUnavailable;

    const showDayClosed = noAvailableTimes && !messageBookingClosed;

    if (showDayClosed) {
        <Flex key="dayClosedBox">
            <Alert type="info" title={t("start.dayClosed")}>
                <PhoneNumberLink />
            </Alert>
        </Flex>;
    }

    const showMessageBookingClosed = noAvailableTimes && messageBookingClosed && !hasAnyWaitList;
    if (showMessageBookingClosed) {
        return <BookingClosed units={units} messageBookingClosed={messageBookingClosed} />;
    }

    return <AvailableTimes />;
};
