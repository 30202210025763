import {
    ThemeBorderVariable,
    ThemeColorVariable,
    ThemeIconSizeVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Icon, Icons } from "@caspeco/casper-ui-library.components.icon";

const StatusFailedIcon = () => {
    const border = `${ThemeBorderVariable.Small} ${ThemeColorVariable.Error}`;
    return (
        <Box
            backgroundColor={ThemeColorVariable.ErrorContainer}
            border={border}
            padding="5px"
            borderRadius="50%"
        >
            <Icon
                icon={Icons.Remove}
                size={ThemeIconSizeVariable.X3Large}
                color={ThemeColorVariable.Error}
            />
        </Box>
    );
};

export default StatusFailedIcon;
