import { Box } from "@caspeco/casper-ui-library.components.box";
import { DatePicker } from "@caspeco/casper-ui-library.components.datepicker";
import { getNonAvailableDatesForGuests, useDayStates } from "api/api-hooks/use-day-states";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";
import { getLocaleFromLang } from "helpers/language-helper";
import { useFirstAvailableDay, useHasAvailableDaysInMonth } from "hooks/use-available-days";
import { useBookingActionsContext, useBookingStateContext } from "hooks/use-booking-state";
import i18n from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { isDateWithinBookingWindow, shouldDisableDate } from "utils/datepicker-utils";
import SelectDateSkeleton from "../select-date-skeleton";
import DatePickerLegend from "./date-picker-legend";
import FirstAvailableDay from "./first-available-day";

interface ISelectDateProps {
    selectedMonth: Date;
    setSelectedMonth: (date: Date) => void;
}
export const SelectDate = ({ selectedMonth, setSelectedMonth }: ISelectDateProps) => {
    const [datePickerLocale, setDatePickerLocale] = useState(getLocaleFromLang(i18n.language));
    const externalBookingSettings = useExternalBookingSettings().data;
    const state = useBookingStateContext();
    const { guests, date } = state;
    const { setDate } = useBookingActionsContext();
    const dayState = useDayStates(selectedMonth);

    const firstAvailableDay = useFirstAvailableDay(selectedMonth);
    const hasAvailableDaysForMonth = useHasAvailableDaysInMonth(selectedMonth);

    const goToNextAvailableDay = () => setSelectedMonth(firstAvailableDay);

    useEffect(() => {
        const setLocaleKey = (lng: string) => {
            const selectedLocaleKey = getLocaleFromLang(lng);
            setDatePickerLocale(selectedLocaleKey);
        };

        i18n.on("languageChanged", setLocaleKey);
        setLocaleKey(i18n.language);

        return () => {
            i18n.off("languageChanged", setLocaleKey);
        };
    }, []);

    if (dayState.isLoading) {
        return <SelectDateSkeleton />;
    }
    const { closedDays, waitListDays, fullyBookedDays } = getNonAvailableDatesForGuests(
        dayState.data,
        guests
    );

    const nextMonthDate = moment(selectedMonth).add(1, "months");
    const nextMonth = nextMonthDate.startOf("month").toDate();
    const isLastAvailableMonth = !isDateWithinBookingWindow(nextMonth, externalBookingSettings);
    return (
        <>
            <Box>
                <DatePicker
                    key={selectedMonth.toString()}
                    selectedDate={date?.toDate()}
                    onDateChange={(date: Date) => setDate(date)}
                    localeString={datePickerLocale}
                    selectedMonth={selectedMonth}
                    onMonthChange={setSelectedMonth}
                    strikeThroughDates={closedDays}
                    dots={{
                        yellowDotDates: waitListDays,
                        redDotDates: fullyBookedDays,
                    }}
                    shouldDisableDate={(date: Date) =>
                        shouldDisableDate(date, externalBookingSettings)
                    }
                    disablePastMonths
                    disableForwardNavigation={isLastAvailableMonth}
                />
            </Box>
            <DatePickerLegend fullyBookedDays={fullyBookedDays} waitListDays={waitListDays} />

            {firstAvailableDay && !hasAvailableDaysForMonth && (
                <FirstAvailableDay
                    date={firstAvailableDay}
                    goToNextAvailableDay={goToNextAvailableDay}
                />
            )}
        </>
    );
};
