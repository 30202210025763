import {
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Divider } from "@caspeco/casper-ui-library.components.divider";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Icons } from "@caspeco/casper-ui-library.components.icon";
import { IconButton } from "@caspeco/casper-ui-library.components.icon-button";
import { List, ListItem } from "@caspeco/casper-ui-library.components.list";
import { useDisclosure } from "@caspeco/casper-ui-library.components.modal";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useReservation } from "api/api-hooks/use-create-reservation";
import ConfirmationDialog from "components/confirm-dialog";
import Price from "components/price";
import getActivities, {
    ActivityMenuPair,
} from "components/selection-components/summary/activity-menu-pair";
import { useRemoveActivity } from "components/selection-components/summary/use-remove-activity";
import { useCurrencyCode } from "hooks/use-currency-code";
import { usePaymentRule } from "hooks/use-payment-rule";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentRule } from "types/payment";

interface ISummary {
    /**
     * Used for open an activity, if it is not present we assume we are at details form
     */
    openActivity?: (ruleId: number) => void;
    onCloseModal?: () => void;
}

const Summary = ({ openActivity, onCloseModal }: ISummary) => {
    const { isOpen, onClose: onCloseDialog, onOpen } = useDisclosure();
    const { data: reservation } = useReservation();
    const [activityToDelete, setActivityToDelete] = useState<ActivityMenuPair | null>(null);
    const { removeActivity } = useRemoveActivity(onCloseModal, onCloseDialog);
    const { t } = useTranslation();
    const paymentRule = usePaymentRule();

    const isDetailsForm = !openActivity;

    const activityMenuPairs = getActivities(reservation);
    const totalPrice = activityMenuPairs.reduce(
        (totalPrice, activity) => totalPrice + activity.totalPrice,
        0
    );
    const currency = useCurrencyCode();

    const showPrepaySumSection = isDetailsForm && totalPrice !== 0;
    // If we have No Show and a total price we want to only present the total price here. The no-show will be handled closer to the payment button
    const shouldShowPrepayText = paymentRule !== PaymentRule.NoShowFee;
    const handleDelete = async (ruleId: number, sectionId: number) => {
        await removeActivity(ruleId, sectionId);
    };

    return (
        <>
            <List spacing={isDetailsForm ? ThemeSpaceVariable.Small : ThemeSpaceVariable.None}>
                {activityMenuPairs.map((activity, index: number) => (
                    <ListItem key={`activitysummmaryitem-${index}`}>
                        <Stack flexDirection="row" justifyContent={"space-between"}>
                            <Stack
                                p={`${isDetailsForm ? ThemeSpaceVariable.None : ThemeSpaceVariable.Medium} 0`}
                                spacing={ThemeSpaceVariable.XSmall}
                            >
                                <Heading
                                    as="h4"
                                    fontSize={ThemeFontSizeVariable.Medium}
                                    fontWeight={"500"}
                                >
                                    {t("activitySummary.activityTitle", {
                                        articleGroupName: activity.articleGroupName,
                                        startTime: t("time.singleTime", {
                                            value: activity.start.toDate().valueOf(),
                                        }),
                                    })}
                                </Heading>
                                {activity.groups && (
                                    <List>
                                        {activity?.groups?.map((group, gindex) => {
                                            const activityItem = {
                                                name: activity.articleGroupName,
                                                quantity: activity.quantity,
                                                price: activity.outPriceIncVat,
                                            };
                                            const rows =
                                                gindex === 0
                                                    ? [activityItem, ...group.items]
                                                    : group.items;
                                            return rows.map((item, index) => (
                                                <ListItem key={`g${gindex}i${index}`}>
                                                    <Text
                                                        paddingLeft={ThemeSpaceVariable.Small}
                                                        color={ThemeColorVariable.OnSurfaceSubdued}
                                                    >
                                                        {t("activitySummary.menuItemRow", {
                                                            name: item.name,
                                                            quantity: item.quantity,
                                                            price: t("currency.onlyAmount", {
                                                                value: item.price,
                                                                display: "symbol",
                                                            }),
                                                        })}
                                                    </Text>
                                                </ListItem>
                                            ));
                                        })}
                                    </List>
                                )}
                            </Stack>
                            {!isDetailsForm && (
                                <Stack p={`${ThemeSpaceVariable.Medium} 0`} alignItems="end">
                                    <Text
                                        fontSize={ThemeFontSizeVariable.Medium}
                                        fontWeight={"500"}
                                        whiteSpace="nowrap"
                                    >
                                        <Price amount={activity.totalPrice} display="symbol" />
                                    </Text>
                                    <Flex gap={ThemeSpaceVariable.Small}>
                                        <IconButton
                                            aria-label={t("summary.editActivity", {
                                                activityName: activity.articleGroupName,
                                            })}
                                            icon={Icons.Edit}
                                            onClick={
                                                openActivity &&
                                                (() => openActivity(activity.ruleId))
                                            }
                                            cursor={openActivity ? "pointer" : "default"}
                                            variant="ghost"
                                        />
                                        <IconButton
                                            aria-label={t("summary.removeActivityX", {
                                                activityName: activity.articleGroupName,
                                            })}
                                            icon={Icons.Delete}
                                            onClick={() => {
                                                setActivityToDelete(activity);
                                                onOpen();
                                            }}
                                            variant="ghost"
                                        />
                                    </Flex>
                                </Stack>
                            )}
                        </Stack>
                        {!isDetailsForm && <Divider />}
                    </ListItem>
                ))}
            </List>
            {showPrepaySumSection && (
                <>
                    <Divider my={ThemeSpaceVariable.Small} />
                    <List gap={ThemeSpaceVariable.Small}>
                        <ListItem display="flex" gap={ThemeSpaceVariable.XSmall}>
                            <Text>{`${t("menuSummary.totalCost")} ${currency}`}</Text>
                            <Text color={ThemeColorVariable.OnSurfaceSubdued}>
                                {t("menuSummary.inkVat")}
                            </Text>
                            <Text marginLeft="auto">
                                {t("currency.onlyAmount", {
                                    value: totalPrice,
                                    display: "symbol",
                                })}
                            </Text>
                        </ListItem>
                        {shouldShowPrepayText && (
                            <ListItem display={"flex"} justifyContent="space-between">
                                <Text fontWeight="700">{t("menuSummary.subtotal")}</Text>
                                <Text fontWeight="700">
                                    {t("currency.onlyAmount", {
                                        value: reservation?.chargeShouldPayAmount,
                                        display: "symbol",
                                    })}
                                </Text>
                            </ListItem>
                        )}
                    </List>
                </>
            )}
            <ConfirmationDialog
                primaryButtonText={t("summary.cancel")}
                secondaryButtonText={t("summary.remove")}
                isOpen={isOpen}
                title={t("summary.removeActivityX", {
                    activityName: activityToDelete?.articleGroupName,
                })}
                message={t("summary.areYouSureRemove", {
                    activityName: activityToDelete?.articleGroupName,
                })}
                onClose={onCloseDialog}
                secondaryAction={() =>
                    handleDelete(activityToDelete?.ruleId, activityToDelete?.sectionId)
                }
                primaryAction={onCloseDialog}
            />
        </>
    );
};

export default Summary;
