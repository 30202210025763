export enum ChargeType {
    None = 0,
    PrePayment = 1,
    NoShow = 2,
    /**
     * This is obselete and should not be used anymore.
     */
    NoShowReservation = 3,
    ManualChargeOrCapturedNoShowPayment = 4,
    ExtarnalCharge = 5,
}
