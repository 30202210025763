import { State } from "store/store-types";

export function getStepperProgressPercentage(
    state: State,
    activateWebChildren: boolean,
    maxWebGuests: number,
    isTimesConfirmed: boolean
) {
    const { guests, guestsChildren, date } = state;
    const allSteps = [
        { condition: guests && guests <= maxWebGuests },
        activateWebChildren
            ? {
                  condition:
                      guestsChildren !== null &&
                      guestsChildren !== undefined &&
                      guestsChildren >= 0,
              }
            : null,
        { condition: date },
        { condition: isTimesConfirmed },
    ].filter(Boolean);

    const totalSteps = allSteps.length;

    const completedSteps = allSteps.reduce((completed, item) => {
        return item.condition ? completed + 1 : completed;
    }, 0);

    const maxPercentage = 100;
    const stepPercentageValue = maxPercentage / totalSteps;

    return stepPercentageValue * completedSteps;
}
