import { Dispatch, SetStateAction, createContext } from "react";
import { AvailableTimesSection } from "types/available-times";

type Step = "time" | "select" | "customize" | "preorder" | "summary" | null;

interface ActivityContext {
    openActivity?: AvailableTimesSection | null;
    setOpenActivity?: Dispatch<SetStateAction<AvailableTimesSection | null>>;
    currentStep?: Step;
    setCurrentStep?: Dispatch<SetStateAction<Step>>;
    currentQuantities?: number[][];
    currentTotalQuantity?: number;
    setCurrentQuantities?: Dispatch<SetStateAction<number[][]>>;
}

export const ActivityContext = createContext<ActivityContext | undefined>(undefined);
export type { Step };
