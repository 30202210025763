import { ActivityContext, Step } from "context/activity-context";
import { useState, ReactNode } from "react";
import { AvailableTimesSection } from "types/available-times";

export const ActivityProvider = ({ children }: { children: ReactNode }) => {
    const [openActivity, setOpenActivity] = useState<AvailableTimesSection | null>(null);
    const [currentStep, setCurrentStep] = useState<Step>(null);
    const [currentQuantities, setCurrentQuantities] = useState<number[][]>([]);

    return (
        <ActivityContext.Provider
            value={{
                openActivity,
                setOpenActivity,
                currentStep,
                setCurrentStep,
                currentQuantities,
                setCurrentQuantities,
                currentTotalQuantity: currentQuantities.reduce(
                    (sum, term) => sum + term.reduce((sum, term) => sum + term, 0),
                    0
                ),
            }}
        >
            {children}
        </ActivityContext.Provider>
    );
};
