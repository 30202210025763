import {
    ThemeColorVariable,
    ThemeSpaceVariable,
    ThemeShadowVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Alert } from "@caspeco/casper-ui-library.components.alert";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Icons } from "@caspeco/casper-ui-library.components.icon";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { useReservation } from "api/api-hooks/use-create-reservation";
import Portal from "components/portal/portal";
import ActivityStepContinueButton from "components/selection-components/select-activity/activity-step-continue-button";
import Summary from "components/selection-components/summary/summary";
import { usePaymentRule } from "hooks/use-payment-rule";
import { t } from "i18next";
import React from "react";
import { PaymentRule } from "types/payment";

interface ISummaryAside {
    handleOpenActivity: (ruleId: number) => void;
    gotoDetailsForm: () => void;
}

const SummaryAside = ({ handleOpenActivity, gotoDetailsForm }: ISummaryAside) => {
    const paymentRule = usePaymentRule();
    const { data: reservation } = useReservation();

    const getAlertText = () => {
        switch (paymentRule) {
            case PaymentRule.Prepayment:
                return t("activitySummary.alert.prepayment");
            case PaymentRule.NoShowFee:
                return t("activitySummary.alert.paymentCardRegistration");
            default:
                return t("activitySummary.alert.paymentAtLocation");
        }
    };
    const headerDate = t("time.date", {
        value: reservation?.start.unix(),
    });

    return (
        <Portal containerId="aside-portal-container">
            <aside
                style={{
                    height: "100%",
                    borderLeft: `1px solid ${ThemeColorVariable.OnBackgroundBorder}`,
                }}
            >
                <Flex
                    flexDirection="column"
                    height="100%"
                    pt={ThemeSpaceVariable.Large}
                    display={{
                        base: "none",
                        lg: reservation?.articles?.length > 0 ? "flex" : "none",
                    }}
                    overflow="hidden"
                >
                    <Box
                        p={`0 ${ThemeSpaceVariable.Medium} ${ThemeSpaceVariable.Medium} ${ThemeSpaceVariable.Medium}`}
                    >
                        <Heading
                            as="h3"
                            fontWeight="500"
                            fontSize="xl"
                            pb={ThemeSpaceVariable.Medium}
                        >
                            {t("activitySummary.title", { date: headerDate })}
                        </Heading>
                        <Summary openActivity={handleOpenActivity} />
                        <Stack
                            pt={ThemeSpaceVariable.Large}
                            pb={ThemeSpaceVariable.Large}
                            gap={ThemeSpaceVariable.Medium}
                        >
                            <Alert type="info" icon={Icons.PaymentCard}>
                                {getAlertText()}
                            </Alert>
                        </Stack>
                    </Box>
                    <Stack
                        mt="auto"
                        boxShadow={ThemeShadowVariable.Footer}
                        p={ThemeSpaceVariable.Medium}
                    >
                        <ActivityStepContinueButton onClick={gotoDetailsForm} />
                    </Stack>
                </Flex>
            </aside>
        </Portal>
    );
};

export default SummaryAside;
