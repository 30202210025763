import { useQuery } from "@tanstack/react-query";
import moment from "moment-timezone";
import { Moment } from "moment/moment";
import qs from "qs";
import { useParams } from "react-router-dom";
import { get } from "./axios-base";
import { getSearchParams } from "search-params";
import { AvailableTimesUnit } from "types/available-times";
import { dateToString } from "utils/moment-utils";
import { useBookingStateContext } from "hooks/use-booking-state";
import { WebRuleBookingType } from "types/web-rule";

interface AvailableTimesParams {
    date: string;
    unitId: string;
    sectionId?: number[];
    tag?: string;
}

export function useAvailableTimes(date: Moment) {
    const { unitId } = useParams();
    const { sectionIds, tag } = getSearchParams();
    const { guests } = useBookingStateContext();
    const params: AvailableTimesParams = {
        date: dateToString(date),
        unitId,
    };

    if (sectionIds) {
        params.sectionId = sectionIds;
    }

    if (tag) {
        params.tag = tag;
    }

    return useQuery({
        queryKey: ["availableTimes", date?.format("YYYY-MM-DD")],
        queryFn: async () => {
            const response = await get(`/WebBooking/availableTimes`, {
                params,
                paramsSerializer: (params: Record<string, unknown>) => {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
                transformResponse: (data: unknown) => {
                    const units = (JSON.parse(data as string) as AvailableTimesUnit[]).map(
                        (unit) => new AvailableTimesUnit(unit)
                    );
                    const now = moment();
                    units.forEach((unit) => {
                        const { name } = unit;
                        unit.sections.forEach((section) => {
                            section.timeSets.forEach((timeSet) => {
                                const { title } = timeSet;
                                timeSet.times.forEach((time) => {
                                    time.name = name;
                                    time.title = title;

                                    time.ruleId = timeSet.id;
                                    time.availableUntil = moment(
                                        time.availableUntil,
                                        "YYYY-MM-DDTHH:mm:ss"
                                    );
                                    time.start = moment(time.start, "YYYY-MM-DDTHH:mm:ss");
                                    time.end = moment(time.end, "YYYY-MM-DDTHH:mm:ss");

                                    const isTimeExpired = now.isAfter(time.availableUntil);
                                    const isSeatsUnavailable =
                                        !time.availableSeats.includes(guests);
                                    const isWaitListUnavailable =
                                        !time.waitListSeats?.includes(guests);

                                    if (
                                        isTimeExpired ||
                                        (isSeatsUnavailable && isWaitListUnavailable)
                                    ) {
                                        time.disabled = true;
                                    }
                                });
                            });
                        });
                    });

                    return units;
                },
            });
            return response.data as AvailableTimesUnit[];
        },
        enabled: !!date,
    });
}

export function useFilteredAvailableTimes(date: Moment) {
    const { data: availableTimesData, isLoading, isError } = useAvailableTimes(date);

    if (isLoading || isError) return [];

    if (!Array.isArray(availableTimesData) || availableTimesData.length === 0) {
        return [];
    }

    const availableTimesCopy = { ...availableTimesData[0] };
    let availableTimes = availableTimesCopy.sections;

    // Do not display sections with no active web rules
    availableTimes = availableTimes.filter((section) => section.timeSets.length > 0);

    availableTimes.forEach((section) => {
        // Mark sections as disabled if all their rules or times are unavailable
        section.disabled = section.timeSets.every(
            (timeSet) => timeSet.isClosed || timeSet.times.length === 0
        );

        // Filter out non-booking type timesets
        section.timeSets = section.timeSets.filter(
            (timeSet) => timeSet.bookingType === WebRuleBookingType.Booking
        );

        section.timeSets.forEach((timeSet) => {
            // Filter out disabled times
            timeSet.times = timeSet.times.filter((time) => !time.disabled);
        });
    });

    // Sort sections on sort order
    availableTimes.sort((a, b) => a.sortOrder - b.sortOrder);

    // Sort sections to show disabled sections last
    availableTimes.sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1));

    return availableTimes;
}
