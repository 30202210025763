import { useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import qs from "qs";
import { useParams } from "react-router-dom";
import { getSearchParams } from "search-params";
import { get } from "./axios-base";
import { DayState, DayStateStatus } from "types/day-state";
import { dateToString } from "utils/moment-utils";

interface Days {
    [n: string]: { available: number[]; waitList: number[]; open: boolean };
}
interface DayStatesParams {
    includeAvailableSeats: boolean;
    includeFirstAvailableMonth: boolean;
    fromDate: string;
    toDate: string;
    unitId: string;
    sectionId?: number[];
    tag?: string;
}
export function useDayStates(date: Date) {
    const { unitId } = useParams();
    const { sectionIds, tag } = getSearchParams();
    const m = moment(date);

    const startOfMonth = m.startOf("month");
    const today = moment().startOf("day");
    const fromDate = startOfMonth.isAfter(today) ? startOfMonth : today;

    const params: DayStatesParams = {
        includeAvailableSeats: true,
        includeFirstAvailableMonth: true,
        fromDate: dateToString(fromDate),
        toDate: dateToString(m.add(1, "month").startOf("month")),
        unitId,
    };

    if (sectionIds) {
        params.sectionId = sectionIds;
    }

    if (tag) {
        params.tag = tag;
    }

    return useQuery({
        queryKey: ["dayStates", m.format("YYYY-MM")],
        queryFn: async () => {
            const response = await get(`/WebBooking/dayStates`, {
                params,
                paramsSerializer: (params: unknown) => {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
                transformResponse: (data: unknown) => {
                    const days: Days = {};
                    const payload = JSON.parse(data as string) as DayState[];
                    payload.forEach((dayState) => {
                        const key = moment(dayState.day).format("YYYY-MM-DD");
                        if (!days[key]) days[key] = { available: [], waitList: [], open: false };

                        let { available, waitList, open } = days[key];

                        if (dayState.availableSeats)
                            available = [...available, ...dayState.availableSeats];
                        if (dayState.waitListSeats)
                            waitList = [...waitList, ...dayState.waitListSeats];
                        if (dayState.state === DayStateStatus.Open) open = true;
                        days[key] = { available, waitList, open };
                    });

                    return days;
                },
            });
            return response.data as Days;
        },
    });
}
export function getNonAvailableDatesForGuests(days: Days, guests: number) {
    const closedDays: Date[] = [];
    const waitListDays: Date[] = [];
    const fullyBookedDays: Date[] = [];

    Object.entries(days).forEach(([key, { available, waitList, open }]) => {
        if (!open) {
            closedDays.push(new Date(key));
        } else if (!available.includes(guests)) {
            if (waitList.includes(guests)) {
                waitListDays.push(new Date(key));
            } else {
                fullyBookedDays.push(new Date(key));
            }
        }
    });
    return { closedDays, waitListDays, fullyBookedDays };
}
