export enum IsoCountryCode {
    None = 0,
    SE,
    NO,
    DK,
    BE,
    EE,
    FI,
    US,
    RU,
    NL,
    ES,
    LT,
    LV,
}
