import "config/i18n";
import MyBooking from "pages/booking-details/my-booking";
import { ErrorView } from "pages/error-view";

import BookingWizard from "pages/booking-wizard";
import PaymentConfirmation from "pages/payment-confirmation";
import { BookingProvider } from "store/store";

const routes = [
    {
        path: "/",
        errorElement: <ErrorView />,
        element: <BookingProvider />,
        children: [
            {
                path: "/booking/:system/:bookingId",
                element: <MyBooking />,
            },
            {
                path: "/:system/:unitId",
                element: <BookingWizard />,
            },
            {
                path: "/:system/:unitId/payment-confirmation",
                element: <PaymentConfirmation />,
            },
        ],
    },
];
export default routes;
