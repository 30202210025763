import { MenuItem } from "./menu-item";

export interface MenuGroup {
    /**
     * Defines the ways a group displays itself and applies conditions.
     * (Types 0-5 are copied from the legacy menu in BC.)
     */
    groupType: MenuGroupType;
    // Todo: We should have a  description for the menu that the restaurant could use for presenting it.
    //    description?: string
    // footer info?
    // disclamer?: string

    name: string;
    /**
     * Value usage depends on ArticleGroupType; it may be maximum different article choices etc.
     */
    choices: number;
    items?: MenuItem[];
    guests: number;
    /**
     * Determines whether it's possible to submit a group with all items without quantity
     */
    optional: boolean;
    /**
     * If true, menu group is default collapsed when presented to guest Todo: Obsolete?
     */
    isCollapsed: boolean;
}

export enum MenuGroupType {
    /** View only; nothing should be filled in or booked (booking only via phone). */
    ViewOnly = 0,

    /** Unclear usage (possibly only for conference booking). Likely replaced by LimitedChoices. */
    SingleChoice = 1,

    /** Free selection of items. */
    AmountChoice = 2,

    /** Can book at most X different types of items. */
    LimitedChoices = 3,

    /** Must book for all guests. */
    LimitedMinChoicesToGuests = 4,

    /** Must book for all guests, with a maximum of X different types of items. */
    LimitedMinMaxChoicesToGuests = 5,

    /** Must book for all guests (newer version of LimitedMinChoicesToGuests). */
    LimitedToGuests = 6,

    /** Must book for all guests, with a maximum of X different types of items (newer version of LimitedMinMaxChoicesToGuests). */
    LimitedToGuestsAndChoices = 7,

    /** Can select multiple choices (potentially replacing older types). */
    MultipleChoices = 8,
}
