import axios from "axios";
import config from "config/app.config";
import { getSearchParams } from "search-params";
const { system } = getSearchParams();

const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/v1`,
    headers: { system: system },
});

export const get = axiosInstance.get.bind(axiosInstance);
