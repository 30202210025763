import {
    ThemeColorVariable,
    ThemeRadiusVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { keyframes } from "@emotion/react";
import { cloneElement, ReactElement } from "react";

const pulseBackdrop = keyframes`
   0%, 30% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  40% {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    opacity: 0.24;
    transform: translate(-4px, -4px);
  }
  100% {
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    opacity: 0;
    transform: translate(-8px, -8px); 
  }
`;

interface IPulseAnimation {
    children: JSX.Element;
    isActive: boolean;
    forceBorder?: boolean;
}

const PulseAnimation = ({ children, isActive, forceBorder }: IPulseAnimation) => {
    if (!isActive) return children;
    return (
        <Box position="relative">
            <Box
                position="absolute"
                height={ThemeSpaceVariable.XLarge}
                borderRadius={ThemeRadiusVariable.Medium}
                bg={ThemeColorVariable.Primary}
                // @ts-expect-error // ! TODO: Include animation in ThemeStyleProps for box in casper-ui-library
                animation={`${pulseBackdrop} ease-out 1160ms infinite`}
            />
            {forceBorder
                ? cloneElement(children as ReactElement, {
                      border: `1px solid ${ThemeColorVariable.Primary}`,
                  })
                : children}
        </Box>
    );
};

export default PulseAnimation;
