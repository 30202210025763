import moment, { Moment } from "moment";
import { BookingStatus } from "types/booking-status";
import { ChargeType } from "types/charge-type";
import { DayState, BookedStatus } from "types/day-state";
import { ExternalBookingSettings } from "types/external-booking-settings";
import { PaymentEventType, ChargeStatus } from "types/payment";
import { TimeSlot } from "types/time-slot";
import { WaitListOfferStatus } from "types/wait-list-offer";
import { WebBooking } from "types/web-booking";

export const dayStatesToUniqueDates = (dayStates: DayState[]): Date[] => {
    const uniqueDates: Date[] = [];
    const uniqueDaysSet: Set<string> = new Set();

    dayStates.forEach((dayState: DayState) => {
        const formattedDay = dayState.day.format("YYYY-MM-DD");
        if (!uniqueDaysSet.has(formattedDay)) {
            uniqueDaysSet.add(formattedDay);
            uniqueDates.push(dayState.day.toDate());
        }
    });

    return uniqueDates;
};

const getDayStatesByDate = (dayStates: DayState[], date: Moment): DayState[] => {
    const matchingDayStates: DayState[] = [];

    dayStates.forEach((dayState: DayState) => {
        if (dayState.day.isSame(date, "day")) {
            matchingDayStates.push(dayState);
        }
    });

    return [...matchingDayStates];
};

// This method only checks the dayState.bookedStatus, it does not check the number of available seats
export const getBookedStatusByDate = (dayStates: DayState[], date: Moment): BookedStatus => {
    // grab all dayStates that matches the date
    const matchingDayStates = getDayStatesByDate(dayStates, date);

    // If no seats are available, return FullyBooked
    const noSeatsAvailable = matchingDayStates.every(
        (dayState) => dayState.bookedStatus === BookedStatus.FullyBooked
    );

    // If no seats are available, but there are waitlist seats, return WaitList
    const hasWaitlistSeats = matchingDayStates.some(
        (dayState) => dayState.bookedStatus === BookedStatus.WaitList
    );

    // If there are available seats, return Available
    const hasAvailableSeats = matchingDayStates.some(
        (dayState) => dayState.bookedStatus === BookedStatus.Available
    );

    if (noSeatsAvailable && !hasWaitlistSeats) {
        return BookedStatus.FullyBooked;
    }
    if (hasWaitlistSeats) {
        return BookedStatus.WaitList;
    }

    if (hasAvailableSeats) {
        return BookedStatus.Available;
    }

    return BookedStatus.Undefined;
};

// This method checks dayState.availableSeats and dayState.waitlistSeats
export const getBookedStatusByGuestCount = (
    dayStates: DayState[],
    date: Moment,
    nrOfGuests: number
): BookedStatus => {
    const matchingDayStates = getDayStatesByDate(dayStates, date);

    const hasAvailableSeats = matchingDayStates.some(
        (dayState) => dayState.availableSeats && dayState.availableSeats.includes(nrOfGuests)
    );

    const hasWaitlistSeats = matchingDayStates.some((dayState) => {
        const hasWaitList = !!dayState.waitListSeats;
        if (hasWaitList) {
            return dayState.waitListSeats.includes(nrOfGuests);
        }
        return false;
    });

    const allIsUndefined = matchingDayStates.every(
        (dayState) => dayState.bookedStatus === BookedStatus.Undefined
    );

    if (hasAvailableSeats) {
        return BookedStatus.Available;
    }

    if (hasWaitlistSeats) {
        return BookedStatus.WaitList;
    }

    if (allIsUndefined) {
        return BookedStatus.Undefined;
    }

    return BookedStatus.FullyBooked;
};

export const groupedTimes = (times: TimeSlot[]) =>
    times.reduce((acc: { [key: string]: TimeSlot[] }, time) => {
        if (time.unavailability !== null || !time.disabled) {
            const hour = time.start.format("HH");
            if (!acc[hour]) {
                acc[hour] = [];
            }
            acc[hour].push(time);
        }
        return acc;
    }, {});

export const getSortedHourKeys = (hours: string[]) =>
    hours.sort((a, b) => {
        // TODO: Change this to the actual opening hour
        const openingHour = 6;
        const numA = parseInt(a, 10);
        const numB = parseInt(b, 10);

        // Compare hours taking into account the opening hour
        if (numA < openingHour && numB >= openingHour) {
            return 1;
        }
        if (numB < openingHour && numA >= openingHour) {
            return -1;
        }
        return numA - numB;
    });

export function isWaitList(booking: WebBooking) {
    if (!booking) return false;

    if (booking.history) {
        const lastStatusBeforeCancel = booking.history
            .filter((x) => x.status !== BookingStatus.Canceled)
            .at(-1).status;
        return (
            booking.status === BookingStatus.WaitList ||
            lastStatusBeforeCancel === BookingStatus.WaitList
        );
    }
    return booking.status === BookingStatus.WaitList;
}

export function hasBookingPassed(booking: WebBooking) {
    return moment() > booking?.start;
}

export function isNoShowBooking(booking: WebBooking) {
    return booking.status === BookingStatus.NoShow;
}
export function isBookingCanceled(booking: WebBooking) {
    return booking.status === BookingStatus.Canceled || booking.status === BookingStatus.NoShow;
}

export function cancelAllowed(booking: WebBooking, settings: ExternalBookingSettings) {
    if (!booking || !settings) return false;

    const isAlreadyCanceled = booking.status === BookingStatus.Canceled;
    const isCancelDisabled = settings.disableCancel;
    const isCancelWindowPassed =
        booking.cancelAllowedBeforeUtc && moment() > booking.cancelAllowedBeforeUtc;
    const isBookingPassed = hasBookingPassed(booking);

    const isWaitListBooking = isWaitList(booking);

    if (isWaitListBooking) {
        return !(isAlreadyCanceled || isBookingPassed);
    }

    return !(isCancelWindowPassed || isCancelDisabled || isAlreadyCanceled || isBookingPassed);
}

export function hasWaitListOffers(booking: WebBooking) {
    if (!booking) return false;
    return booking.waitListOffers && booking.waitListOffers.length > 0;
}

export function hasAvailableWaitListOffers(booking: WebBooking) {
    if (!booking) return false;
    return (
        booking.waitListOffers &&
        booking.waitListOffers.length > 0 &&
        booking.waitListOffers.some((w) => w.offerStatus === WaitListOfferStatus.Available)
    );
}

export function getAvailableWaitListOffer(booking: WebBooking) {
    return booking.waitListOffers?.find(
        (offer) => offer.offerStatus === WaitListOfferStatus.Available
    );
}

export function isWaitListOfferLocked(booking: WebBooking) {
    if (!booking) return false;
    const webTimeRule = booking.webBookingRules?.at(0);
    if (!webTimeRule) return false;
    const ruleStartTime = moment(booking.start).startOf("day").add(webTimeRule?.startTime, "hours");
    const offerStartTime = getAvailableWaitListOffer(booking)?.startTime;

    if (!offerStartTime) return false;

    const availableUntil =
        webTimeRule?.lockPerTime || webTimeRule?.lockTime === 0
            ? offerStartTime.clone().subtract(webTimeRule?.lockTime, "minutes")
            : ruleStartTime.clone().subtract(webTimeRule?.lockTime, "minutes");
    const now = moment();

    return now.isAfter(availableUntil);
}
export function isNoShowLink(booking: WebBooking) {
    const lastPaymentEvent = booking.paymentEvents?.at(-1);
    return lastPaymentEvent?.type === PaymentEventType.NoShowLink;
}
export function hasUnpaidPrepayment(booking: WebBooking) {
    return (
        booking.chargeType === ChargeType.PrePayment &&
        booking.chargePayedAmount < booking.chargeShouldPayAmount
    );
}
export function hasUnhandledNoShowLink(booking: WebBooking) {
    return (
        booking.chargeType === ChargeType.NoShow &&
        booking.chargeStatus !== ChargeStatus.CardRegistered
    );
}
function hasExpiredPrepaymentLink(booking: WebBooking, isPaymentLink: boolean) {
    return (
        isPaymentLink &&
        booking.chargePayedAmount === 0 &&
        (booking.chargeType === ChargeType.PrePayment ||
            booking.chargeType === ChargeType.ManualChargeOrCapturedNoShowPayment) &&
        (booking.chargeStatus === ChargeStatus.InactivatedByUser ||
            !booking.chargePaymentLinkExpirationDate?.isAfter(moment.now()))
    );
}
function hasExpiredNoShowLink(booking: WebBooking, isPaymentLink: boolean) {
    return (
        isPaymentLink &&
        booking.chargePayedAmount === 0 &&
        booking.chargeType === ChargeType.NoShow &&
        (booking.chargeStatus === ChargeStatus.InactivatedByUser ||
            !booking.chargePaymentLinkExpirationDate?.isAfter(moment.now()))
    );
}
export function isPaymentEventExpired(booking: WebBooking, isPaymentLink: boolean) {
    const isPrepaymentExpired = hasExpiredPrepaymentLink(booking, isPaymentLink);
    const isNoShowAndCardNotRegisteredExpired = hasExpiredNoShowLink(booking, isPaymentLink);

    return isPrepaymentExpired || isNoShowAndCardNotRegisteredExpired;
}
export function hasUnhandledPaymentEventLink(booking: WebBooking, isPaymentLink: boolean) {
    return isPaymentLink && (hasUnpaidPrepayment(booking) || hasUnhandledNoShowLink(booking));
}
