import { useMutation } from "@tanstack/react-query";
import { useWebBooking } from "api/api-hooks/use-web-booking";
import { post } from "api/api-methods";
import { trackEvent } from "logging/insights";
import { useParams } from "react-router-dom";
import { urlBuilder } from "search-params/url-builder";
import { GUID_STORAGE_KEY } from "store";
import { State } from "store/store-types";
import { BookingStatus } from "types/booking-status";
import { PaymentTerminalParameters } from "types/request-parameters";
import { WebBooking } from "types/web-booking";
import { getAvailableWaitListOffer } from "utils/booking";
import { sendTrackingEventToParent, TrackingEventType } from "utils/event-utils";

export const usePaymentTerminal = () => {
    const paymentTerminal = async ({
        state,
        webBooking,
        isNoShow,
    }: {
        state: State;
        webBooking?: WebBooking;
        isPrepayment?: boolean;
        isNoShow?: boolean;
    }) => {
        const guid = localStorage.getItem(GUID_STORAGE_KEY);
        let returnUrl = `${document.location.origin + document.location.pathname}/payment-confirmation${document.location.search}`;

        if (isNoShow) returnUrl += "&isNoShow=true";

        const data = new PaymentTerminalParameters(returnUrl, state, webBooking);

        let eventName = "PaymentTerminal";
        let terminalUrl: string;

        const startTime = new Date().getTime();
        try {
            terminalUrl = await post<never, PaymentTerminalParameters>(
                `WebBooking/WebBookings/${guid}/PaymentTerminal`,
                data
            );
            window.location.replace(terminalUrl);
        } catch (error) {
            console.error(error);
            eventName = "PaymentTerminalFailed";
        }

        const endTime = new Date().getTime();
        const loadTime = endTime - startTime;
        trackEvent(eventName, {
            guests: state.guests,
            children: state.guestsChildren,
            date: state.times[0].start.toISOString(),
            isWaitingList: state.times.length > 1,
            ms: loadTime,
        });

        sendTrackingEventToParent(
            state,
            eventName === "PaymentTerminal"
                ? TrackingEventType.paymentTerminal
                : TrackingEventType.paymentTerminalFailed
        );

        return terminalUrl;
    };

    return useMutation({
        mutationFn: paymentTerminal,
        scope: {
            id: "webbooking-create",
        },
    });
};

export const usePaymentTerminalMyBooking = () => {
    const { data: webBooking } = useWebBooking();
    const { system } = useParams();
    const paymentTerminal = async ({
        state,
        isWaitList,
        isPaymentLink,
    }: {
        state: State;
        isWaitList?: boolean;
        isPaymentLink?: boolean;
    }) => {
        const guid = webBooking.guid;

        const baseUrl = `${document.location.origin}/${system}/${webBooking.unitId}/payment-confirmation`;
        const queryParams: Record<string, string | undefined> = {
            offerStartTime: isWaitList
                ? getAvailableWaitListOffer(webBooking).startTime?.format("YYYY-MM-DD[T]HH:mm:ss")
                : undefined,
            paymentLink: isPaymentLink ? "true" : undefined,
        };
        const returnUrl = urlBuilder(baseUrl, queryParams);

        const data = new PaymentTerminalParameters(returnUrl, state, webBooking);

        let eventName = "PaymentTerminal";
        let terminalUrl: string;

        const startTime = new Date().getTime();
        try {
            terminalUrl = await post<never, PaymentTerminalParameters>(
                `WebBooking/WebBookings/${guid}/PaymentTerminal`,
                data
            );
            window.location.replace(terminalUrl);
        } catch (error) {
            eventName = "PaymentTerminalFailed";
            console.error(error);
        }

        const endTime = new Date().getTime();
        const loadTime = endTime - startTime;

        trackEvent(eventName, {
            guests: webBooking.guests,
            children: webBooking.guestsChildren,
            date: webBooking.start.toISOString(),
            isWaitingList: webBooking.status === BookingStatus.WaitList,
            ms: loadTime,
        });

        sendTrackingEventToParent(
            state,
            eventName === "PaymentTerminal"
                ? TrackingEventType.paymentTerminal
                : TrackingEventType.paymentTerminalFailed
        );

        return terminalUrl;
    };

    return useMutation({
        mutationFn: paymentTerminal,
        scope: {
            id: "webbooking-create",
        },
    });
};
