import { ThemeColorVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { SpaceBetween } from "layout/space-between";

interface BookingSummaryItemProps {
    label: string;
    value?: string | number | JSX.Element;
    children?: JSX.Element;
}

export const BookingSummaryItem = ({ label, value, children }: BookingSummaryItemProps) => {
    return (
        <SpaceBetween>
            <Text flex="0 0 40%" color={ThemeColorVariable.OnSurfaceSubdued}>
                {label}
            </Text>
            {value && (
                <Text textAlign="end" color={ThemeColorVariable.OnSurfaceSubdued}>
                    {value}
                </Text>
            )}
            {children}
        </SpaceBetween>
    );
};
