import { ThemeColorVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import {
    FormControl,
    FormErrorMessage,
    FormLabel,
} from "@caspeco/casper-ui-library.components.formcontrol";
import { Input } from "@caspeco/casper-ui-library.components.input";
import { validateName } from "helpers/validation-helper";
import { ChangeEvent } from "react";
import { Control, UseFormTrigger, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useBookingActionsContext } from "hooks/use-booking-state";
import { ContactInfoForm } from "store/store-types";

interface INameFieldProps {
    value: string;
    control: Control<ContactInfoForm, string>;
    disabled?: boolean;
    variant: "fname" | "lname";
    trigger: UseFormTrigger<ContactInfoForm>;
}

const NameField = ({ variant, control, disabled, value, trigger }: INameFieldProps) => {
    const { t } = useTranslation();
    const { setContact } = useBookingActionsContext();
    const {
        field,
        fieldState: { error },
    } = useController({
        control,
        name: variant,
        rules: {
            required: t(`contact.validation.${variant}`),
            validate: {
                validName: (n) => validateName(n, variant),
            },
        },
    });

    const handleContactChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        field.onChange(e.target.value);
        if (error) {
            void trigger(field.name);
        }
        setContact({ [variant]: value });
    };

    const autoComplete = variant === "fname" ? "given-name" : "family-name";
    return (
        <FormControl isInvalid={!!error}>
            <FormLabel>
                {t(`contact.${variant}`)}{" "}
                <Box as="span" color={ThemeColorVariable.Error}>
                    *
                </Box>
            </FormLabel>
            <Input
                size="lg"
                id={variant}
                name={variant}
                autoComplete={autoComplete}
                onChange={handleContactChange}
                value={value}
                isDisabled={disabled}
                onBlur={field.onBlur}
                ref={field.ref}
            />
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};

export default NameField;
