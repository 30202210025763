import { Badge } from "@caspeco/casper-ui-library.components.badge";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import {
    getFormattedActivityLength,
    getFormattedActivityPrice,
    getFormattedGroupSizeRange,
    getSectionBookingDurations,
} from "components/selection-components/select-activity/activity-badges/activity-badges-helper";
import { useTranslation } from "react-i18next";
import { AvailableTimesSection } from "types/available-times";

interface IActivityBadges {
    section: AvailableTimesSection;
}

const ActivityBadges = ({ section }: IActivityBadges) => {
    const { t } = useTranslation();
    const sittingLengths = getSectionBookingDurations(section);
    const formattedSittingLengths = getFormattedActivityLength(sittingLengths, t);
    const formattedActivityPrice = getFormattedActivityPrice(section);
    const formattedGroupSizeRange = getFormattedGroupSizeRange(section, t);

    if (!formattedSittingLengths && !formattedActivityPrice && !formattedGroupSizeRange)
        return null;

    return (
        <Stack direction="row">
            {sittingLengths.length > 0 && <Badge.Primary>{formattedSittingLengths}</Badge.Primary>}
            {formattedActivityPrice && <Badge.Primary>{formattedActivityPrice}</Badge.Primary>}
            {formattedGroupSizeRange && <Badge.Primary>{formattedGroupSizeRange}</Badge.Primary>}
        </Stack>
    );
};

export default ActivityBadges;
