import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useWebBooking } from "api/api-hooks/use-web-booking";
import { post } from "api/api-methods";
import { useNavigate } from "react-router-dom";
import { WaitListConfirmParameters } from "types/request-parameters";
import { WaitListOfferStatus } from "types/wait-list-offer";
import { WebBooking } from "types/web-booking";

export const useConfirmWaitListBooking = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { data: booking } = useWebBooking();
    const { guid: id } = booking;

    const postConfirmBooking = () =>
        post<WebBooking, WaitListConfirmParameters>(
            `WebBooking/WebBookings/${id}/AcceptWaitListOffer`,
            new WaitListConfirmParameters(booking.waitListOffers)
        );

    return useMutation({
        mutationFn: postConfirmBooking,
        onSuccess: (data) => {
            queryClient.setQueryData<WebBooking>(["booking", id], () => {
                return new WebBooking(data);
            });

            navigate(location.pathname, {
                replace: true,
                state: { isFinal: true },
            });
        },
        onError: (error) => {
            console.error("Error confirming waitlist booking", error);
            queryClient.setQueryData<WebBooking>(["booking", id], (currentData) => {
                currentData.waitListOffers.forEach((offer) => {
                    offer.offerStatus = WaitListOfferStatus.Unavailable;
                });
                return new WebBooking(currentData);
            });
        },
    });
};
