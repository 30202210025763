import moment from "moment";
import { UrlParams } from "search-params";
import { State, Time } from "store/store-types";

const parseTimes = (params: UrlParams): Time[] | null => {
    // If no date is selected, times should be null
    if (!params.date) {
        return null;
    }

    return params.end
        ? [
              {
                  ruleId: params.ruleId,
                  start: moment(params.start, "HHmm").set({
                      date: params.date.date(),
                      month: params.date.month(),
                      year: params.date.year(),
                  }),
                  end: moment(params.end, "HHmm").set({
                      date: params.date.date(),
                      month: params.date.month(),
                      year: params.date.year(),
                  }),
              },
          ]
        : params.start
          ? params.start.split("-").map((t) => ({
                ruleId: params.ruleId,
                start: moment(t, "HHmm").set({
                    date: params.date?.date(),
                    month: params.date?.month(),
                    year: params.date?.year(),
                }),
            }))
          : null;
};

export const getStateFromParams = (params: UrlParams, previousState: State): State => {
    // Create a new state object to avoid direct mutation
    const newState: State = { ...previousState };

    // Handle guests - explicitly set to undefined if param is missing
    newState.guests = params.guests !== undefined ? params.guests : undefined;

    // Handle children - explicitly set to undefined if param is missing
    newState.guestsChildren = params.guestsChildren !== undefined ? params.guestsChildren : null;

    // Handle date - explicitly set to undefined if param is missing
    newState.date = params.date || undefined;

    newState.times = parseTimes(params);

    // Handle timesConfirmed - explicitly set to false if param is missing
    newState.isTimesConfirmed = params.isTimesConfirmed || false;

    return newState;
};
