import { ThemeColorVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import {
    FormControl,
    FormErrorMessage,
    FormLabel,
} from "@caspeco/casper-ui-library.components.formcontrol";
import { Input } from "@caspeco/casper-ui-library.components.input";
import { validateEmail } from "helpers/validation-helper";
import { ChangeEvent } from "react";
import { Control, UseFormTrigger, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ContactInfoForm } from "store/store-types";
import { useBookingActionsContext } from "hooks/use-booking-state";

interface IEmailField {
    control: Control<ContactInfoForm, string>;
    value: string;
    disabled?: boolean;
    trigger: UseFormTrigger<ContactInfoForm>;
}

const EmailField = ({ control, value, trigger }: IEmailField) => {
    const { t } = useTranslation();
    const { setContact } = useBookingActionsContext();
    const {
        field,
        fieldState: { error },
    } = useController({
        control,
        name: "email",
        rules: {
            required: t("contact.validation.email"),
            validate: (value) => validateEmail(value),
        },
    });

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        field.onChange(e.target.value);
        if (error) {
            void trigger(field.name);
        }
        setContact({ email: value });
    };
    return (
        <FormControl isInvalid={Boolean(error)}>
            <FormLabel>
                {t("contact.email")}{" "}
                <Box as="span" color={ThemeColorVariable.Error}>
                    *
                </Box>
            </FormLabel>
            <Input
                type="email"
                size="lg"
                autoComplete="email"
                name={field.name}
                value={value}
                onChange={handleEmailChange}
                onBlur={field.onBlur}
                ref={field.ref}
            />
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};

export default EmailField;
