import { List, ListItem } from "@caspeco/casper-ui-library.components.list";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { useAvailableTimes, useFilteredAvailableTimes } from "api/api-hooks/use-available-times";
import { useReservation } from "api/api-hooks/use-create-reservation";
import SomethingWrong from "components/error-components/something-wrong";
import ActivityListSkeleton from "components/selection-components/select-activity/activity-list-skeleton";
import CustomizeActivity from "components/selection-components/select-activity/customize-activity";
import ActivityFooter from "components/selection-components/select-activity/select-activity-footer";
import { useOriginalSelectedTime } from "components/selection-components/select-activity/use-original-selected-time";
import SelectionHeader from "components/selection-components/selection-header";
import SummaryAside from "components/selection-components/summary/summary-aside";
import SummaryModal from "components/selection-components/summary/summary-modal";
import { useActivityContext } from "context/use-activity-context";
import { useBookingActionsContext, useBookingStateContext } from "hooks/use-booking-state";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setSearch } from "search-params/parse";
import { AvailableTimesSection } from "types/available-times";
import { TimeSlot } from "types/time-slot";
import ActivityItem from "./activity-item";
import EmptyState from "components/empty-state";
import { Icons } from "@caspeco/casper-ui-library.components.icon";

const SelectActivity = () => {
    const { t } = useTranslation();
    const [summaryOpen, setSummaryOpen] = useState(false);
    const { setOpenActivity, openActivity } = useActivityContext();
    const state = useBookingStateContext();
    const { date } = state;
    const availableTimes = useAvailableTimes(date);
    const filteredAvailableTimes = useFilteredAvailableTimes(date);
    const { data: reservation } = useReservation();
    const { setState } = useBookingActionsContext();
    const navigate = useNavigate();
    const [selectedTime, setSelectedTime] = useState<TimeSlot>();
    const previousSelectedTime = useOriginalSelectedTime();

    const gotoDetailsForm = () => {
        const reservationTime = {
            start: reservation?.start,
            end: reservation?.end,
            ruleId: -1,
        };
        const stateWithTimes = {
            ...state,
            ...{ times: [...(state.times ?? []), ...[reservationTime]] },
        };

        setSearch(navigate, {
            timesConfirmed: true,
        });
        setState(stateWithTimes);
        setOpenActivity(null);
    };

    const handleSelectTime = (time: TimeSlot) => {
        if (selectedTime && time?.start.isSame(selectedTime?.start)) {
            setSelectedTime(null);
        } else {
            setSelectedTime(time);
        }
    };

    useEffect(() => {
        if (reservation && previousSelectedTime) {
            setSelectedTime(previousSelectedTime);
        }
    }, [previousSelectedTime, reservation, openActivity]);

    const handleOpenActivity = (sectionOrRuleId: AvailableTimesSection | number) => {
        let section: AvailableTimesSection | undefined;

        if (typeof sectionOrRuleId === "number") {
            section = filteredAvailableTimes.find((timeSection) =>
                timeSection.timeSets.some((timeSet) => timeSet.id === sectionOrRuleId)
            );

            if (!section) {
                console.error("Could not find activity to open", sectionOrRuleId);
                return;
            }
        } else {
            section = sectionOrRuleId;
        }

        setOpenActivity(section);
    };

    if (availableTimes.isLoading) {
        return <ActivityListSkeleton />;
    }
    if (availableTimes.isError) {
        return <SomethingWrong />;
    }
    const sectionsToRender = filteredAvailableTimes.filter((section) => {
        const webRulesIds = section.timeSets.map((timeSet) => timeSet.id);
        const bookedWebRuleIds = reservation?.articles.map((article) => article.ruleId);
        return !bookedWebRuleIds?.some((bookedId) => webRulesIds.includes(bookedId));
    });
    const sectionList =
        sectionsToRender.length === 0 ? (
            <EmptyState
                icon={Icons.Smile} // Todo: change to PartyHorn when available
                description={t("selectActivity.noActivityDescription")}
                header={t("selectActivity.noActivityHeader")}
            />
        ) : (
            <>
                {reservation?.articles.length > 0 && (
                    <SelectionHeader
                        id="timeChoiceHeader"
                        key="timeChoiseHeader"
                        text={t("selectActivity.wantToAddMore")}
                    />
                )}
                {sectionsToRender.length && (
                    <List>
                        {sectionsToRender.map((section) => (
                            <ListItem key={section.id}>
                                <ActivityItem
                                    key={section.id}
                                    section={section}
                                    onClick={() => handleOpenActivity(section)}
                                    disabled={section.disabled}
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </>
        );

    return (
        <Stack flexDirection="column">
            {sectionList}
            <SummaryModal
                openActivity={handleOpenActivity}
                isOpen={summaryOpen}
                onClose={() => setSummaryOpen(false)}
                gotoDetailsForm={gotoDetailsForm}
            />
            {reservation?.articles?.length && (
                <SummaryAside
                    handleOpenActivity={handleOpenActivity}
                    gotoDetailsForm={gotoDetailsForm}
                />
            )}
            <CustomizeActivity selectedTime={selectedTime} setSelectedTime={handleSelectTime} />
            <ActivityFooter setSummaryOpen={setSummaryOpen} gotoDetailsForm={gotoDetailsForm} />
        </Stack>
    );
};

export default SelectActivity;
