import { Icons } from "@caspeco/casper-ui-library.components.icon";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ErrorTemplate from "./error-template";

export default function SomethingWrong() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { systemId, unitId } = useParams(); // Capture systemId and unitId from the URL

    if (systemId && unitId) {
        localStorage.setItem("lastKnownSystemId", systemId);
        localStorage.setItem("lastKnownUnitId", unitId);
    }

    const storedSystemId = localStorage.getItem("lastKnownSystemId");
    const storedUnitId = localStorage.getItem("lastKnownUnitId");
    const validStoredValues = storedSystemId && storedUnitId;

    const handleRefresh = () => {
        if (validStoredValues) {
            navigate(`/${storedSystemId}/${storedUnitId}`);
            location.reload();
        }
    };

    return (
        <ErrorTemplate
            icon={Icons.SlightlySad}
            title={t("errorInformation.somethingWrong")}
            message={t("errorInformation.errorMessageWrong")}
            btnCallback={validStoredValues ? handleRefresh : undefined}
            btnText={t("errorInformation.tryAgain")}
        />
    );
}
