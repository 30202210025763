import { useMutation } from "@tanstack/react-query";
import { post } from "api/api-methods";
import i18n from "i18next";
import { useError } from "hooks/use-error";
import { getLocaleFromLang } from "helpers/language-helper";
import { trackEvent } from "logging/insights";
import queryClient from "query-client";
import { useNavigate, useParams } from "react-router-dom";
import { GUID_STORAGE_KEY } from "store";
import { State } from "store/store-types";
import { CreateReservationParameters } from "types/request-parameters";
import { WebBooking } from "types/web-booking";
import { sendTrackingEventToParent, TrackingEventType } from "utils/event-utils";
import { UrlParamName } from "search-params";
import { useReservation } from "api/api-hooks/use-create-reservation";

// Parameters to exclude when preserving URL parameters after booking finalization
const PARAMS_TO_EXCLUDE = [
    UrlParamName.RULE_ID,
    UrlParamName.START,
    UrlParamName.END,
    UrlParamName.DATE,
    UrlParamName.GUESTS,
    UrlParamName.GUESTS_CHILDREN,
    UrlParamName.IS_TIMES_CONFIRMED,
];

export const useFinalizeBooking = () => {
    const { system } = useParams();
    const navigate = useNavigate();
    const { openReservationFailedModal } = useError();
    const { data: reservation } = useReservation();

    const finalizeBooking = async (state: State) => {
        const guid = localStorage.getItem(GUID_STORAGE_KEY);
        if (!guid) return;
        const data: CreateReservationParameters = {
            ...state,
            lang: getLocaleFromLang(i18n.language),
            logMessage: "Bokning skapad",
            message: state.contact?.message,
            times: reservation.getBookTimes().map((time) => {
                return {
                    ruleId: time.ruleId,
                    // This was an error, we were passing Moments instead of strings
                    start: time.start.toISOString(),
                };
            }),
        };

        let eventName = "BookingFinalized";
        let booking: WebBooking;

        const startTime = new Date().getTime();
        try {
            booking = await post<WebBooking, CreateReservationParameters>(
                `WebBooking/WebBookings/${guid}/Finalize`,
                data
            );
            queryClient.setQueryData<WebBooking>(["booking", guid], new WebBooking(booking));
            localStorage.removeItem(GUID_STORAGE_KEY);

            const currentParams = new URLSearchParams(window.location.search);
            const searchParamsToKeep = Array.from(currentParams.entries())
                .filter(([key]) => !PARAMS_TO_EXCLUDE.includes(key as UrlParamName))
                .reduce((params, [key, value]) => {
                    params.set(key, value);
                    return params;
                }, new URLSearchParams())
                .toString();

            const searchParamsString = searchParamsToKeep ? `?${searchParamsToKeep}` : "";

            navigate(`/booking/${system}/${guid}${searchParamsString}`, {
                state: { isFinal: true },
            });

            // TODO: revisit the scroll event implementation. (logic brought from previous widget 3.0)
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            window.parent.postMessage("scroll2top", "*");
        } catch (error) {
            console.error(error);
            eventName = "BookingFinalizeFailed";
            openReservationFailedModal();
        }

        const endTime = new Date().getTime();
        const loadTime = endTime - startTime;
        trackEvent(eventName, {
            guests: state.guests,
            children: state.guestsChildren,
            date: state.times[0].start.toISOString(),
            isWaitingList: state.times.length > 1,
            ms: loadTime,
        });
        sendTrackingEventToParent(
            state,
            eventName === "BookingFinalized"
                ? TrackingEventType.bookingFinalized
                : TrackingEventType.bookingFinalizedFailed
        );
        return booking;
    };

    return useMutation({
        mutationFn: finalizeBooking,
        scope: {
            id: "webbooking-create",
        },
    });
};
