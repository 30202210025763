import {
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useTranslation } from "react-i18next";

interface MessageProps {
    message: string;
}

export default function BookingMessage({ message }: MessageProps) {
    const { t } = useTranslation();
    return (
        <Flex direction="column" gap={ThemeSpaceVariable.Small}>
            <Heading as={"h3"} fontSize={ThemeFontSizeVariable.Medium} fontWeight="medium">
                {t("contact.message")}
            </Heading>
            <Text whiteSpace="pre-line" color={ThemeColorVariable.OnSurfaceSubdued}>
                {message}
            </Text>
        </Flex>
    );
}
