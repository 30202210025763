import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IShowMoreText {
    text: string;
    initialLines?: number;
    showMoreText?: string;
    showLessText?: string;
    textProps?: React.ComponentProps<typeof Text>;
    linkProps?: React.ComponentProps<typeof Text>;
}

const ShowMoreText = ({
    text,
    initialLines = 3,
    showMoreText,
    showLessText,
    textProps = {},
    linkProps = {},
}: IShowMoreText) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
    const [wasEverTruncated, setWasEverTruncated] = useState(false);
    const { t } = useTranslation();
    // Create a unique ID for the text element to avoid conflicts with other elements on the page
    const textId = `show-more-text-${Math.random().toString(36).substring(2, 9)}`;

    useEffect(() => {
        const timer = setTimeout(() => {
            const element = document.getElementById(textId);
            if (!element) return;

            const checkTruncation = () => {
                if (isExpanded) return;

                const tempEl = element.cloneNode(true) as HTMLElement;
                tempEl.style.webkitLineClamp = "unset";
                tempEl.style.position = "absolute";
                tempEl.style.visibility = "hidden";
                document.body.appendChild(tempEl);

                const isTruncated = tempEl.clientHeight > element.clientHeight;

                document.body.removeChild(tempEl);
                setIsTruncated(isTruncated);

                if (isTruncated) {
                    setWasEverTruncated(true);
                }
            };

            checkTruncation();

            const resizeObserver = new ResizeObserver(() => {
                checkTruncation();
            });

            resizeObserver.observe(element);

            return () => {
                resizeObserver.disconnect();
            };
        }, 100);

        return () => clearTimeout(timer);
    }, [text, initialLines, textId, isExpanded]);

    if (!text) return null;

    const defaultShowMoreText = showMoreText || t("showMoreText.showMore");
    const defaultShowLessText = showLessText || t("showMoreText.showLess");

    const showButton = isTruncated || (wasEverTruncated && isExpanded);

    return (
        <Stack spacing={ThemeSpaceVariable.XSmall}>
            <Text id={textId} noOfLines={isExpanded ? undefined : initialLines} {...textProps}>
                {text}
            </Text>

            {showButton && (
                <div onClick={() => setIsExpanded(!isExpanded)} aria-hidden="true">
                    <Text
                        fontWeight="400"
                        textDecoration="underline"
                        cursor="pointer"
                        {...linkProps}
                    >
                        {isExpanded ? defaultShowLessText : defaultShowMoreText}
                    </Text>
                </div>
            )}
        </Stack>
    );
};

export default ShowMoreText;
