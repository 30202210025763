import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { useDisclosure } from "@caspeco/casper-ui-library.components.drawer";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";
import { usePaymentTerminal } from "api/api-hooks/use-payment-terminal";
import BookingConditions from "components/details-form/booking-conditions";
import { PhoneField } from "components/details-form/phone-field";
import { PaymentMethodField } from "components/payment-field/payment-field";
import { PrivacyPolicyModal } from "components/privacy-policy-modal";
import { hasFlag } from "helpers/binary-flag-helper";
import { getDefaultPaymentMethod } from "helpers/payment-helper";
import { useBookingActionsContext, useBookingStateContext } from "hooks/use-booking-state";
import { usePaymentRule } from "hooks/use-payment-rule";
import { Articles } from "pages/booking-details/articles";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getSearchParams } from "search-params";
import { ContactInfoForm } from "store/store-types";
import { Contact } from "types/contact";
import { BookingWebSettingsFlag } from "types/external-booking-settings";
import { PaymentRule } from "types/payment";
import WaitListNoShowAlert from "../wait-list/wait-list-no-show-alert";
import WaitListPrepaymentAlert from "../wait-list/wait-list-prepayment-alert";
import CustomerNameField from "./customer-name-field";
import EmailField from "./email-field";
import MessageField from "./message-field";
import NameField from "./name-field";
import NewsletterField from "./newsletter-field";
import NoShowAlert from "./no-show-alert";
import PrepaymentAlert from "./prepayment-alert";
import PrivacyPolicyText from "./privacy-policy-text";
import SubmitButton from "./submit-button";

export const DetailsWithPaymentForm = () => {
    const { t } = useTranslation();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { data: settings } = useExternalBookingSettings();
    const state = useBookingStateContext();
    const { handleFinalize, finalize } = useBookingActionsContext();
    const terminal = usePaymentTerminal();
    const paymentRule = usePaymentRule();

    const contact = (state.contact || {}) as Contact;

    const isPrepayment = paymentRule === PaymentRule.Prepayment;
    const isNoShow = paymentRule === PaymentRule.NoShowFee;
    const { control, handleSubmit, formState, trigger, watch } = useForm<ContactInfoForm>({
        mode: "onBlur",
        defaultValues: {
            ...contact,
            payMethod: getDefaultPaymentMethod(settings, isPrepayment, state),
        },
    });

    const displayMessageBox = settings && settings.displayMessageBox;
    const unitMetaData = settings.unitsMetaData;
    const showCustomerNameField = hasFlag(
        unitMetaData.binaryFlags,
        BookingWebSettingsFlag.displayCustomerName
    );

    const onSubmit = () => {
        if (state.isWaitList || (!isPrepayment && !isNoShow)) {
            handleFinalize();
        } else {
            const terminalState = {
                ...state,
                payment: {
                    payMethod: watch("payMethod"),
                },
            };
            terminal.mutate({ state: terminalState, isNoShow });
        }
    };
    const { isActivityBooking } = getSearchParams();
    return (
        <form
            onSubmit={(e) => {
                void handleSubmit(onSubmit)(e);
            }}
            noValidate
        >
            <Flex direction="column" gap={ThemeSpaceVariable.Large}>
                <Flex direction="column" gap={ThemeSpaceVariable.Medium}>
                    {isActivityBooking && <Articles />}
                    <BookingConditions />
                    <Heading as="h5" fontWeight="500">
                        {t("contactDetails")}
                    </Heading>
                    <NameField
                        value={contact.fname || ""}
                        control={control}
                        variant="fname"
                        // disabled={!!contactLoadedByFriendlyId?.fname}
                        trigger={trigger}
                    />
                    <NameField
                        value={contact.lname || ""}
                        control={control}
                        variant="lname"
                        // disabled={!!contactLoadedByFriendlyId?.lname}
                        trigger={trigger}
                    />
                    <PhoneField
                        value={contact.mobile || ""}
                        control={control}
                        // disabled={!!contactLoadedByFriendlyId?.mobile}
                        trigger={trigger}
                    />
                    <EmailField
                        value={contact.email || ""}
                        control={control}
                        // disabled={!!contactLoadedByFriendlyId?.email}
                        trigger={trigger}
                    />

                    {showCustomerNameField && <CustomerNameField control={control} />}

                    {displayMessageBox && (
                        <MessageField control={control} value={contact.message || ""} />
                    )}

                    {settings.newsletter && <NewsletterField />}
                </Flex>

                {!isActivityBooking &&
                    isNoShow &&
                    (state.isWaitList ? <WaitListNoShowAlert /> : <NoShowAlert />)}
                {!isActivityBooking &&
                    isPrepayment &&
                    (state.isWaitList ? <WaitListPrepaymentAlert /> : <PrepaymentAlert />)}

                {isPrepayment && !state.isWaitList && <PaymentMethodField control={control} />}

                <Flex direction="column" gap={ThemeSpaceVariable.Medium}>
                    <SubmitButton
                        isLoading={finalize.isPending || terminal?.isPending}
                        isDisabled={!formState.isValid}
                    />
                    <PrivacyPolicyText onOpen={onOpen} />
                </Flex>
            </Flex>
            <PrivacyPolicyModal isOpen={isOpen} onClose={onClose} />
        </form>
    );
};
