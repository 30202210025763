import SelectActivity from "components/selection-components/select-activity/select-activity";
import { SelectTime } from "components/selection-components/select-time/select-time";
import { getSearchParams } from "search-params";
import { ActivityProvider } from "context/activity-provider";

const AvailabilityView = () => {
    const { isActivityBooking } = getSearchParams();

    return (
        <ActivityProvider>
            {isActivityBooking ? <SelectActivity /> : <SelectTime />}
        </ActivityProvider>
    );
};

export default AvailabilityView;
