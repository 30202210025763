import { ThemeColorVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { getPhoneNumberCountryCode, validatePhoneNumber } from "helpers/validation-helper";
import { CountryCode } from "libphonenumber-js/types";
import { forwardRef } from "react";
import { Control, UseFormTrigger, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import {
    FormControl,
    FormErrorMessage,
    FormLabel,
} from "@caspeco/casper-ui-library.components.formcontrol";
import { Input, InputProps } from "@caspeco/casper-ui-library.components.input";
import "react-phone-number-input/style.css";
import "./phone-field.less";
import { useBookingActionsContext } from "hooks/use-booking-state";
import { ContactInfoForm } from "store/store-types";

interface ICustomPhoneTextFieldProps {
    value: string;
    disabled?: boolean;
    control: Control<ContactInfoForm, string>;
    trigger: UseFormTrigger<ContactInfoForm>;
}

const phoneInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    return <Input size="lg" name="tel" autoComplete="tel" ref={ref} {...props} />;
});

phoneInput.displayName = "PhoneInputElement";

export const PhoneField = ({ value, control, disabled, trigger }: ICustomPhoneTextFieldProps) => {
    const { t } = useTranslation();
    const { setContact } = useBookingActionsContext();

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const countryCode = document.querySelector(
        'select[name="phoneNumberCountry"]'
    ) as HTMLSelectElement;
    const countryCodeStrValue = countryCode?.value as CountryCode | undefined;

    const {
        field,
        fieldState: { error },
    } = useController({
        control,
        name: "mobile",
        rules: {
            required: t("contact.validation.mobile"),
            validate: {
                validPhoneNumber: (v) => validatePhoneNumber(v, countryCodeStrValue) as string,
            },
        },
    });

    const handlePhoneChange = (value: string | undefined) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const countrySelect = document.querySelector(
            'select[name="phoneNumberCountry"]'
        ) as HTMLSelectElement;
        const countryCode = (countrySelect?.value as CountryCode) ?? undefined;

        field.onChange(value);
        if (error) {
            void trigger(field.name);
        }
        setContact({ mobile: value, mobileCountryCode: countryCode });
    };

    return (
        <FormControl isInvalid={!!error}>
            <FormLabel>
                {t("contact.mobile")}{" "}
                <Box as="span" color={ThemeColorVariable.Error}>
                    *
                </Box>
            </FormLabel>
            <PhoneInput
                className="StyledPhoneInput"
                name="phoneNumber"
                autoComplete="tel"
                aria-invalid={Boolean(error)}
                onChange={handlePhoneChange}
                value={value}
                inputComponent={phoneInput}
                defaultCountry={getPhoneNumberCountryCode(field.value)}
                disabled={disabled}
                onBlur={field.onBlur}
                ref={field.ref}
            />
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};
