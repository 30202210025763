import {
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeIconSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Icon, Icons } from "@caspeco/casper-ui-library.components.icon";
import { Text } from "@caspeco/casper-ui-library.components.text";

interface IEmptyState {
    icon?: Icons;
    header: string;
    description: string;
}

/**
 * Presents an icon and text that indicates that there is no data to show.
 */
const EmptyState = ({ icon = Icons.CircleInfo, header, description }: IEmptyState) => {
    return (
        <Box
            justifyContent={"center"}
            alignItems="center"
            textAlign="center"
            p={ThemeSpaceVariable.Medium}
            pt={ThemeSpaceVariable.XLarge}
        >
            <Icon
                icon={icon}
                size={ThemeIconSizeVariable.X3Large}
                color={ThemeColorVariable.OnBackgroundBorder}
                mb={ThemeSpaceVariable.Medium}
            />
            <Heading as="h3" fontWeight="500" fontSize={ThemeFontSizeVariable.Large}>
                {header}
            </Heading>
            <Text>{description}</Text>
        </Box>
    );
};

export default EmptyState;
