import {
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from "@caspeco/casper-ui-library.components.tabs";
import { List } from "@caspeco/casper-ui-library.components.list";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Divider } from "@caspeco/casper-ui-library.components.divider";
import { BookingMenu, MenuStatus } from "types/menu";
import testId from "types/test-id";
import { useContext, useEffect, useState } from "react";
import PreorderMenuGroup from "components/selection-components/preorder/preorder-menu-group";
import { useTranslation } from "react-i18next";
import { useReservation } from "api/api-hooks/use-create-reservation";
import { ActivityContext } from "context/activity-context";
import { TimeSlot } from "types/time-slot";

interface IPreorderBody {
    selectedTime: TimeSlot;
}

const PreorderBody = ({ selectedTime }: IPreorderBody) => {
    const { t } = useTranslation();

    const { data: reservation } = useReservation();
    const { openActivity, currentQuantities, setCurrentQuantities } = useContext(ActivityContext);

    const [bookingMenu, setBookingMenu] = useState<BookingMenu | null>(null);

    useEffect(() => {
        if (!bookingMenu) {
            // We have to use the current timeslot start time and match it to the activity to open the correct menu
            const currentActivity = reservation?.articles.find((activity) =>
                activity.start.isSame(selectedTime?.start)
            );
            const bookingMenu = reservation?.menus?.find(
                (menu) =>
                    menu.status === MenuStatus.Sent && menu.publicId === currentActivity?.menuGuid
            );

            const initialQuantities =
                bookingMenu?.groups?.map((group) => group.items.map((item) => item.quantity)) || [];
            setCurrentQuantities(initialQuantities);
            setBookingMenu(bookingMenu);
        }
    }, [reservation, setCurrentQuantities, selectedTime?.start, bookingMenu]);

    const headerPreorderText = t("preorder.headerText");

    const headerText = `${headerPreorderText} ${openActivity?.name}`;

    if (!bookingMenu || !bookingMenu.groups) {
        return null;
    }

    return (
        <Tabs data-testid={testId.preorder.wrapper}>
            <Heading
                as="h2"
                p={`0 ${ThemeSpaceVariable.Medium} ${ThemeSpaceVariable.Medium} ${ThemeSpaceVariable.Medium}`}
            >
                {headerText}
            </Heading>

            <TabList
                margin={`0 0 ${ThemeSpaceVariable.Small} ${ThemeSpaceVariable.Medium}`}
                overflowY="auto"
                gap={ThemeSpaceVariable.Small}
            >
                {bookingMenu.groups.map((menuGroup, idx) => (
                    <Tab key={`${idx}-${menuGroup.name}`}>{menuGroup.name}</Tab>
                ))}
            </TabList>
            <Divider marginBottom={ThemeSpaceVariable.Medium} />
            <TabPanels marginTop={ThemeSpaceVariable.Large}>
                {bookingMenu.groups.map((menuGroup, groupIdx) => {
                    return (
                        <TabPanel key={`tabpanel-${groupIdx}`}>
                            <List
                                gap={ThemeSpaceVariable.Medium}
                                p={`0 ${ThemeSpaceVariable.Medium} 0 ${ThemeSpaceVariable.Medium}`}
                            >
                                <PreorderMenuGroup
                                    menuGroup={menuGroup}
                                    currentGroupQuantities={currentQuantities[groupIdx]}
                                    setGroupQuantities={(groupQuantities: number[]) =>
                                        setCurrentQuantities(
                                            currentQuantities.toSpliced(
                                                groupIdx,
                                                1,
                                                groupQuantities
                                            )
                                        )
                                    }
                                />
                            </List>
                        </TabPanel>
                    );
                })}
            </TabPanels>
        </Tabs>
    );
};

export default PreorderBody;
