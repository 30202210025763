import { Alert } from "@caspeco/casper-ui-library.components.alert";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IContinueButton {
    buttonText: string;
    onContinue: () => Promise<void>;
    isDisabled?: boolean;
}

/**
 * The button that continues the user flow from preorder to the next step
 * Shared by both the modal and panel version of the preorder component
 */
const ContinueButton = ({ buttonText, onContinue, isDisabled = false }: IContinueButton) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<unknown>(null);
    const errorText = t("errorInformation.somethingWrong"); // Todo
    const handleOnClick = async () => {
        setLoading(true);
        try {
            await onContinue();
        } catch (error: unknown) {
            console.error("Error", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {error && <Alert type="error">{errorText}</Alert>}
            <Button
                variant="primary"
                size={["lg"]}
                alignSelf="flex-end"
                w="full"
                isLoading={loading}
                isDisabled={isDisabled}
                onClick={() => {
                    void handleOnClick();
                }}
            >
                {buttonText}
            </Button>
        </>
    );
};

export default ContinueButton;
