import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Icon, Icons } from "@caspeco/casper-ui-library.components.icon";
import { Image } from "@caspeco/casper-ui-library.components.image";
import {
    ModalBody,
    ModalCloseButton,
    ModalHeader,
} from "@caspeco/casper-ui-library.components.modal";
// eslint-disable-next-line caspeco/discourage-chakra-import
import { AspectRatio } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ICustomizeWizardModalBody {
    onBack?: () => void;
    children: React.ReactNode;
    headerImage?: string;
    headerTitle?: string;
}

/**
 * wraps the wizard body in the modal wrappers and adjust the header to fit the design requirements
 */
const CustomizeWizardModalBody = ({
    children,
    onBack,
    headerImage,
    headerTitle,
}: ICustomizeWizardModalBody) => {
    const { t } = useTranslation();
    const [showImage, setShowImage] = useState(true);
    const backButtonText = t("customizeWizard.backButtonText");

    return (
        <ModalBody display="flex" flexDirection="column">
            {headerImage && showImage ? (
                <>
                    <AspectRatio maxW="100%" ratio={16 / 9}>
                        <Image src={headerImage} onError={() => setShowImage(false)} />
                    </AspectRatio>
                    <ModalCloseButton isAbsolutePositioned />
                </>
            ) : (
                <ModalHeader
                    display="flex"
                    justifyContent="space-between"
                    p={ThemeSpaceVariable.Medium}
                    flexDirection={"row-reverse"}
                >
                    <ModalCloseButton />
                    {headerTitle && <Heading as="h2">{headerTitle}</Heading>}
                    {onBack && (
                        <Button variant="ghost" name={backButtonText} onClick={onBack}>
                            <Icon icon={Icons.Back} />
                            {backButtonText}
                        </Button>
                    )}
                </ModalHeader>
            )}
            {children}
        </ModalBody>
    );
};

export default CustomizeWizardModalBody;
