import {
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Text } from "@caspeco/casper-ui-library.components.text";
import React from "react";
import { Trans } from "react-i18next";

interface IPrivacyPolicyTextProps {
    onOpen: () => void;
}

const PrivacyPolicyText = ({ onOpen }: IPrivacyPolicyTextProps) => {
    const handlePolicyClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onOpen();
    };

    return (
        <Text
            mb={ThemeSpaceVariable.Medium}
            color={ThemeColorVariable.OnSurfaceSubdued}
            fontSize={ThemeFontSizeVariable.Small}
        >
            <Trans
                i18nKey="privacyPolicyLink"
                components={{
                    1: (
                        <Box
                            as="button"
                            color={ThemeColorVariable.OnSurfaceSubdued}
                            textDecoration="underline"
                            onClick={handlePolicyClicked}
                        />
                    ),
                }}
            />
        </Text>
    );
};

export default PrivacyPolicyText;
