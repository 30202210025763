import {
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeFontVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Alert } from "@caspeco/casper-ui-library.components.alert";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Divider } from "@caspeco/casper-ui-library.components.divider";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Image } from "@caspeco/casper-ui-library.components.image";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import ActivityBadges from "components/selection-components/select-activity/activity-badges/activity-badges";
import { useBookingStateContext } from "hooks/use-booking-state";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AvailableTimesSection } from "types/available-times";

interface IActivityItem {
    section: AvailableTimesSection;
    onClick: () => void;
    disabled: boolean;
}

const ActivityItem = ({ section, onClick, disabled }: IActivityItem) => {
    const { t } = useTranslation();
    const [showImage, setShowImage] = useState(true);
    const { guests } = useBookingStateContext();
    const [isSelected, setIsSelected] = useState(false);

    return (
        <Box
            onClick={!disabled ? onClick : undefined}
            cursor={!disabled ? "pointer" : "default"}
            role="button"
            tabIndex={0}
            onMouseOver={() => setIsSelected(true)}
            onPointerLeave={() => setIsSelected(false)}
            onFocus={() => setIsSelected(true)}
            onBlur={() => setIsSelected(false)}
            // This results in something similar to this:
            // 0 0 0 3px ${ThemeColorVariable.PickerSky}
            // However the actual variable is not exposed by casper. Only way to ensure theme is consistent is to use the css variable directly.
            boxShadow={isSelected ? `var(--theme-shadows-outline)` : "none"}
        >
            <Flex
                justify="space-between"
                py={ThemeSpaceVariable.Medium}
                align="center"
                gap={ThemeSpaceVariable.Small}
                opacity={disabled ? 0.5 : 1}
                cursor={!disabled ? "pointer" : "default"}
            >
                <Stack spacing={ThemeSpaceVariable.None}>
                    <Heading
                        as="h3"
                        noOfLines={1}
                        fontFamily={ThemeFontVariable.SubHeader}
                        fontWeight="medium"
                        color={ThemeColorVariable.OnBackgroundHeader}
                        fontSize={{
                            base: ThemeFontSizeVariable.Large,
                            md: ThemeFontSizeVariable.XLarge,
                        }}
                    >
                        {section.name}
                    </Heading>
                    <Text
                        height={{
                            base: ThemeFontSizeVariable.XLarge,
                            md: "unset",
                        }}
                        noOfLines={1}
                        whiteSpace="break-spaces"
                        fontSize={{
                            base: ThemeFontSizeVariable.Small,
                            md: ThemeFontSizeVariable.Large,
                        }}
                        maxW="380px"
                    >
                        {section.description}
                    </Text>

                    <ActivityBadges section={section} />
                </Stack>
                {section.image && showImage && (
                    <Image
                        src={`https://imagecdn.caspeco.net/images/${section.image}?AspectRatio=ONE_BY_ONE&Type=webp`}
                        maxH="72px"
                        borderRadius={ThemeSpaceVariable.Small}
                        onError={() => setShowImage(false)}
                        opacity={disabled ? 0.5 : 1}
                    />
                )}
            </Flex>
            {disabled && (
                <Alert mb={ThemeSpaceVariable.Small} type="info">
                    {t("activityUnavailable", { guests })}
                </Alert>
            )}
            <Divider />
        </Box>
    );
};

export default ActivityItem;
