import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Grid } from "@caspeco/casper-ui-library.components.grid";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Skeleton } from "@caspeco/casper-ui-library.components.skeleton";

function TimeSetSkeleton() {
    return (
        <Flex gap={ThemeSpaceVariable.Large} direction="column">
            <Box>
                <Heading as="h3" mb={ThemeSpaceVariable.Medium}>
                    <Skeleton height="32px" width="35%" />
                </Heading>
                <Skeleton mb={ThemeSpaceVariable.Small} height="21px" width="30%" />
                <Grid templateColumns="repeat(4, 1fr)" gap={ThemeSpaceVariable.Small}>
                    {Array.from({ length: 16 }).map((_, index) => (
                        <Skeleton key={index} height="48px" w="full" />
                    ))}
                </Grid>
            </Box>
            <Box>
                <Heading as="h3" mb={ThemeSpaceVariable.Medium}>
                    <Skeleton height="32px" width="35%" />
                </Heading>
                <Skeleton mb={ThemeSpaceVariable.Small} height="21px" width="30%" />
                <Grid templateColumns="repeat(4, 1fr)" gap={ThemeSpaceVariable.Small}>
                    {Array.from({ length: 16 }).map((_, index) => (
                        <Skeleton key={index} height="48px" w="full" />
                    ))}
                </Grid>
            </Box>
        </Flex>
    );
}

export default TimeSetSkeleton;
