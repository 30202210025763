import { ThemeColorVariable, ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useTranslation } from "react-i18next";

const WaitListTimeSetTitle = () => {
    const { t } = useTranslation();

    return (
        <Text
            color={ThemeColorVariable.OnBackgroundHeader}
            mb={ThemeSpaceVariable.Small}
            fontWeight="500"
        >
            {t("waitList.timesWithWaitList")}
        </Text>
    );
};

export default WaitListTimeSetTitle;
