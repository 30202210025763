import { parseDate } from "utils/moment-utils";

export const enum WaitListOfferStatus {
    Unavailable = 0,
    Available = 1,
}

export class WaitListOffer {
    startTime: IMoment;
    offerStatus: WaitListOfferStatus;

    constructor(values?: WaitListOffer) {
        this.startTime = parseDate(values?.startTime);
        this.offerStatus = values?.offerStatus ?? WaitListOfferStatus.Unavailable;
    }
}
