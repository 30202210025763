import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Skeleton } from "@caspeco/casper-ui-library.components.skeleton";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { useTranslation } from "react-i18next";

interface ISkeletonText {
    noOfLines: number;
    lineHeight: string;
    spacing?: string;
    w?: string;
    mt?: string;
    mb?: string;
    textAlignCenter?: boolean;
}

const SkeletonText = ({
    noOfLines,
    spacing,
    w,
    mt,
    mb,
    lineHeight,
    textAlignCenter,
}: ISkeletonText) => {
    const getWidthForLine = (index: number, totalLines: number) => {
        if (!textAlignCenter) {
            return "full";
        }

        const decrementFactor = (index / totalLines) * (20 / 100);
        const widthPercentage = 100 - decrementFactor * 100;
        return `${Math.max(widthPercentage, 85)}%`;
    };

    return (
        <Stack
            mt={mt}
            mb={mb}
            spacing={spacing}
            w={w}
            alignItems={textAlignCenter ? "center" : "flex-start"}
        >
            {Array.from({ length: noOfLines }).map((_, index) => (
                <Skeleton
                    key={index}
                    height={lineHeight}
                    w={getWidthForLine(index, noOfLines - 1)}
                />
            ))}
        </Stack>
    );
};

const MyBookingSkeleton = () => {
    const { t } = useTranslation();
    return (
        <Stack
            alignItems="center"
            spacing={ThemeSpaceVariable.Large}
            m={ThemeSpaceVariable.Small}
            flex={1}
        >
            <Flex w="full" alignItems="center" direction="column">
                <Skeleton
                    height="64px"
                    w="64px"
                    borderRadius="full"
                    mb={ThemeSpaceVariable.Medium}
                />

                <SkeletonText
                    mb={ThemeSpaceVariable.Medium}
                    noOfLines={2}
                    lineHeight="24px"
                    spacing={ThemeSpaceVariable.Medium}
                    w="80%"
                    textAlignCenter
                />

                <SkeletonText
                    mt="ThemeSpaceVariable.Small"
                    noOfLines={4}
                    lineHeight="18px"
                    spacing={ThemeSpaceVariable.Small}
                    w="80%"
                    textAlignCenter
                />
            </Flex>

            {/* Guest Information */}
            <Flex gap="6" w="full">
                <Skeleton borderRadius={ThemeSpaceVariable.Small} w="72px" h="72px" />

                <SkeletonText w="50%" lineHeight="16px" noOfLines={3} />
            </Flex>

            <Skeleton
                height="250px"
                width="full"
                alignSelf="center"
                borderRadius={ThemeSpaceVariable.Small}
            />

            <Button variant="tertiary" isDisabled alignSelf="flex-end">
                {t("bookMore")}
            </Button>

            <Stack spacing={ThemeSpaceVariable.Medium} w="full">
                <Skeleton lineHeight="24px" w="50%" h="24px" mb={ThemeSpaceVariable.Small} />
                <Flex w="full" justifyContent="space-between">
                    <Skeleton w="30%" h="20px" />
                    <Skeleton w="30%" h="20px" />
                </Flex>
                <Flex w="full" justifyContent="space-between">
                    <Skeleton w="30%" h="20px" />
                    <Skeleton w="30%" h="20px" />
                </Flex>
                <Flex w="full" justifyContent="space-between">
                    <Skeleton w="30%" h="20px" />
                    <Skeleton w="30%" h="20px" />
                </Flex>
                <Flex w="full" justifyContent="space-between">
                    <Skeleton w="30%" h="20px" />
                    <Skeleton w="30%" h="20px" />
                </Flex>
            </Stack>
        </Stack>
    );
};

export default MyBookingSkeleton;
