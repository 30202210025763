import { Dispatch, ReactNode, SetStateAction, createContext } from "react";
import { ValidationResult } from "types/validation-result";

export enum WebBookingErrorCode {
    Undefined = 0,
    TemporaryUnavailable = 2,
    PaymentError = 19,
}
interface ErrorContextType {
    errorCode: WebBookingErrorCode;
    setErrorCode: Dispatch<SetStateAction<WebBookingErrorCode>>;
    hasErrorCode: boolean;
    hasPaymentError: boolean;
    openReservationFailedModal: () => void;
    closeReservationFailedModal: () => void;
    isOpenReservationFailedModal: boolean;
    setValidationResult: Dispatch<SetStateAction<ValidationResult | null>>;
    validationResult: ValidationResult;
}

export interface ErrorProviderProps {
    children: ReactNode;
}

export const ErrorContext = createContext<ErrorContextType | undefined>(undefined);
