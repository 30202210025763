import { useAvailableTimes } from "api/api-hooks/use-available-times";
import { useReservation } from "api/api-hooks/use-create-reservation";
import { useWebBooking } from "api/api-hooks/use-web-booking";
import { useAllTimeSets } from "hooks/use-all-time-sets";
import { useBookingStateContext } from "hooks/use-booking-state";
import { useMemo } from "react";
import { AvailableTimesSection, AvailableTimesUnit } from "types/available-times";
import { WebRule } from "types/web-rule";

const useSelectedRule = (ruleIdOverride?: number): WebRule | undefined => {
    const state = useBookingStateContext();
    const availableTimes = useAvailableTimes(state.date);
    const { data: webBooking } = useWebBooking();
    const { data: reservation } = useReservation();

    const ruleId = (state.times && state.times[0]?.ruleId) || ruleIdOverride;

    let selectedRule = useMemo(() => {
        return availableTimes?.data
            ?.flatMap((item: AvailableTimesUnit) =>
                item.sections.flatMap((section: AvailableTimesSection) => section.timeSets)
            )
            .find((timeSet: WebRule) => timeSet.id === ruleId);
    }, [availableTimes?.data, ruleId]);

    if (reservation && reservation.webBookingRules)
        selectedRule = reservation.webBookingRules.at(0);

    if (webBooking?.webBookingRules) selectedRule = webBooking.webBookingRules.at(0);

    return selectedRule;
};

/**
 * Retrieves a list of unique timeSets (WebRules) that the booking is associated with.
 * This is important for activity bookings where a guest can book multiple WebRules.
 *
 * Note:
 * - "timeSets" and "WebRules" are synonyms.
 *
 * @returns {WebRule[]} The selected WebRules for the booking.
 */
const useSelectedRules = (): WebRule[] => {
    const { data: reservation } = useReservation();
    const ruleIds = reservation?.articles?.map((time) => time.ruleId);
    const allTimeSets = useAllTimeSets();
    const selectedRules = useMemo(() => {
        return allTimeSets?.filter((timeSet: WebRule) => ruleIds?.includes(timeSet.id));
    }, [ruleIds, allTimeSets]);

    return selectedRules;
};

export { useSelectedRule, useSelectedRules };
