import {
    ThemeFontSizeVariable,
    ThemeIconSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Icon, Icons } from "@caspeco/casper-ui-library.components.icon";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import React from "react";

interface ErrorTemplateProps {
    btnCallback?: () => void;
    btnText?: string;
    icon: Icons | JSX.Element;
    title: string;
    message: string;
    isBtnLoading?: boolean;
}

const ErrorTemplate = ({
    btnCallback,
    btnText,
    icon,
    title,
    message,
    isBtnLoading,
}: ErrorTemplateProps) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={ThemeSpaceVariable.Medium}
            mt={ThemeSpaceVariable.Large}
        >
            {React.isValidElement(icon) ? (
                icon
            ) : (
                <Icon icon={icon as Icons} color="#242236" size={ThemeIconSizeVariable.X3Large} />
            )}
            <Stack textAlign="center" alignItems="center" spacing={ThemeSpaceVariable.Small}>
                <Heading fontWeight="medium" as="h2">
                    {title}
                </Heading>
                <Text fontSize={ThemeFontSizeVariable.Large} textAlign="center">
                    {message}
                </Text>
                {btnCallback && (
                    <Button variant="primary" onClick={btnCallback} isLoading={isBtnLoading}>
                        {btnText}
                    </Button>
                )}
            </Stack>
        </Box>
    );
};

export default ErrorTemplate;
