import { useSelectedRules } from "hooks/use-selected-rule";
import { PaymentRule } from "types/payment";
import { useReservation } from "./../api/api-hooks/use-create-reservation";

const usePaymentRule = (): PaymentRule => {
    const allBookedRules = useSelectedRules();
    const reservation = useReservation();

    if (!allBookedRules) return PaymentRule.NoFee;

    const ruleMap = new Map(
        reservation.data?.articles?.map((article) => [article.ruleId, article])
    );

    /**
     * Determines if prepayment is required based on menu payment rules.
     *
     * A booking requires prepayment if **both** conditions are met:
     * 1. A booked resource with a rule that requires menu payment.
     * 2. At least one booked menu-article that shares the same `menuGuid`
     *    as the booked resource article which requires menu-payment (i.e., an article linked to the rule).
     *
     * This ensures that menu-related items requiring payment trigger prepayment.
     */
    const requiresPrepaymentForSomeMenu = allBookedRules.some((rule) => {
        const requireMenuPayment = rule.requireMenuPayment;
        if (!requireMenuPayment) return false;
        const resourceArticle = ruleMap.get(rule.id);
        return reservation.data.nonTableArticles.some(
            (article) => article.menuGuid === resourceArticle.menuGuid
        );
    });

    if (requiresPrepaymentForSomeMenu) return PaymentRule.Prepayment;

    // If any booked rule requires prepayment - booking should require prepayment. Has a higher priority than noShow.
    if (allBookedRules.some((rule) => rule.paymentRule === PaymentRule.Prepayment)) {
        return PaymentRule.Prepayment;
    }

    // If any booked rule requires noShow - booking should require card registration.
    if (allBookedRules.some((rule) => rule.paymentRule === PaymentRule.NoShowFee)) {
        return PaymentRule.NoShowFee;
    }

    return PaymentRule.NoFee;
};

export { usePaymentRule };
