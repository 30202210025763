import { useReservation } from "api/api-hooks/use-create-reservation";
import { useAllTimeSets } from "hooks/use-all-time-sets";
import { useCallback, useMemo } from "react";
import { Time } from "store/store-types";
import { AvailableTimesSection } from "types/available-times";
import { TimeSlot } from "types/time-slot";
import { WebRule } from "types/web-rule";

const isWithinTwoHours = (timeA: IMoment, timeB: IMoment): boolean => {
    return Math.abs(timeA.diff(timeB, "minutes")) <= 120;
};

export const isOtherRuleTimeOverlapping = (
    time: TimeSlot,
    selectedTimes: Time[],
    allTimeSets: WebRule[],
    currentTimeSet: WebRule
) => {
    return selectedTimes?.some((selectedTime) => {
        if (selectedTime.ruleId === time.ruleId) return false;

        const selectedTimeRule = allTimeSets.find((rule) => rule.id === selectedTime.ruleId);
        if (!selectedTimeRule) return false;

        const currentTimeEnd = time.end?.clone().subtract(currentTimeSet?.recoupTime, "minutes");
        const selectedTimeEnd = selectedTime.end
            ?.clone()
            .subtract(selectedTimeRule.recoupTime, "minutes");

        return selectedTime.start.isBefore(currentTimeEnd) && time.start.isBefore(selectedTimeEnd);
    });
};

// Times to nudge:
// Based on each selected time (selectedTimesExcludingOpenSection), we want to identify the first available timeslot in the provided time set that follows or precedes the selected time.
// This timeslot should not overlap with previously selected times and must be within 2 hours of the selected time—either up to 2 hours before time.start or up to 2 hours after time.end.
// The closest available option in each direction should be chosen.

export const useTimesToNudge = (section: AvailableTimesSection): Map<number, Time[]> => {
    const ruleIds = section.timeSets.map((timeSet) => timeSet.id);
    const selectedTimes = useReservation().data?.getBookTimes();
    const selectedTimesExcludingOpenSection = selectedTimes?.filter(
        (time) => !section.timeSets.some((timeSet) => timeSet.id === time.ruleId)
    );

    const allTimeSets = useAllTimeSets();

    const getValidTimes = useCallback(
        (
            times: TimeSlot[],
            selectedTime: Time,
            selectedTimeRule: WebRule,
            currentTimeSet: WebRule,
            direction: "after" | "before"
        ): Time[] => {
            const timeBoundary =
                direction === "after" ? selectedTime.end.clone() : selectedTime.start.clone();
            const comparisonTime = direction === "after" ? selectedTime.start : selectedTime.end;

            return times.filter((time) => {
                const boundaryCondition =
                    direction === "after"
                        ? time.start.isSameOrAfter(
                              timeBoundary.subtract(selectedTimeRule.recoupTime, "minutes")
                          )
                        : time.start.isBefore(
                              timeBoundary.subtract(selectedTimeRule.recoupTime, "minutes")
                          );
                return (
                    boundaryCondition &&
                    !isOtherRuleTimeOverlapping(
                        time,
                        selectedTimesExcludingOpenSection,
                        allTimeSets,
                        currentTimeSet
                    ) &&
                    isWithinTwoHours(time.start, comparisonTime)
                );
            });
        },
        [allTimeSets, selectedTimesExcludingOpenSection]
    );

    const timesToNudgeMap = useMemo(() => {
        const timesToNudgeMap = new Map<number, Time[]>();
        const firstIndex = 0;

        ruleIds.forEach((ruleId) => {
            const currentTimeSet = allTimeSets.find((rule) => rule.id === ruleId);
            if (!currentTimeSet) return;

            const ruleTimesToNudge: Time[] = [];
            selectedTimesExcludingOpenSection?.forEach((selectedTime) => {
                const selectedTimeRule = allTimeSets.find(
                    (rule) => rule.id === selectedTime.ruleId
                );
                if (!selectedTimeRule) return;

                const possibleTimesAfter = getValidTimes(
                    currentTimeSet.times,
                    selectedTime,
                    selectedTimeRule,
                    currentTimeSet,
                    "after"
                );
                const followingTime = possibleTimesAfter.at(firstIndex);
                if (followingTime) {
                    ruleTimesToNudge.push(followingTime);
                }

                const possibleTimesBefore = getValidTimes(
                    currentTimeSet.times,
                    selectedTime,
                    selectedTimeRule,
                    currentTimeSet,
                    "before"
                );
                const previousTime = possibleTimesBefore.at(firstIndex);
                if (previousTime) {
                    ruleTimesToNudge.push(previousTime);
                }
            });

            timesToNudgeMap.set(ruleId, ruleTimesToNudge);
        });

        return timesToNudgeMap;
    }, [ruleIds, allTimeSets, selectedTimesExcludingOpenSection, getValidTimes]);

    return timesToNudgeMap;
};
