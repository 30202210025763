import {
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Button } from "@caspeco/casper-ui-library.components.button";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "@caspeco/casper-ui-library.components.modal";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useState } from "react";

interface IConfirmDialog {
    isOpen: boolean;
    title: string;
    message?: string | undefined;
    primaryButtonText: string | undefined;
    secondaryButtonText?: string | undefined;
    primaryAction: () => void | Promise<void>;
    secondaryAction: () => void | Promise<void>;
    onClose: () => void;
    size?: "sm" | "md" | "lg" | "xl" | "full";
}

function ConfirmationDialog({
    title,
    message,
    primaryButtonText,
    secondaryButtonText,
    primaryAction,
    secondaryAction,
    onClose,
    isOpen,
    size = "sm",
}: IConfirmDialog) {
    const [isAwaitingSecondaryAction, setIsAwaitingSecondaryAction] = useState(false);

    const onClickSecondaryAction = async () => {
        setIsAwaitingSecondaryAction(true);
        await secondaryAction();
        setIsAwaitingSecondaryAction(false);
    };

    return (
        <Modal onClose={onClose} isOpen={isOpen} size={size} closeOnOverlayClick={false}>
            <ModalHeader p={ThemeSpaceVariable.Medium}>
                <Heading as="h3">{title}</Heading>
            </ModalHeader>
            <ModalBody px={ThemeSpaceVariable.Medium}>
                <Text fontSize={ThemeFontSizeVariable.Medium}>{message}</Text>
            </ModalBody>
            <ModalFooter
                pt={ThemeSpaceVariable.Medium}
                px={ThemeSpaceVariable.Medium}
                pb={ThemeSpaceVariable.Medium}
                display="flex"
                justifyContent="flex-end"
            >
                <Stack direction="row" spacing={ThemeSpaceVariable.Medium}>
                    <Button
                        size={{ base: "lg", md: "md" }}
                        variant="primary"
                        onClick={() => void primaryAction()}
                    >
                        {primaryButtonText}
                    </Button>
                    <Button
                        size={{ base: "lg", md: "md" }}
                        variant="secondary"
                        onClick={() => void onClickSecondaryAction()}
                        isLoading={isAwaitingSecondaryAction}
                    >
                        {secondaryButtonText}
                    </Button>
                </Stack>
            </ModalFooter>
        </Modal>
    );
}

export default ConfirmationDialog;
