import { t } from "i18next";
import { Article } from "types/article";
import { PaymentCalculationRule } from "types/payment";
import { WebRule } from "types/web-rule";

export function getPaymentCalculationTotal(
    rule: WebRule,
    guests: number,
    resourceArticles: Article[]
): number {
    if (!rule) return 0;
    switch (rule.paymentCalculationRule) {
        case PaymentCalculationRule.PaymentPerBooking:
            return rule.paymentAmount;
        case PaymentCalculationRule.PaymentPerGuest:
            return rule.paymentAmount * guests;
        case PaymentCalculationRule.PaymentPerResource:
            return rule.paymentAmount * (resourceArticles?.length || 1);
        default:
            return rule.paymentAmount;
    }
}

export function getPaymentUnitTranslation(rule: WebRule): string {
    switch (rule.paymentCalculationRule) {
        case PaymentCalculationRule.PaymentPerBooking:
            return t("payment.unit.booking");
        case PaymentCalculationRule.PaymentPerGuest:
            return t("payment.unit.guest");
        case PaymentCalculationRule.PaymentPerResource:
            return t("payment.unit.resource");
        default:
            return t("payment.unit.guest");
    }
}
