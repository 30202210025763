import "./change-url";
import { asBool, asDate, asInt, asIntArray, asString } from "./parse";

// Enum for actual URL parameter names
export enum UrlParamName {
    SYSTEM = "system",
    UNIT_ID = "unitId",
    SECTION_IDS = "sectionIds",
    TAG = "tag",
    WEB_BOOKING_ID = "webBookingId",
    START = "start",
    END = "end",
    DATE = "date",
    RULE_ID = "r",
    GUESTS = "guests",
    GUESTS_CHILDREN = "children",
    CHARGE_ID = "ChargeId",
    OFFER_START_TIME = "offerStartTime",
    IS_PAYMENT_LINK = "paymentLink",
    CAPTURED = "Captured",
    IS_NO_SHOW = "isNoShow",
    IS_ACTIVITY_BOOKING = "activityBooking",
    IS_TIMES_CONFIRMED = "timesConfirmed",
}

export type UrlParams = {
    date: moment.Moment;
    guestsChildren: number;
    sectionIds: number[];
    system: string;
    tag: string;
    webBookingId: string;
    guests: number;
    unitId: number;
    start: string;
    end: string;
    ruleId: number;
    chargeId: string;
    offerStartTime: string;
    isPaymentLink: boolean;
    captured: boolean;
    isNoShow: boolean;
    isActivityBooking: boolean;
    isTimesConfirmed: boolean;
};

let lastUrl = "";
let cache: UrlParams;
// eslint-disable-next-line prefer-const
export let getSearchParams = () => {
    if (window.location.href !== lastUrl) {
        lastUrl = window.location.href;
        cache = {
            system: asString(UrlParamName.SYSTEM, null),
            unitId: asInt(UrlParamName.UNIT_ID),
            sectionIds: asIntArray(UrlParamName.SECTION_IDS),
            tag: asString(UrlParamName.TAG, null),
            // Query Strings
            webBookingId: asString(UrlParamName.WEB_BOOKING_ID, null),
            start: asString(UrlParamName.START),
            end: asString(UrlParamName.END),
            // Date moment
            date: asDate(UrlParamName.DATE),
            // Query Number
            ruleId: asInt(UrlParamName.RULE_ID),
            guests: asInt(UrlParamName.GUESTS),
            guestsChildren: asInt(UrlParamName.GUESTS_CHILDREN),
            chargeId: asString(UrlParamName.CHARGE_ID, null),
            offerStartTime: asString(UrlParamName.OFFER_START_TIME, null),
            isPaymentLink: asBool(UrlParamName.IS_PAYMENT_LINK),
            captured: asBool(UrlParamName.CAPTURED),
            isNoShow: asBool(UrlParamName.IS_NO_SHOW),
            isActivityBooking: asBool(UrlParamName.IS_ACTIVITY_BOOKING),
            isTimesConfirmed: asBool(UrlParamName.IS_TIMES_CONFIRMED),
        };
    }
    return cache;
};
