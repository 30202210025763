import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { ToggleButton } from "@caspeco/casper-ui-library.components.toggle-button";
import { useReservation } from "api/api-hooks/use-create-reservation";
import { isOtherRuleTimeOverlapping } from "components/selection-components/select-activity/use-times-to-nudge";
import { useAllTimeSets } from "hooks/use-all-time-sets";
import { useMemo } from "react";
import { Time } from "store/store-types";
import { TimeSlot } from "types/time-slot";
import PulseAnimation from "./pulse-animation";

interface IActivityTimeSlot {
    time: TimeSlot;
    selectedTime: TimeSlot;
    onClick?: () => void;
    timesToNudge: Time[];
}

export default function ActivityTimeSlot({
    time,
    selectedTime,
    onClick,
    timesToNudge = [],
}: IActivityTimeSlot) {
    const allTimeSets = useAllTimeSets();
    const selectedTimes = useReservation().data?.getBookTimes();

    const isSelected =
        time.ruleId === selectedTime?.ruleId && time.start.isSame(selectedTime?.start);

    const currentTimeSet = useMemo(
        () => allTimeSets.find((rule) => rule.id === time.ruleId),
        [allTimeSets, time.ruleId]
    );

    const disable = isOtherRuleTimeOverlapping(time, selectedTimes, allTimeSets, currentTimeSet);

    const shouldNudgeTime = timesToNudge.some((nudgeTime) => nudgeTime.start.isSame(time.start));
    return (
        <PulseAnimation isActive={shouldNudgeTime && !isSelected} forceBorder>
            <ToggleButton
                key={time.start.format("HH:mm")}
                onClick={onClick}
                isPressed={isSelected}
                w="full"
                px={ThemeSpaceVariable.Small}
                size="lg"
                h={ThemeSpaceVariable.XLarge}
                isDisabled={disable}
            >
                {time.start.format("HH:mm")}
            </ToggleButton>
        </PulseAnimation>
    );
}
