import {
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Alert } from "@caspeco/casper-ui-library.components.alert";
import { Checkbox } from "@caspeco/casper-ui-library.components.checkbox";
import { Divider } from "@caspeco/casper-ui-library.components.divider";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Icons } from "@caspeco/casper-ui-library.components.icon";
import { IconButton } from "@caspeco/casper-ui-library.components.icon-button";
import { ListItem } from "@caspeco/casper-ui-library.components.list";
import { QuantityButton } from "@caspeco/casper-ui-library.components.quantity-button";
import { Radio } from "@caspeco/casper-ui-library.components.radio";
import { Stack } from "@caspeco/casper-ui-library.components.stack";
import { Text } from "@caspeco/casper-ui-library.components.text";
import Price from "components/price";
import PreorderGroupWrapper from "components/selection-components/preorder/preorder-group-wrapper";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuGroup, MenuGroupType } from "types/menu-group";
import { MenuItemWebFlags } from "types/menu-item";

interface IPreOrderMenuGroup {
    menuGroup: MenuGroup;
    currentGroupQuantities: number[];
    setGroupQuantities: (quantities: number[]) => void;
}

/**
 * Gives an icon button if the quantity is 1. Gives quantity buttons if the quantity is greater than 1.
 * Have to ability to show inline errors if there is a minimum or maximum quantity that is exceeded.
 */
const AmountSelector = ({
    currentQuantity,
    setQuantity,
    addItemButtonText,
    minimumQuantity = Number.MIN_SAFE_INTEGER,
    maximumQuantity = Number.MAX_SAFE_INTEGER,
    minWarning = "Minimum quantity reached",
    maxWarning = "Maximum quantity reached", // Todo: Probably should have some other solution for this
}: {
    currentQuantity: number;
    addItemButtonText: string;
    setQuantity: (quantity: number) => void;
    minimumQuantity?: number;
    maximumQuantity?: number;
    minWarning?: string;
    maxWarning?: string;
}) => {
    enum ErrorType {
        Minimum = "minimum",
        Maximum = "maximum",
        None = "none",
    }
    const [error, setError] = useState(ErrorType.None);
    if (currentQuantity === 0) {
        return (
            <IconButton
                icon={Icons.Add}
                variant="tertiary"
                aria-label={addItemButtonText}
                onClick={() => setQuantity(++currentQuantity)}
            />
        );
    } else {
        return (
            <Stack>
                {error === ErrorType.Minimum && <Alert type="error">{minWarning}</Alert>}
                {error === ErrorType.Maximum && <Alert type="error">{maxWarning}</Alert>}
                <QuantityButton
                    quantity={currentQuantity}
                    onClickMinus={() =>
                        currentQuantity > minimumQuantity
                            ? setQuantity(--currentQuantity)
                            : setError(ErrorType.Minimum)
                    }
                    onClickPlus={() =>
                        currentQuantity < maximumQuantity
                            ? setQuantity(++currentQuantity)
                            : setError(ErrorType.Maximum)
                    }
                />
            </Stack>
        );
    }
};

const PreorderMenuGroup = ({
    menuGroup,
    setGroupQuantities,
    currentGroupQuantities,
}: IPreOrderMenuGroup) => {
    const { t } = useTranslation();
    // Todo: Should consider if we should add the item name to this translation for accessability. Test.
    const addItemButtonText = t("preorder.addItemButtonText");

    const widgets: Record<
        MenuGroupType,
        (
            setQuantity: (quantity: number) => void,
            currentQuantity: number,
            itemFlag: MenuItemWebFlags
        ) => JSX.Element | null
    > = {
        [MenuGroupType.ViewOnly]: () => {
            // Kem är denna med -1 choices, beroende av webflags så presenteras widget. Denna är riktigt underlig, kolla med UX
            /**
             * Based on this data
             * {
             *      "groupType": 0,
             *      "name": "Kem",
             *      "choices": -1,
             *      "items": [
             *        {
             *          "name": "Lysfotogen",
             *          "description": "",
             *          "articleID": 706,
             *          "quantity": 0,
             *          "price": 0,
             *          "vatPercent": 25,
             *          "time": null,
             *          "webFlags": 0,
             *          "inactivatedUntil": "1753-01-01T00:00:00",
             *          "sortOrder": 0
             *        }
             *      ],
             *      "optional": false,
             *      "isCollapsed": false
             *    }
             */
            return null;
        },
        [MenuGroupType.SingleChoice]: () => {
            // This is personal with -1 choices
            // Todo, check name. We can not really add any meaningful lable to the radiobutton. A11y problems? What is initially checked?
            return <Radio />;
        },
        [MenuGroupType.AmountChoice]: (setQuantity, currentQuantity) => {
            return (
                <AmountSelector
                    currentQuantity={currentQuantity}
                    setQuantity={setQuantity}
                    addItemButtonText={addItemButtonText}
                />
            );
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [MenuGroupType.LimitedChoices]: (setQuantity, currentQuantity, itemFlag) => {
            return null; // Add a return statement to avoid type error
            // Todo, consider adding a maximum property. How to explain for the user that they utilized the maximum amount? Disabling + inline?
        },
        [MenuGroupType.LimitedMinChoicesToGuests]: (setQuantity, currentQuantity, itemFlag) => {
            // Todo, consider adding a minimum property that matches the guest. How to explain for the user that they need to add a minimum? Disabling + inline?
            return widgets[MenuGroupType.AmountChoice](setQuantity, currentQuantity, itemFlag);
        },
        [MenuGroupType.LimitedMinMaxChoicesToGuests]: (setQuantity, currentQuantity, itemFlag) => {
            // Todo, Same as the two previous but with both min and max
            return widgets[MenuGroupType.AmountChoice](setQuantity, currentQuantity, itemFlag);
        },
        [MenuGroupType.LimitedToGuests]: () => {
            return <div>todo, how does this differ fro limited to min?</div>;
        },
        [MenuGroupType.LimitedToGuestsAndChoices]: () => {
            return <div>todo, how does this differ from earlier?</div>;
        },
        [MenuGroupType.MultipleChoices]: (_, _x, itemFlag) => {
            // Todo, what to do with itemflag? Not visible for personal
            if (itemFlag === MenuItemWebFlags.TextPriceAndQuantity) {
                return <Checkbox />;
            }
            return null;
        },
    };

    return (
        <>
            {menuGroup.items.map((menuItem, idx) => {
                const setQuantity = (newValue: number) =>
                    setGroupQuantities(currentGroupQuantities.toSpliced(idx, 1, newValue));
                if (menuItem.webFlags === MenuItemWebFlags.Hide) return null;

                const showPrice = menuItem.webFlags === MenuItemWebFlags.TextPriceAndQuantity;
                const showWidget = menuItem.webFlags !== MenuItemWebFlags.TextOnly;
                return (
                    <div key={`menugroup-${idx}`}>
                        <ListItem
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={ThemeSpaceVariable.Medium}
                            margin={`${ThemeSpaceVariable.Medium} 0`}
                            minH={ThemeSpaceVariable.X2Large}
                        >
                            <PreorderGroupWrapper menuGroup={menuGroup}>
                                <div>
                                    <Heading
                                        as="h3"
                                        fontSize={ThemeFontSizeVariable.Large}
                                        fontWeight="500"
                                    >
                                        {menuItem.name}
                                    </Heading>
                                    <Text noOfLines={2} fontWeight="400">
                                        {menuItem.description}
                                    </Text>
                                    {showPrice && (
                                        <Text fontWeight="500">
                                            <Price amount={menuItem.price} display="symbol" />
                                        </Text>
                                    )}
                                </div>
                                {showWidget && // Todo, check that this is the case. Not defined in requirements
                                    widgets[menuGroup.groupType](
                                        setQuantity,
                                        currentGroupQuantities[idx],
                                        menuItem.webFlags
                                    )}
                            </PreorderGroupWrapper>
                        </ListItem>
                        <Divider backgroundColor={ThemeColorVariable.OnBackgroundBorder} />
                    </div>
                );
            })}
        </>
    );
};

export default PreorderMenuGroup;
