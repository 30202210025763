import { useCancelBooking } from "api/api-hooks/use-cancel-booking";
import { useReservation, useUpdateReservation } from "api/api-hooks/use-create-reservation";
import { useBookingActionsContext, useBookingStateContext } from "hooks/use-booking-state";
import queryClient from "query-client";
import { GUID_STORAGE_KEY } from "store";

const useRemoveActivity = (
    beforeRemoveLastCallback?: () => void,
    onRemoveCompleteCallback?: () => void
) => {
    const { setState } = useBookingActionsContext();
    const state = useBookingStateContext();
    const { data: reservation } = useReservation();
    const updateBooking = useUpdateReservation();
    const cancelBooking = useCancelBooking();

    const removeActivity = async (ruleId: number, sectionId: number) => {
        const startTime = reservation?.articles
            .filter((article) => article.ruleId === ruleId)
            .at(0).start;

        const times = state.times ? state.times.filter((time) => time.ruleId !== ruleId) : [];
        const stateWithTimes = {
            ...state,
            times,
        };
        setState(stateWithTimes);

        const activityToRemove = reservation
            .getBookTimes()
            .filter((time) => time.ruleId === ruleId);

        const uniqueIds = [...new Set(reservation?.articles.map((item) => item.ruleId))];
        if (uniqueIds.length === 1) {
            // If this is the last item in the booking, we need to remove the query for the booking
            queryClient.removeQueries({
                queryKey: ["booking", localStorage.getItem(GUID_STORAGE_KEY)],
            });
            if (beforeRemoveLastCallback) beforeRemoveLastCallback();
            await cancelBooking.mutateAsync({});
        } else if (activityToRemove.length > 0) {
            await updateBooking.mutateAsync({
                state: stateWithTimes,
                timesToUpdate: [],
                removeTimes: [{ sectionId, startTime }],
            });
            if (onRemoveCompleteCallback) onRemoveCompleteCallback();
        }
    };
    return { removeActivity, isPendig: updateBooking.isPending || cancelBooking.isPending };
};

export { useRemoveActivity };
