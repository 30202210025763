import { RadioGroup } from "@caspeco/casper-ui-library.components.radio-group";
import { CheckboxGroup } from "@caspeco/casper-ui-library.components.checkbox-group";
import { logWarn } from "logger/logger";
import { MenuGroup, MenuGroupType } from "types/menu-group";

interface IPreorderGroupWrapper {
    children: React.ReactNode;
    menuGroup: MenuGroup;
}

/**
 * Some menu groups require a wrapper around the components.
 * Currently known group
 * SingelChoice + choices === -1 => Radio Buttons
 * MultipleChoices + choices === -1 => Checkbox
 * Todo: How to handle default selected?
 */
const PreorderGroupWrapper = ({ children, menuGroup }: IPreorderGroupWrapper) => {
    switch (menuGroup.groupType) {
        case MenuGroupType.SingleChoice:
            if (menuGroup.choices !== -1) {
                logWarn(
                    `PreorderGroupWrapper: unknown combination for SingleChoice, choices ${menuGroup.choices}`
                );
            }
            return <RadioGroup>{children}</RadioGroup>;
        case MenuGroupType.MultipleChoices:
            if (menuGroup.choices !== -1) {
                logWarn(
                    `PreorderGroupWrapper: unknown combination for MultipleChoices, choices ${menuGroup.choices}`
                );
            }
            return <CheckboxGroup>{children}</CheckboxGroup>;
        default:
            return children;
    }
};

export default PreorderGroupWrapper;
