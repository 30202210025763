import { ThemeSpaceVariable } from "@caspeco/casper-ui-library.base-ui.theme";
import { ToggleButton } from "@caspeco/casper-ui-library.components.toggle-button";
// eslint-disable-next-line caspeco/discourage-chakra-import
import { ScaleFade } from "@chakra-ui/react";
import { useBookingActionsContext, useBookingStateContext } from "hooks/use-booking-state";
import { TimeSlot as TimeSlotType } from "types/time-slot";
import { saveSectionNameAndWebRuleTitle } from "utils/event-utils";

interface TimeSlotProps {
    time: TimeSlotType;
}

export default function TimeSlot({ time }: TimeSlotProps) {
    const state = useBookingStateContext();
    const { handleReservation } = useBookingActionsContext();
    const { times } = state;

    const handleClick = () => {
        const newTime = { ruleId: time.ruleId, start: time.start, end: time.end };
        saveSectionNameAndWebRuleTitle(time.name, time.title);
        const times = state.times ? [...state.times, newTime] : [newTime];
        const stateWithTimes = {
            ...state,
            times,
        };

        handleReservation(stateWithTimes);
    };

    const isSelected = !!times?.find(
        (t) => time.ruleId === t.ruleId && time.start.isSame(t.start, "day")
    );

    return (
        <ScaleFade initialScale={0.9} in={true} transition={{ enter: { duration: 0.5 } }}>
            <ToggleButton
                key={time.start.format("HH:mm")}
                className="timeButton"
                onClick={handleClick}
                isPressed={isSelected}
                w="full"
                px={ThemeSpaceVariable.Small}
                size="lg"
                h={ThemeSpaceVariable.XLarge}
            >
                {time.start.format("HH:mm")}
            </ToggleButton>
        </ScaleFade>
    );
}
