import { useBackFromPaymentTerminal } from "api/api-hooks/use-back-from-terminal";
import PaymentError from "components/error-components/payment-error/payment-error";
import PaymentSpinner from "components/payment-spinner";
import { useEffect, useRef } from "react";
import { GUID_STORAGE_KEY } from "store";
import { getSearchParams } from "search-params";
import { useError } from "hooks/use-error";

const PaymentConfirmation = () => {
    const { validationResult } = useError();
    const backFromTerminal = useBackFromPaymentTerminal();
    const { hasPaymentError } = useError();
    const { chargeId, webBookingId, offerStartTime } = getSearchParams();
    const hasRunMutate = useRef(null);

    const handleTryPayment = () => {
        localStorage.setItem(GUID_STORAGE_KEY, webBookingId);
        backFromTerminal.mutate({
            chargeId,
            offerStartTime,
        });
    };

    useEffect(() => {
        if (chargeId && webBookingId && !hasRunMutate.current) {
            hasRunMutate.current = true;
            handleTryPayment();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (hasPaymentError || (validationResult && !validationResult.isValid())) {
        return <PaymentError />;
    }

    return <PaymentSpinner />;
};

export default PaymentConfirmation;
