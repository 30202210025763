import {
    ThemeColorVariable,
    ThemeIconSizeVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Heading } from "@caspeco/casper-ui-library.components.heading";
import { Icon, Icons } from "@caspeco/casper-ui-library.components.icon";

interface IChoiceHeaderProps {
    id: string;
    icon?: Icons;
    text: string;
}

const SelectionHeader = ({ id, icon, text }: IChoiceHeaderProps) => {
    return (
        <Flex
            alignItems="center"
            gap={ThemeSpaceVariable.Small}
            mb={ThemeSpaceVariable.Medium}
            userSelect="none"
        >
            {icon && (
                <Icon
                    alignSelf="flex-start"
                    icon={icon}
                    size={ThemeIconSizeVariable.Large}
                    color={ThemeColorVariable.OnBackgroundHeader}
                />
            )}
            <Heading as="h6" id={id}>
                {text}
            </Heading>
        </Flex>
    );
};

export default SelectionHeader;
