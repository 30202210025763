import { useCancelBooking } from "api/api-hooks/use-cancel-booking";
import { useCreateReservation } from "api/api-hooks/use-create-reservation";
import { useFinalizeBooking } from "api/api-hooks/use-finalize-booking";
import { LayoutContext } from "layout/layout-context";
import { trackEvent } from "logging/insights";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { getSearchParams } from "search-params";
import { BookingSetStateContext, BookingStateContext } from "store/store-context";
import { getStateFromParams } from "store/store-helper";
import { State } from "store/store-types";
import { Contact } from "types/contact";
import { Customer } from "types/customer";
import { PaymentMethod } from "types/payment";
import { sendTrackingEventToParent } from "utils/event-utils";
import { useSafeNavigation } from "hooks/use-safe-navigation";

export const BookingProvider = () => {
    const params = getSearchParams();
    const location = useLocation();
    const safeNavigate = useSafeNavigation();
    const layoutContext = useContext(LayoutContext);
    const { mutate: cancelBooking } = useCancelBooking();
    const reservation = useCreateReservation();
    const finalize = useFinalizeBooking();
    const [state, setState] = useState(getStateFromParams(params, {}));
    const stateRef = useRef(state);
    const isUpdatingFromUrlRef = useRef(false);

    // Keep reference updated for use in callbacks
    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    // Sync state from URL params, but avoid circular updates, responds to URL changes
    useEffect(() => {
        // Skip if we're already handling a navigation
        if (isUpdatingFromUrlRef.current) {
            return;
        }

        // Mark that we're updating from URL to prevent circular updates
        isUpdatingFromUrlRef.current = true;

        try {
            // Re-fetch params to ensure we have the latest
            const latestParams = getSearchParams();
            const newState = getStateFromParams(latestParams, state);

            // Update state with the derived values from URL
            setState(newState);
        } finally {
            // Reset the flag after a short delay to ensure all effects have completed
            setTimeout(() => {
                isUpdatingFromUrlRef.current = false;
            }, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    useEffect(() => {
        if (params.guests) layoutContext.setShowCoverImage(false);
    }, [layoutContext, params.guests]);

    useEffect(() => {
        sendTrackingEventToParent(state);
    }, [state]);

    const { reset } = reservation;
    const handleCancel = () => cancelBooking({ id: undefined, callback: reset });

    /**
     * Set multiple state properties at once and update URL if needed
     * @param updates Partial state object with properties to update
     * @param updateUrl Whether to update the URL with the state changes (default: true)
     */
    const setMultipleStateProps = (updates: Partial<State>, updateUrl = true) => {
        // Update state immediately
        setState((prevState) => {
            const newState = {
                ...prevState,
                ...updates,
            };

            // If URL update is requested and we're not already updating from URL
            if (updateUrl && !isUpdatingFromUrlRef.current) {
                isUpdatingFromUrlRef.current = true; // Set flag to prevent URL-driven update while we update URL

                try {
                    const urlParams = new URLSearchParams(window.location.search);
                    let urlChanged = false;

                    if (updates.guests !== undefined) {
                        if (updates.guests) {
                            urlParams.set("guests", updates.guests.toString());
                        } else {
                            urlParams.delete("guests");
                        }
                        urlChanged = true;
                    }

                    if (updates.guestsChildren !== undefined) {
                        if (updates.guestsChildren !== null) {
                            urlParams.set("children", updates.guestsChildren.toString());
                        } else {
                            urlParams.delete("children");
                        }
                        urlChanged = true;
                    }

                    if (updates.date !== undefined) {
                        if (updates.date) {
                            urlParams.set("date", updates.date.format("YYYY-MM-DD"));
                        } else {
                            urlParams.delete("date");
                        }
                        urlChanged = true;
                    }

                    if (updates.isTimesConfirmed !== undefined) {
                        if (updates.isTimesConfirmed) {
                            urlParams.set("timesConfirmed", "true");
                        } else {
                            urlParams.delete("timesConfirmed");
                            // Also remove any time-related parameters
                            urlParams.delete("r");
                            urlParams.delete("start");
                            urlParams.delete("end");
                        }
                        urlChanged = true;
                    }

                    // Only navigate if something actually changed
                    if (urlChanged) {
                        const newSearch = urlParams.toString();
                        const path = `${window.location.pathname}${newSearch ? `?${newSearch}` : ""}`;
                        // Use preventBlocking: true to avoid confirmation dialogs during programmatic state updates
                        safeNavigate(path, { preventBlocking: true, replace: true });
                    }
                } finally {
                    // Reset the flag after a short delay
                    setTimeout(() => {
                        isUpdatingFromUrlRef.current = false;
                    }, 0);
                }
            }

            return newState;
        });
    };

    const setPaymentMethod = (method: PaymentMethod) => {
        setMultipleStateProps(
            {
                payment: { payMethod: method },
            },
            false
        );
    };

    const setIsWaitList = (isWaitList: boolean) => {
        setMultipleStateProps({ isWaitList }, false);
    };

    const setGuests = (guests: number) => {
        trackEvent("GuestsSelected", { count: guests });
        setMultipleStateProps({ guests });
    };

    const setChildren = (guestsChildren: number) => {
        trackEvent("SelectChildrenAmount", { guestsChildren, children: guestsChildren });
        setMultipleStateProps({ guestsChildren });
    };

    const setDate = (date: Date) => {
        setMultipleStateProps({ date: moment(date) });
    };

    const setContact = (contact: Partial<Contact>) => {
        setMultipleStateProps(
            {
                contact: {
                    ...stateRef.current.contact,
                    ...contact,
                },
            },
            false
        );
    };

    const setCustomer = (customer: Customer) => {
        setMultipleStateProps(
            {
                customer: {
                    ...stateRef.current.customer,
                    ...customer,
                },
            },
            false
        );
    };

    const setIsTimesConfirmed = (isTimesConfirmed: boolean) => {
        setMultipleStateProps({ isTimesConfirmed });
    };

    const handleReservation = (state: State) => {
        window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
        window.parent.postMessage("scroll2top", "*");

        const r = state.times[0].ruleId.toString();
        const start = state.times.map((t) => t.start.format("HHmm")).join("-");
        const end = state.times.length > 1 ? "" : state.times[0].end.format("HHmm");

        // Update state first
        setState(state);

        // Then update URL with all needed parameters
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set("r", r);
        currentParams.set("start", start);
        if (end) currentParams.set("end", end);
        currentParams.set("timesConfirmed", "true");

        const newSearch = currentParams.toString();
        safeNavigate(`${window.location.pathname}?${newSearch}`, {
            preventBlocking: true,
            replace: true,
        });

        reservation.mutate(state);
    };

    const handleFinalize = () => {
        finalize.mutate(state);
    };

    const storeActions = {
        setState,
        setMultipleStateProps,
        setIsWaitList,
        setPaymentMethod,
        setGuests,
        setChildren,
        setDate,
        setContact,
        setCustomer,
        setIsTimesConfirmed,
        handleReservation,
        handleFinalize,
        handleCancel,
        finalize,
        reservation,
    };

    return (
        <BookingStateContext.Provider value={state}>
            <BookingSetStateContext.Provider value={storeActions}>
                <Outlet />
            </BookingSetStateContext.Provider>
        </BookingStateContext.Provider>
    );
};
